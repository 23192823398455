/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import { useMemo } from 'react'

import { useAccessControl } from '@r1/core-blocks'

import { GridGenerator, setQuery, parseQuery } from '@r1/grid-generator'

import { httpClient } from '../../../../httpClient'

export const PolicyGrid = React.memo(() => {
  const [allowPermissions] = useAccessControl()
  const initialStateQuery = useMemo(parseQuery, [])

  return (
    <GridGenerator
      exportable={allowPermissions.allowPolicyItemExport}
      gridId="RtvPolicy"
      gridEngineBaseUrl="/uisvc/contract-management-grid"
      initialStateQuery={initialStateQuery}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      httpClient={httpClient}
      aggregationCount={{
        title: 'All Policies',
        suppressAggregation: false,
      }}
      onChangeState={setQuery}
    />
  )
})
