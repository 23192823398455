import React, { useState, useEffect, useCallback } from 'react'

import * as R from 'ramda'
import { handleServerError, useAccessControl } from '@r1/core-blocks'
import type { UserProgramSettings as ApiUserProgramSettings } from '@r1-webui/usermanagement-v1'
import type { UncontrolledActionButtons } from '@r1/ui-kit/contracts/ts/Modal'
import { Button, Modal, NotificationSystem, Placeholder } from '@r1/ui-kit'
import { ContentHeader } from '@r1/wireframe-primary'
import type { UserProgramSettings } from '../../../types/common'
import { ProgramsSettings } from '../../../components'
import { programsApi } from '../../../api/programs'

type Props = {
  userId: string
  onCancelForm: () => void
}

const { addNotification } = NotificationSystem

function tryMapToValidSettings(settings: UserProgramSettings) {
  const { selectedProgramIds, defaultLoginProgramId, defaultSearchOptionId } = settings
  if (selectedProgramIds.length > 0 && defaultLoginProgramId && defaultSearchOptionId) {
    return {
      selectedProgramIds,
      defaultLoginProgramId,
      defaultSearchOptionId,
    }
  }
  return null
}

function mapToControlSettings(settings: ApiUserProgramSettings): UserProgramSettings {
  return {
    selectedProgramIds: settings.availablePrograms,
    defaultLoginProgramId: settings.defaultLoginProgram,
    defaultSearchOptionId: settings.defaultSearchProgram,
  }
}

function mapToApiSettings(settings: UserProgramSettings) {
  const validSettings = tryMapToValidSettings(settings)
  if (!validSettings) {
    throw new Error('Not valid settings')
  }

  return {
    availablePrograms: validSettings.selectedProgramIds,
    defaultLoginProgram: validSettings.defaultLoginProgramId,
    defaultSearchProgram: validSettings.defaultSearchOptionId,
  }
}

export const ProgramSettingsTab = ({ userId, onCancelForm }: Props) => {
  const [state, setState] = useState<UserProgramSettings>()
  const [persistedState, setPersistedState] = useState<UserProgramSettings>()
  const [isSaving, setIsSaving] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [{ allowUserEdit }] = useAccessControl()

  useEffect(() => {
    const loadUserSettings = async () => {
      const settingsResponse = await programsApi.getUserProgramSettings({ userId })
      if (settingsResponse.status === 200) {
        const settings = mapToControlSettings(settingsResponse.body)
        setState(settings)
        setPersistedState(settings)
      } else {
        handleServerError(settingsResponse)
        addNotification({ level: 'error', title: "Can't load user settings" })
      }
    }

    loadUserSettings()
  }, [userId])

  const onSave = useCallback(async () => {
    if (!state) {
      throw new Error('Settings are not initailized')
    }
    setIsSaving(true)

    const settings = mapToApiSettings(state)
    const saveResult = await programsApi.setUserProgramSettings({ userId }, settings)
    if (saveResult.status === 200) {
      setPersistedState(state)
      setIsEditing(false)
      addNotification({ level: 'success', title: 'User program settings were saved' })
    } else {
      handleServerError(saveResult)
      addNotification({ level: 'error', title: "Can't load user settings" })
    }

    setIsSaving(false)
  }, [userId, state])

  const onDiscard = useCallback(() => {
    setState(persistedState)
    setIsEditing(false)
  }, [persistedState])

  const onCancelEditing = useCallback(
    (showConfirmationModal: () => void) => {
      if (!R.equals(state, persistedState)) {
        showConfirmationModal()
      } else {
        setIsEditing(false)
      }
    },
    [state, persistedState],
  )

  const discardModalActionButtons: UncontrolledActionButtons = useCallback(
    ({ onClose }: { onClose: () => void }) => {
      return [
        {
          title: 'NO',
          color: 'secondary',
          onClick: () => onClose(),
          align: 'right',
        },
        {
          title: 'YES',
          onClick: () => {
            onDiscard()
            onClose()
          },
          align: 'right',
        },
      ]
    },
    [onDiscard],
  )

  if (!state) {
    return <Placeholder type="form" height={5} />
  }

  return (
    <>
      <ProgramsSettings
        userSettings={state}
        isEditing={isEditing}
        onChange={settings => setState(settings)}
      />

      <ContentHeader.ActionButtons>
        {!isEditing && (
          <Button color="secondary" onClick={onCancelForm}>
            Close
          </Button>
        )}

        {!isEditing && allowUserEdit && (
          <Button color="primary" onClick={() => setIsEditing(true)}>
            Edit
          </Button>
        )}

        {isEditing && (
          <Modal
            isControlled={false}
            title="You have unsaved data"
            actionButtons={discardModalActionButtons}
            buttonElement={({ onOpen }) => (
              <Button disabled={isSaving} color="secondary" onClick={() => onCancelEditing(onOpen)}>
                Cancel
              </Button>
            )}
          >
            Are you sure you want to discard unsaved changes?
          </Modal>
        )}

        {isEditing && (
          <Button
            color="primary"
            disabled={!tryMapToValidSettings(state) || isSaving}
            onClick={onSave}
          >
            Save
          </Button>
        )}
      </ContentHeader.ActionButtons>
    </>
  )
}
