// @flow

import { useMemo, useCallback } from 'react'

import { ContextRouter, useNavigate } from 'react-router-dom'
import type { Grouping } from '@r1/grid-generator/build/types/query'
import { ROUTES } from '../../../../navigation'

const LINK_NODE_NAME = 'A'

export const useBreadcrumbs = (
  history: $ElementType<ContextRouter, 'history'>,
  isExpanded: boolean,
  lastGrouping: Grouping,
) => {
  const navigate = useNavigate()
  const parentLink = useMemo(() => {
    if (isExpanded === false) return ROUTES.R1_HOME

    const urlParams = new URLSearchParams(window.location.search)

    /** @todo move this fn to grid, so it can be exported to build such breadcrumbs on other pages
     * and remove side effects from useMemo
     */
    urlParams.delete('grouping')

    if (Array.isArray(lastGrouping) && lastGrouping.length > 0) {
      const groupingString = lastGrouping
        .map(({ column }) => `${column.settingsId}:${column.schemaIds.join('-')}`)
        .join(',')

      urlParams.set('grouping', encodeURIComponent(groupingString))
    }

    return `${window.location.pathname}?${urlParams.toString()}`
  }, [isExpanded, lastGrouping])

  const onRedirect = useCallback(
    (event: SyntheticEvent<HTMLElement>) => {
      // $FlowFixMe[prop-missing]
      if (event.target.nodeName !== LINK_NODE_NAME) return
      navigate(parentLink)
      /** we should manually reload since only query params changed */
      window.location.reload()
    },
    [navigate, parentLink],
  )

  const breadcrumbs = isExpanded ? 'Back to grouped records' : 'Home'

  return {
    parentLink,
    breadcrumbs,
    onRedirect,
  }
}
