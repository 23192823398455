import React from 'react'

import { Flex, Label, Text } from '@r1/ui-kit'

import { PolicyStatus } from '../../../../../constants/PolicyStatus'

import { mapPolicyStatusToLabelType } from '../../../../../mappers/mapPolicyStatusToLabelType'

type Props = {
  status: PolicyStatus | null
}

export const DrawerLabel = React.memo<Props>(({ status }) => {
  if (status) {
    return (
      <Flex align="center" spaceBetween="XS">
        <Text>RTV Policy</Text>
        <Label type={mapPolicyStatusToLabelType(status)}>{status}</Label>
      </Flex>
    )
  }

  return (
    <Flex align="center" spaceBetween="XS">
      <Text>RTV Policy</Text>
    </Flex>
  )
})
