/* eslint-disable no-nested-ternary */
import { useCallback, useState, useEffect } from 'react'
import FullCalendar, { EventInput, EventDropArg, EventClickArg } from '@fullcalendar/react'
import { EventResizeDoneArg } from '@fullcalendar/interaction'

import {
  PickupDescription,
  Warehouse,
  WarehousePickupTimeDescriptionPerDate,
} from '@r1-webui/facilitypickupscheduler-v1'

import {
  PickupReservation,
  PickupDescription as ReservationPickupDescription,
} from '@r1-webui/facilityreservationscheduler-v1'

import { NotificationSystem } from '@r1/ui-kit'
import { add, differenceInMinutes, format } from 'date-fns'
import { useUserSettings, useAccessControl } from '@r1/core-blocks'
import { useAccountInfo } from '@r1/wireframe-primary'

import { flatten } from 'ramda'
import { EventType, Reservation } from '../types'
import { getReservations, updateReservationTime } from '../../../services/reservationService'
import {
  getPickupDescriptions,
  updatePickupTime as updatePickupTimeAsync,
} from '../../../services/schedulePickupService'

import { ReservationEvent } from '../components/modals/CreateReservationModal'
import { CreatePickupEvent } from '../components/modals/CreatePickupModal'
import { utcToLocal } from '../../../../../utils/dates/utcToLocal'
import { ISOFormat } from '../../../../../utils/dates/formats'
import { COLORS } from '../constansts'

type UseCalendarEventsProps = {
  warehouse: Warehouse | null
  orderIds: string[]
  calendarComponentRef: React.RefObject<FullCalendar | null>
  onReservationClick: (reservation: Reservation) => void
  onPickupClick: (pickup: PickupDescription) => void
}

type EventHandlers = {
  onCalendarEventClick: (info: EventClickArg) => void
  onCalendarEventDragDrop: (eventDropInfo: EventDropArg) => void
  onCalendarEventResize: (eventDropInfo: EventResizeDoneArg) => void
  onDeleteReservationEvent: (reservationId: string) => void
  onDeletePickupEvent: (pickupId: string) => void
  onBindReservationWithOrder: (
    reservationId: string,
    pickupDescription: ReservationPickupDescription,
  ) => void
  onClearCalendarEvents: () => void
  onAddReservationEvent: (reservationEvent: ReservationEvent) => void
  onAddPickupEvent: (createPickupEvent: CreatePickupEvent) => void
  refreshCalendarEvents: () => Promise<void>
}

type UseCalendarEventsState = {
  isEventsLoading: boolean
  events: EventInput[]
} & EventHandlers

function getPickupColor(pickup: PickupDescription) {
  let color = COLORS.PICKUP

  switch (true) {
    case pickup.selected:
      color = COLORS.SELECTED_PICKUP
      break
    case pickup.isBundled:
      color = COLORS.BUNDLE_PICKUP
      break
    default:
      break
  }

  return color
}

const createCalendarEventFromPickup = (
  pickup: PickupDescription,
  start: Date | string,
  end: Date | string,
): EventInput => {
  const color = getPickupColor(pickup)

  return {
    id: pickup.id,
    payload: pickup,
    title: `${pickup.customerName} (#${pickup.orderIds})`,
    start,
    end,
    type: 'pickup',
    color,
  }
}

const createCalendarEventFromReservation = (
  reservation: Reservation,
  start: Date | string,
  end: Date | string,
): EventInput => {
  return {
    id: reservation.reservationId,
    payload: reservation,
    title: `Reserved by ${reservation.createdBy.name}`,
    start,
    end,
    color: COLORS.RESERVE,
    type: 'reservation',
  }
}

const updateEventPickupTimeAsync = async (
  eventType: EventType,
  payload: PickupDescription | Reservation,
  eventStart: Date,
  eventEnd: Date,
): Promise<EventInput | null> => {
  if (eventType === 'pickup') {
    const pickupDescription = payload as PickupDescription
    const isUpdated = await updatePickupTimeAsync(
      pickupDescription.warehouseLocationId,
      pickupDescription.id,
      {
        pickupDate: eventStart.toISOString(),
        period: { start: eventStart.toISOString(), end: eventEnd.toISOString() },
      },
    )
    if (isUpdated) {
      return createCalendarEventFromPickup(pickupDescription, eventStart, eventEnd)
    }
  }
  if (eventType === 'reservation') {
    const reservation = payload as Reservation
    const isUpdated = await updateReservationTime(reservation.reservationId, {
      pickupDate: eventStart.toISOString(),
      period: { start: eventStart.toISOString(), end: eventEnd.toISOString() },
    })
    if (isUpdated) {
      return createCalendarEventFromReservation(reservation, eventStart, eventEnd)
    }
  }
  return null
}

const convertDataToEvents = (
  warehouse: Warehouse,
  events: WarehousePickupTimeDescriptionPerDate[],
  reservations: PickupReservation[],
): EventInput[] => {
  const exceptionEvents: EventInput[] = warehouse.schedule.exceptions.map(exception => {
    const startTime = utcToLocal(exception)
    const endTime = add(utcToLocal(exception), { days: 1 })
    const start = format(startTime, ISOFormat)
    const end = format(endTime, ISOFormat)

    return {
      start,
      end,
      display: 'background',
      title: 'Not working day',
      allDay: true,
      overlap: false,
      color: COLORS.NON_WORKING_DAY,
    }
  })

  const pickupEvents = events.map(dayPickupDescription => {
    return dayPickupDescription.descriptions.map(pickup => {
      return createCalendarEventFromPickup(pickup, pickup.period.start, pickup.period.end)
    })
  })

  const reservationEvents = reservations.map(reservation => {
    const payload: Reservation = {
      notes: reservation.notes,
      createdBy: {
        id: reservation.createdBy,
        name: reservation.createdByName,
      },
      reservationId: reservation.id,
      period: {
        start: reservation.period.start,
        end: reservation.period.end,
      },
      pickupDate: reservation.pickupDate,
    }
    return createCalendarEventFromReservation(
      payload,
      reservation.period.start,
      reservation.period.end,
    )
  })

  const flattenEvents: EventInput[] = flatten(pickupEvents)

  return flattenEvents.concat(exceptionEvents).concat(reservationEvents)
}

export const useCalendarEvents = ({
  orderIds,
  warehouse,
  calendarComponentRef,
  onReservationClick,
  onPickupClick,
}: UseCalendarEventsProps): UseCalendarEventsState => {
  const userSettings = useUserSettings()
  const user = useAccountInfo()
  const [events, setEvents] = useState<EventInput[]>([])
  const [isEventsLoading, setEventsLoading] = useState<boolean>(false)

  const [{ allowPickupScheduleEdit }] = useAccessControl()

  const allowPerformCalendarAction = useCallback(
    (calendarEvent: EventDropArg | EventResizeDoneArg) => {
      if (allowPickupScheduleEdit) {
        return true
      }
      NotificationSystem.addNotification({
        level: 'info',
        title: 'You do not have permissions to perform action',
      })
      calendarEvent.revert()
      return false
    },
    [allowPickupScheduleEdit],
  )

  const fetchPickupsDescriptions = useCallback(async () => {
    if (warehouse) {
      setEventsLoading(true)
      const [pickupEvents, reservations] = await Promise.all([
        getPickupDescriptions(warehouse.locationId, orderIds),
        getReservations(warehouse.locationId),
      ])
      setEventsLoading(false)

      // If opened for one order's pickup should go to it's date (it goes to now by default)
      if (orderIds.length === 1 && calendarComponentRef.current != null) {
        const lastDate = pickupEvents[pickupEvents.length - 1].date
        const calendarApi = calendarComponentRef.current.getApi()
        calendarApi.gotoDate(lastDate)
      }

      const calendarEvents = convertDataToEvents(
        warehouse,
        pickupEvents,
        orderIds.length > 0 ? [] : reservations, // hide reservations when exists selected orders
      )
      setEvents(calendarEvents)
    }
  }, [calendarComponentRef, orderIds, warehouse])

  useEffect(() => {
    fetchPickupsDescriptions()
  }, [fetchPickupsDescriptions])

  const refreshCalendarEvents = async () => {
    await fetchPickupsDescriptions()
  }

  const handleDeleteReservation = useCallback(
    (reservationId: string) => {
      const newEvents = events.filter(event => {
        const { payload, type } = event
        const eventType = type as EventType
        if (eventType === 'reservation') {
          const reservation = payload as Reservation
          return reservation.reservationId !== reservationId
        }

        return true
      })
      setEvents(newEvents)
    },
    [events],
  )

  const handleDeletePickup = useCallback(
    (pickupId: string) => {
      const newEvents = events.filter(event => {
        const { payload, type } = event
        const eventType = type as EventType
        if (eventType === 'pickup') {
          const pickup = payload as PickupDescription
          return pickup.id !== pickupId
        }

        return true
      })
      setEvents(newEvents)
    },
    [events],
  )

  const handleBindReservationWithOrder = useCallback(
    (reservationId: string, pickupDescription: ReservationPickupDescription) => {
      const newEvents = events.filter(event => {
        const { payload, type } = event
        const eventType = type as EventType
        if (eventType === 'reservation') {
          const reservation = payload as Reservation
          return reservation.reservationId !== reservationId
        }

        return true
      })

      const pickupEvent = createCalendarEventFromPickup(
        {
          ...pickupDescription,
          isBundled: false,
          shipmentIds: pickupDescription.shipmentId ? [pickupDescription.shipmentId] : [],
        },
        pickupDescription.period.start,
        pickupDescription.period.end,
      )
      newEvents.push(pickupEvent)
      setEvents(newEvents)
    },
    [events],
  )

  const handleEventClick = useCallback(
    (info: EventClickArg) => {
      const { payload, type } = info.event.extendedProps
      const eventType = type as EventType
      if (eventType === 'pickup') {
        const pickupDescrition = payload as PickupDescription
        onPickupClick(pickupDescrition)
      }
      if (eventType === 'reservation') {
        const reservation = payload as Reservation
        onReservationClick(reservation)
      }
    },
    [onPickupClick, onReservationClick],
  )

  const handleUpdateEventTime = useCallback(
    async (eventDropInfo: EventDropArg | EventResizeDoneArg) => {
      if (!allowPerformCalendarAction(eventDropInfo)) {
        return
      }

      const { event } = eventDropInfo
      const { type, payload } = event.extendedProps
      const eventType = type as EventType

      if (event.start && event.end) {
        // do not use differenceInHours cause it do not handle case when duration 1h 30 min
        if (differenceInMinutes(event.end, event.start) > 60) {
          NotificationSystem.addNotification({
            level: 'error',
            title: "Pickup/Reservation duration couldn't be more than 1 hour",
          })
        } else {
          const newCalendarEvent = await updateEventPickupTimeAsync(
            eventType,
            payload,
            event.start,
            event.end,
          )

          if (newCalendarEvent) {
            const newEvents = events.filter(calendarEvent => {
              return calendarEvent.id !== event.id
            })
            newEvents.push(newCalendarEvent)
            setEvents(newEvents)
            return
          }
        }
      }

      eventDropInfo.revert()
    },
    [allowPerformCalendarAction, events],
  )

  const handleEventReceive = useCallback(
    (reservationEvent: ReservationEvent) => {
      const reservation: Reservation = {
        notes: reservationEvent.notes || '',
        createdBy: {
          id: String(user.id),
          name: userSettings.currentUser.userInfo?.fullName || '',
        },
        reservationId: reservationEvent.id,
        period: reservationEvent.period,
        pickupDate: reservationEvent.pickupDate,
      }
      const newEvent = createCalendarEventFromReservation(
        reservation,
        reservation.period.start,
        reservation.period.end,
      )

      setEvents(events.concat(newEvent))
    },
    [events, user, userSettings],
  )

  const handleAddPickupEvent = useCallback(
    (createPickupEvent: CreatePickupEvent) => {
      const newEvent = createCalendarEventFromPickup(
        createPickupEvent.pickupDescription,
        createPickupEvent.start,
        createPickupEvent.end,
      )

      setEvents(events.concat(newEvent))
    },
    [events],
  )

  const handleClearCalendarEvents = useCallback(() => {
    setEvents([])
  }, [setEvents])

  return {
    isEventsLoading,
    events,
    onCalendarEventClick: handleEventClick,
    onBindReservationWithOrder: handleBindReservationWithOrder,
    onCalendarEventDragDrop: handleUpdateEventTime,
    onCalendarEventResize: handleUpdateEventTime,
    onDeleteReservationEvent: handleDeleteReservation,
    onDeletePickupEvent: handleDeletePickup,
    onClearCalendarEvents: handleClearCalendarEvents,
    onAddReservationEvent: handleEventReceive,
    onAddPickupEvent: handleAddPickupEvent,
    refreshCalendarEvents,
  }
}
