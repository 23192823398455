import { UiPolicy } from '@r1-webui/rtv-policymanagement-v1'
import { GroupedPolicy } from '../../../types/GroupedPolicy'
import { PolicyTerm } from '../../../types/PolicyTerm'
import { TermType } from '../../../constants/TermType'
import { ContactPerson } from '../../../types/ContactPerson'
import { ShippingInfo } from '../../../types/ShippingInfo'
import { PolicyStatus } from '../../../constants/PolicyStatus'
import { ProductType } from '../../../constants/ProductType'
import { ReturnReason } from '../../../constants/ReturnReason'
import { RequirementType } from '../../../constants/RequirementType'
import { ReturnMethod } from '../../../constants/ReturnMethod'
import { CollectionMethod } from '../../../constants/CollectionMethod'
import { Carrier } from '../../../constants/Carrier'
import { VendorInfo } from '../../../types/VendorInfo'

function getPolicyTerm({ policyTerm }: UiPolicy): PolicyTerm {
  const term = policyTerm && policyTerm.expiryDate ? TermType['Fixed-term'] : TermType['Open-ended']
  return {
    term,
    effectiveDate: policyTerm && policyTerm.effectiveDate ? policyTerm.effectiveDate : null,
    expiryDate: policyTerm && policyTerm.expiryDate ? policyTerm.expiryDate : null,
  }
}

function getVendorInfo({ vendorDetails }: UiPolicy): VendorInfo {
  return {
    vendorNumber: vendorDetails?.vendorInfo?.vendorNumber ?? null,
    vendorName: vendorDetails?.vendorInfo?.vendorName ?? null,
    remitVBU: vendorDetails?.vendorInfo?.remitVBU ?? null,
  }
}

function getContactPerson({ vendorDetails }: UiPolicy): ContactPerson {
  if (vendorDetails?.contacts && vendorDetails.contacts.length > 0) return vendorDetails.contacts[0]
  return {
    name: null,
    phoneNumber: null,
    email: null,
  }
}

function getShippingInfo({ vendorDetails }: UiPolicy): ShippingInfo {
  return {
    shippingAddress: vendorDetails?.shippingInformation?.shippingAddress ?? null,
    shippingCarrier: vendorDetails?.shippingInformation?.shippingCarrier
      ? Carrier[vendorDetails.shippingInformation.shippingCarrier]
      : null,
    shippingCarrierAccountNumber:
      vendorDetails?.shippingInformation?.shippingCarrierAccountNumber ?? null,
  }
}

function getTimeframeDays(value: number | undefined): string | null {
  if (value !== undefined) return String(value)
  return null
}

function getAvailableEnumValue<T>(
  value: string | 'NonAvailable' | undefined,
  enumeration: Record<string, string>,
) {
  if (value === undefined || value === 'NonAvailable') return null
  return enumeration[value] as unknown as T
}

export function toState(policy: UiPolicy): GroupedPolicy {
  return {
    status: PolicyStatus[policy.status],
    id: policy.id,
    isDefault: policy.isDefault,
    policyTerm: getPolicyTerm(policy),
    vendorDetails: {
      vendorInfo: getVendorInfo(policy),
      contact: getContactPerson(policy),
      shippingInfo: getShippingInfo(policy),
    },
    productConditions: {
      type: ProductType[policy.productConditions.type],
      returnReason: getAvailableEnumValue(policy.productConditions.returnReason, ReturnReason),
      itemNumbers: policy.productConditions.itemNumbers.map(itemNumber => String(itemNumber)),
    },
    rtvRequirements: {
      type: getAvailableEnumValue(policy.rtvRequirements.type, RequirementType),
      number: policy.rtvRequirements.number ?? null,
      vendorInspection: {
        required: policy.rtvRequirements.vendorInspection.required,
        timeframeDays: getTimeframeDays(policy.rtvRequirements.vendorInspection.timeframeDays),
      },
      conditionalAssessmentRequired: policy.rtvRequirements.conditionalAssessmentRequired,
      purchaseOrderRequired: policy.rtvRequirements.purchaseOrderRequired,
      serialNumberRequired: policy.rtvRequirements.serialNumberRequired,
      returnMethod: getAvailableEnumValue(policy.rtvRequirements.returnMethod, ReturnMethod),
      collectionMethod: {
        type: getAvailableEnumValue(policy.rtvRequirements.collectionMethod.type, CollectionMethod),
        timeframeDays: getTimeframeDays(policy.rtvRequirements.collectionMethod.timeframeDays),
      },
      note: policy.rtvRequirements.note,
    },
  }
}
