// @flow

import { queryParametersUtils } from '../../../../utils'
import type {
  Program,
  FilterStateProgram,
  FilterStateDivert,
  FilterStateLocationType,
} from './types'

export const getFilterStateFromQueryParameters = ({
  programs,
}: {|
  programs: Program[],
|}): {|
  program: FilterStateProgram,
  divert: FilterStateDivert,
  locationType: FilterStateLocationType,
|} => {
  const { getValueByKey } = queryParametersUtils

  const programIdString = getValueByKey('programId') || ''
  const locationTypeString = getValueByKey('locationType') || ''
  const divertString = getValueByKey('divert') || ''

  const programId = programIdString === '' ? null : programIdString
  const divert = divertString === '' ? null : divertString
  let locationType = null
  switch (locationTypeString) {
    case 'Bin':
    case 'CatchAll':
    case 'Gaylord':
      locationType = locationTypeString
      break
    default:
  }

  const program = programs.find(p => p.id === programId) || null

  return {
    program,
    divert,
    locationType,
  }
}

export const saveFilterStateToQueryParameters = ({
  program,
  divert,
  locationType,
}: {|
  program: FilterStateProgram,
  divert: FilterStateDivert,
  locationType: FilterStateLocationType,
|}) => {
  const { setQueries } = queryParametersUtils

  const queries = [
    {
      key: 'programId',
      value: (program && program.id) || '',
    },
    {
      key: 'divert',
      value: divert ? String(divert) : '',
    },
    {
      key: 'locationType',
      value: locationType || '',
    },
  ]

  setQueries(queries)
}
