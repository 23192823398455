import React, { useCallback } from 'react'
import styled from '@emotion/styled'

import { Flex, Box, H4, Text, useConfigurationProvider } from '@r1/ui-kit'

export interface TableItem {
  key: string | React.ReactNode
  value: string | number | null | undefined | React.ReactNode
}
interface PivotTableProps {
  title?: string
  items: TableItem[]
}

const TableContainer = styled(Flex)`
  box-shadow: 0 1px 0 0 ${props => props.theme.palette.grey[300]};
`

const Title = styled(H4)`
  margin: 0;
  margin-bottom: ${props => props.theme.space.S};
`

export const Row = styled(Flex)`
  box-shadow: 0 -1px 0 0 ${props => props.theme.palette.grey[300]};
`

export const Block = styled(Box)`
  width: 210px;
`
export const ColumnHeader = styled(Block)`
  align-items: center;
  background-color: rgba(22, 41, 63, 0.04);
`

export const ColumnValue = styled(Box)`
  align-items: center;
`

export const PivotTable = React.memo<PivotTableProps>(({ items, title }) => {
  const { palette } = useConfigurationProvider()

  const renderItemValue = useCallback(
    (item: TableItem) => {
      if (React.isValidElement(item.value)) {
        return item.value
      }

      return <Text color={palette.grey[900]}>{item.value || '-'}</Text>
    },
    [palette.grey],
  )

  return (
    <TableContainer column minWidth={1} mb="L">
      {title && <Title>{title}</Title>}

      {items.map((item, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <Row key={index} align="stretch">
            <ColumnHeader p="M">
              <Text color={palette.grey[900]} weight="medium">
                {item.key}
              </Text>
            </ColumnHeader>
            <ColumnValue grow p="M" maxWidth={0.6}>
              {renderItemValue(item)}
            </ColumnValue>
          </Row>
        )
      })}
    </TableContainer>
  )
})
