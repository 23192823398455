import { useCallback, useEffect, useState } from 'react'
import { AppearanceMode } from '../../../constants/AppearanceMode'
import { getGroupedPolicy } from '../../../services/groupedPolicyManagementService/groupedPolicyManagementService'
import { GroupedPolicy } from '../../../types/GroupedPolicy'
import { emptyGroupedPolicy } from '../../../constants/emptyGroupedPolicy'
import { useURLSearchParamState } from '../../../hooks/useURLSearchParamState'

export const useGroupedPoliciesController = () => {
  const { get: getPolicyIdFromUrl, set: setPolicyIdIntoUrl } = useURLSearchParamState('policyId')

  const [policy, setPolicy] = useState<GroupedPolicy>(emptyGroupedPolicy)

  const [appearanceMode, setAppearanceMode] = useState<AppearanceMode>(AppearanceMode.Closed)

  const switchToViewMode = useCallback(() => {
    setAppearanceMode(AppearanceMode.View)
  }, [])

  const openViewPolicy = useCallback(
    async (policyId: string) => {
      setPolicyIdIntoUrl(policyId)
      setAppearanceMode(AppearanceMode.Loading)
      const response = await getGroupedPolicy(policyId)
      if (response.success) {
        setPolicy(response.data)
        setAppearanceMode(AppearanceMode.View)
        return
      }
      setPolicyIdIntoUrl(null)
      setAppearanceMode(AppearanceMode.Closed)
    },
    [setPolicyIdIntoUrl],
  )

  const closeDrawer = useCallback(() => {
    setPolicyIdIntoUrl(null)
    setAppearanceMode(AppearanceMode.Closed)
  }, [setPolicyIdIntoUrl])

  /**
   * Will invoked only once.
   * If url has a policyId query param it will open drawer automatically
   * */
  useEffect(() => {
    const policyId = getPolicyIdFromUrl()
    if (policyId !== null) {
      openViewPolicy(policyId)
    }
    // No deps in order to call this effect only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    policy,
    appearanceMode,

    switchToViewMode,

    closeDrawer,
    openViewPolicy,
  }
}
