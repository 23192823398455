export const emptyPolicy = {
  publicId: '',
  itemDescription: '',
  vendorName: '',
  vendorContactName: '',
  vendorContactEmailAddress: '',
  collectionMethod: '',
  returnMethod: '',
  policyRequirementForAcceptance: '',
  merchandisingGroupLevel: '',
  merchandisingGroupDescription: '',
  specialInstructionsForReturns: '',
  assortmentDescription: '',
  productGroupDescription: '',
  merchandiseSubdivisionDescription: '',
  merchandiseDescription: '',
  businessAreaDescription: '',
  returnAuthorizationNumber: '',
}
