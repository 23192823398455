// @flow

import React from 'react'

import { PickersGridView } from './PickersGridView'
import { PickersGridController } from './PickersGridController'

export const PickersGridScreen = () => {
  return (
    <PickersGridController>
      {propsFromController => <PickersGridView {...propsFromController} />}
    </PickersGridController>
  )
}
