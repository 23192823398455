import * as React from 'react'
import { useMemo, useState, useCallback, useRef } from 'react'

import { GridGenerator, setQuery, parseQuery } from '@r1/grid-generator'
import { PageAccessControl, PERMISSIONS, useAccessControl } from '@r1/core-blocks'
import { Main, Content, ContentHeader, useBackgroundTasksProvider } from '@r1/wireframe-primary'

import { httpClient } from '../../../../httpClient'

import { KeepInStore } from './components/KeepInStore'
import { CatalogActionButton, ActionId } from './components/ActionButton'

export const CatalogGrid = () => {
  const initialStateQuery = useMemo(parseQuery, [])
  const [allowPermissions] = useAccessControl()
  const [actionId, setActionId] = useState<ActionId | null>(null)
  const tableRef = useRef<GridGenerator>(null)
  const { showBackgroundTasks } = useBackgroundTasksProvider()

  const refreshGridData = useCallback(() => {
    if (tableRef.current) tableRef.current.refreshGrid()
  }, [tableRef])

  const onSelectItem = (id: ActionId) => setActionId(id)
  const onCloseAction = useCallback(() => setActionId(null), [])
  return (
    <PageAccessControl permissions={[PERMISSIONS.allowLowesCatalogView]}>
      <Main fullScreen>
        <ContentHeader>
          <ContentHeader.Title />
        </ContentHeader>
        <Content>
          <KeepInStore
            open={actionId === 'manageKeepInStore'}
            onClose={onCloseAction}
            onConfirm={refreshGridData}
          />
          <GridGenerator
            ref={tableRef}
            exportable={allowPermissions.allowLowesCatalogExport}
            gridId="RtvCatalog"
            gridEngineBaseUrl="/uisvc/contract-management-grid"
            initialStateQuery={initialStateQuery}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            httpClient={httpClient}
            aggregationCount={{
              title: 'All Products',
              suppressAggregation: false,
            }}
            toolbarActionButtons={
              allowPermissions.allowKeepInStoreEdit && (
                <CatalogActionButton onSelect={onSelectItem} />
              )
            }
            onChangeState={setQuery}
            onOpenBackgroundTasks={showBackgroundTasks}
          />
        </Content>
      </Main>
    </PageAccessControl>
  )
}
