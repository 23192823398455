import { createPoliciesApi } from '@r1-webui/rtv-policymanagement-v1'
import { httpClient } from '../../../../httpClient'

const API_PREFIX = `/uisvc/policy-management`

export const groupedPolicyManagementApi = createPoliciesApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: API_PREFIX,
})
