import * as React from 'react'
import { useCallback, useMemo, useState } from 'react'

import { Collapse, Drawer, Flex } from '@r1/ui-kit'

import { GroupedPolicy } from '../../../../types/GroupedPolicy'

import { AppearanceMode } from '../../../../constants/AppearanceMode'
import { DrawerLabel } from './blocks/DrawerLabel'
import { GroupedPolicyFormValues } from './types/GroupedPolicyForm'

import { VendorDetailsSection } from './blocks/VendorDetailsSection/VendorDetailsSection'
import { OverviewSection } from './blocks/OverviewSection/OverviewSection'
import { PolicyRulesSection } from './blocks/PolicyRulesSection/PolicyRulesSection'

type Props = {
  mode: AppearanceMode
  policy: GroupedPolicy
  onClose: () => void
}

const collapsePanelsIds = ['Overview', 'Vendor Details', 'Policy Rules']

export const GroupedPolicyDrawer: React.FC<Props> = ({ policy, mode, onClose }) => {
  const [activePanels, setActivePanels] = useState<string[]>(collapsePanelsIds)

  const title = useMemo(() => {
    switch (mode) {
      default:
        return `Policy #${policy.id?.substring(0, 15)}`
    }
  }, [mode, policy.id])

  const getOverviewSectionProps = useCallback((policyData: GroupedPolicyFormValues) => {
    return {
      isDefaultPolicy: policyData.isDefault,
      vendorName: policyData.vendorDetails.vendorInfo.vendorName,
      policyTerm: policyData.policyTerm,
    }
  }, [])

  const getVendorDetailsSectionProps = useCallback((policyData: GroupedPolicyFormValues) => {
    return {
      vendorDetails: policyData.vendorDetails,
    }
  }, [])

  const getPolicyRulesSectionProps = useCallback((policyData: GroupedPolicyFormValues) => {
    return {
      isDefaultPolicy: policyData.isDefault,
      productConditions: policyData.productConditions,
      rtvRequirements: policyData.rtvRequirements,
    }
  }, [])

  return (
    <Drawer.Form
      closeButton
      childrenAnimationKey={AppearanceMode[mode]}
      placement="right"
      size={800}
      loading={mode === AppearanceMode.Loading}
      show={mode !== AppearanceMode.Closed}
      backdrop={mode === AppearanceMode.View ? 'closing' : 'blocking'}
      label={<DrawerLabel status={policy.status} />}
      title={title}
      onClose={onClose}
    >
      <Flex column minWidth={1}>
        <Collapse active={activePanels} onChange={setActivePanels}>
          <Collapse.Panel id="Overview" title="Overview">
            <OverviewSection mode={mode} {...getOverviewSectionProps(policy)} />
          </Collapse.Panel>
          <Collapse.Panel id="Vendor Details" title="Vendor Details">
            <VendorDetailsSection mode={mode} {...getVendorDetailsSectionProps(policy)} />
          </Collapse.Panel>
          <Collapse.Panel id="Policy Rules" title="Policy Rules">
            <PolicyRulesSection mode={mode} {...getPolicyRulesSectionProps(policy)} />
          </Collapse.Panel>
        </Collapse>
      </Flex>
    </Drawer.Form>
  )
}
