import { parseISO, format, isSameDay, differenceInMinutes, differenceInSeconds } from 'date-fns'

export const parseAndFormatDate = (dateString: string | undefined): string | null => {
  if (!dateString) {
    return null
  }
  const date = parseISO(dateString)
  return format(date, 'MM/dd/yyyy')
}

/**
 *
 * event has occurred today -> 1 hour ago
 * more than one hour ago -> Today, 12:30 AM
 * event has occurred more than one day ago: {time} {date} -> March 8, 2021, 12:30 PM
 */
export const formatDateTime = (dateString: string): { date: string | null; time: string } => {
  const date = new Date(dateString)
  const now = new Date()

  if (isSameDay(date, now)) {
    const secDiff = differenceInSeconds(now, date)
    if (secDiff < 60) {
      return {
        time: 'A minute ago',
        date: null,
      }
    }
    const minutesDiff = differenceInMinutes(now, date)
    if (minutesDiff <= 60) {
      if (minutesDiff === 1) {
        return {
          time: `${minutesDiff} minute ago`,
          date: null,
        }
      }

      if (minutesDiff === 60) {
        return {
          time: '1 hour ago',
          date: null,
        }
      }

      return {
        time: `${minutesDiff} minutes ago`,
        date: null,
      }
    }

    return {
      time: `Today, ${format(date, 'HH:mm a')}`,
      date: null,
    }
  }

  return {
    time: format(date, 'HH:mm a'),
    date: format(date, 'LLL dd, yyyy'),
  }
}

// eslint-disable-next-line no-use-before-define
export const ejectProperties = Object.keys as <T>(o: T) => Extract<keyof T, string | number>[]
