import { Policy } from '@r1-webui/rtv-contractmanagement-v1'
import { createValidator, inOptions, requiredField } from '../../../../validation'
import { ValidationSchemas } from '../../../../validation/schemas'
import {
  RETURN_METHOD_OPTIONS,
  POLICY_REQUIREMENT_FOR_ACCEPTENCE_OPTIONS,
} from './helpers/constants'

export type ValidationPolicy = Pick<
  Policy,
  | 'itemNumber'
  | 'contractStartDate'
  | 'returnMethod'
  | 'policyRequirementForAcceptance'
  | 'vendorNumber'
  | 'vendorName'
>
const validationSchema: ValidationSchemas<ValidationPolicy> = {
  itemNumber: [requiredField()],
  vendorNumber: [requiredField()],
  vendorName: [requiredField()],
  contractStartDate: [requiredField()],
  returnMethod: [requiredField(), inOptions(RETURN_METHOD_OPTIONS)],
  policyRequirementForAcceptance: [
    requiredField(),
    inOptions(POLICY_REQUIREMENT_FOR_ACCEPTENCE_OPTIONS),
  ],
}

export const validatePolicy = createValidator(validationSchema)
