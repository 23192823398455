/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import { useMemo, useState } from 'react'
import { isEmpty } from 'lodash'
import { Collapse, Drawer, Flex, Label, Text } from '@r1/ui-kit'

import { PolicyStatus } from '../../../../constants/PolicyStatus'
import { Policy } from '../../../../types/Policy'

import { outOfDate } from '../../../../validation'
import { ObjectMap } from '../../../../types/ObjectMap'

import { AppearanceMode } from '../../../../constants/AppearanceMode'

import { mapPolicyStatusToLabelType } from '../../../../mappers/mapPolicyStatusToLabelType'
import { validatePolicy, ValidationPolicy } from './validation'
import { ProductInformationSection } from './blocks/ProductInformationSection'
import { ReturnAuthorizationSection } from './blocks/ReturnAuthorizationSection'
import { VendorInformationSection } from './blocks/VendorInforamtionSection'
import { PolicySection } from './blocks/PolicySection'

interface PolicyDetailsProps {
  mode: AppearanceMode
  policy: Policy
  onClose: () => void
}

const calculateStatus = (
  errors: ObjectMap<ValidationPolicy | Record<string, never>>,
  policyModel: Policy,
): PolicyStatus => {
  const { validator: validateOutDate } = outOfDate()
  // out of date error
  if (!validateOutDate(policyModel.contractEndDate || '')) {
    return PolicyStatus.Inactive
  }
  if (!isEmpty(errors)) {
    return PolicyStatus.MissingData
  }
  return PolicyStatus.Active
}

export const PolicyDetailsDrawer = ({ mode, policy, onClose }: PolicyDetailsProps) => {
  const [activePanels, setActivePanels] = useState<string[]>([])

  const policyStatus = useMemo(() => {
    const errors = validatePolicy({
      vendorNumber: policy.vendorNumber,
      vendorName: policy.vendorName,
      contractStartDate: policy.contractStartDate,
      returnMethod: policy.returnMethod,
      policyRequirementForAcceptance: policy.policyRequirementForAcceptance,
    })
    return calculateStatus(errors, policy)
  }, [policy])

  const productInformationSectionProps = useMemo(() => {
    return {
      itemNumber: policy.itemNumber,
      itemDescription: policy.itemDescription,
    }
  }, [policy])

  const returnAuthorizationSectionProps = useMemo(() => {
    return {
      returnAuthorizationNumber: policy.returnAuthorizationNumber,
      policyRequirementForAcceptance: policy.policyRequirementForAcceptance,
      specialInstructionsForReturns: policy.specialInstructionsForReturns,
      vendorContactEmailAddress: policy.vendorContactEmailAddress,
    }
  }, [policy])

  const vendorInformationSectionProps = useMemo(() => {
    return {
      vendorName: policy.vendorName,
      vendorNumber: policy.vendorNumber,
      remitVBU: policy.remitVBU,
    }
  }, [policy])

  const policySectionProps = useMemo(() => {
    return {
      returnMethod: policy.returnMethod,
      collectionMethod: policy.collectionMethod,
      contractStartDate: policy.contractStartDate,
      contractEndDate: policy.contractEndDate,
    }
  }, [policy])

  return (
    <Drawer.Form
      closeButton
      label={
        <Flex align="center" spaceBetween="XS">
          <Text>Policy</Text>
          <Label type={mapPolicyStatusToLabelType(policyStatus)}>{policyStatus}</Label>
        </Flex>
      }
      title={`Policy #${policy.publicId}`}
      loading={mode === AppearanceMode.Loading}
      show={mode !== AppearanceMode.Closed}
      backdrop="closing"
      size={680}
      placement="right"
      onClose={onClose}
    >
      <Flex column minWidth={1}>
        <Collapse active={activePanels} onChange={setActivePanels}>
          <Collapse.Panel id="Product Information" title="Product Information">
            <ProductInformationSection mode={mode} {...productInformationSectionProps} />
          </Collapse.Panel>
          <Collapse.Panel id="Return Authorization" title="Return Authorization">
            <ReturnAuthorizationSection mode={mode} {...returnAuthorizationSectionProps} />
          </Collapse.Panel>
          <Collapse.Panel id="Vendor Information" title="Vendor Information">
            <VendorInformationSection mode={mode} {...vendorInformationSectionProps} />
          </Collapse.Panel>
          <Collapse.Panel id="Policy" title="Policy">
            <PolicySection mode={mode} {...policySectionProps} />
          </Collapse.Panel>
        </Collapse>
      </Flex>
    </Drawer.Form>
  )
}
