import { useCallback, useEffect, useState } from 'react'
import { AppearanceMode } from '../../../constants/AppearanceMode'
import { getLastUploadedFileName, getPolicy } from '../../../services/policyManagementService'

import { Policy } from '../../../types/Policy'
import { useURLSearchParamState } from '../../../hooks/useURLSearchParamState'
import { emptyPolicy } from './emptyPolicy'

const extractDateFromName = (name: string): string => {
  const found = name.match(/\d+/)
  if (!found) {
    return ''
  }
  const date = found[0]
  const year = date.substring(0, 4)
  const month = date.substring(4, 6)
  const day = date.substring(6, 8)
  return `${month}-${day}-${year}`
}

export const usePoliciesController = () => {
  const { get: getPolicyIdFromUrl, set: setPolicyIdIntoUrl } = useURLSearchParamState('policyId')
  const [policy, setPolicy] = useState<Policy>(emptyPolicy)
  const [lastUpdate, setLastUpdate] = useState('')

  const [appearanceMode, setAppearanceMode] = useState<AppearanceMode>(AppearanceMode.Closed)

  const closeDrawer = useCallback(() => {
    setPolicyIdIntoUrl(null)
    setAppearanceMode(AppearanceMode.Closed)
  }, [setPolicyIdIntoUrl])

  const openViewPolicy = useCallback(
    async (policyId: string) => {
      setPolicyIdIntoUrl(policyId)
      setAppearanceMode(AppearanceMode.Loading)
      const policyData = await getPolicy(policyId)
      if (policyData !== null) {
        setPolicy(policyData)
        setAppearanceMode(AppearanceMode.View)
        return
      }
      setAppearanceMode(AppearanceMode.Closed)
    },
    [setPolicyIdIntoUrl],
  )

  /**
   * Will invoked only once.
   * If url has a policyId query param it will open drawer automatically
   * */
  useEffect(() => {
    const policyId = getPolicyIdFromUrl()
    if (policyId !== null) {
      openViewPolicy(policyId)
    }
    // No deps in order to call this effect only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function getLastUploadedFileNameAsync() {
      const fileName = await getLastUploadedFileName()
      if (fileName !== null) {
        setLastUpdate(extractDateFromName(fileName))
      }
    }
    getLastUploadedFileNameAsync()
  }, [])

  return {
    policy,
    lastUpdate,
    appearanceMode,

    closeDrawer,
    openViewPolicy,
  }
}
