import React from 'react'

import { Box, Flex, Text, useConfigurationProvider } from '@r1/ui-kit'

import { CopyToClipboard } from '../CopyToClipboard'

type Props = {
  value: string[] | number[]
}

export const CopyableTableItemValue: React.FC<Props> = ({ value }) => {
  const { palette } = useConfigurationProvider()

  return (
    <Flex align="center">
      <Text color={palette.grey[900]}>{value.length}</Text>
      <Box ml="S">
        <CopyToClipboard hidden={value.length === 0} value={value.join('\n')} />
      </Box>
    </Flex>
  )
}
