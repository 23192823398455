// @flow

import type { ControllerState } from './types'

export const MIN_PAGE_SIZE = 10

export const defaultState: ControllerState = {
  gridState: {
    isFetching: false,
    page: 1,
    pageSize: MIN_PAGE_SIZE,
    locations: [],
    isAllSelected: false,
    selectedLocationIds: [],
  },
  filterState: {
    isFetching: false,
    programs: [],
    locationTypes: [],
    program: null,
    divert: null,
    locationType: null,
  },
  locationManagementState: {
    isOpen: false,
    isLoading: false,
    type: 'create',
    locationId: null,
    locationName: null,
    locationType: null,
    divert: null,
    sort: null,
    sortingIndexId: null,
    sortingIndexes: [],
  },
  deleteConfirmationState: {
    isOpen: false,
    isLoading: false,
    location: null,
    locationIds: null,
  },
}
