import React from 'react'
import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { Main, Content, ContentHeader } from '@r1/wireframe-primary'
import { GroupedPolicyDrawer } from './View/GroupedPolicyDrawer/GroupedPolicyDrawer'

import { GroupedPolicyGrid } from './GroupedPolicyGrid'
import { useGroupedPoliciesController } from './Controllers/useGroupedPoliciesController'

export const GroupedPolicyGridScreen: React.FC = () => {
  const { policy, appearanceMode, closeDrawer, openViewPolicy } = useGroupedPoliciesController()

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowPolicyView]}>
      <Main fullScreen>
        <ContentHeader>
          <ContentHeader.Title />
        </ContentHeader>
        <Content>
          <GroupedPolicyGrid onDetailsClick={openViewPolicy} />
          <GroupedPolicyDrawer mode={appearanceMode} policy={policy} onClose={closeDrawer} />
        </Content>
      </Main>
    </PageAccessControl>
  )
}
