import React, { useMemo } from 'react'

import { PivotTable } from '../../../../../components/PivotTable/PivotTable'
import { InfoBlockWrapper } from '../../../../../components/InfoBlockWrapper'
import { FormLoadingPlaceholder } from '../../../../../../../components/FormLoadingPlaceholder'
import { AppearanceMode } from '../../../../../constants/AppearanceMode'

type Props = {
  mode: AppearanceMode
  returnAuthorizationNumber?: string
  policyRequirementForAcceptance?: string
  specialInstructionsForReturns?: string
  vendorContactEmailAddress?: string
}

export const ReturnAuthorizationSection: React.FC<Props> = ({
  mode,
  returnAuthorizationNumber,
  policyRequirementForAcceptance,
  specialInstructionsForReturns,
  vendorContactEmailAddress,
}) => {
  const tableItems = useMemo(() => {
    return [
      {
        key: 'RA #',
        value: returnAuthorizationNumber,
      },
      {
        key: 'RA Requirements',
        value: policyRequirementForAcceptance,
      },
      {
        key: 'Instructions',
        value: specialInstructionsForReturns,
      },
      {
        key: 'Email',
        value: vendorContactEmailAddress,
      },
    ]
  }, [
    policyRequirementForAcceptance,
    returnAuthorizationNumber,
    specialInstructionsForReturns,
    vendorContactEmailAddress,
  ])

  if (mode === AppearanceMode.Loading) {
    return (
      <InfoBlockWrapper>
        <FormLoadingPlaceholder numberOfFields={4} />
      </InfoBlockWrapper>
    )
  }

  if (mode === AppearanceMode.View) {
    return (
      <InfoBlockWrapper>
        <PivotTable items={tableItems} />
      </InfoBlockWrapper>
    )
  }

  return null
}
