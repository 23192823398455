/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'

import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { Main, Content, ContentHeader } from '@r1/wireframe-primary'

import { PolicyDetailsDrawer } from './View/PolicyDetails'
import { usePoliciesController } from './Controller/usePoliciesController'
import { PolicyGrid } from './PolicyGrid'

export const PolicyGridScreen = () => {
  const { policy, lastUpdate, appearanceMode, closeDrawer } = usePoliciesController()

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowPolicyItemView]}>
      <Main fullScreen>
        <ContentHeader>
          <ContentHeader.Title
            description={lastUpdate && `The policy data was updated on ${lastUpdate}`}
          />
        </ContentHeader>
        <Content>
          <PolicyDetailsDrawer mode={appearanceMode} policy={policy} onClose={closeDrawer} />
          <PolicyGrid />
        </Content>
      </Main>
    </PageAccessControl>
  )
}
