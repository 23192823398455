import React, { useMemo } from 'react'

import { PivotTable } from '../../../../../../components/PivotTable/PivotTable'

import { ProductConditions } from '../../../../../../types/ProductConditions'
import { RtvRequirements } from '../../../../../../types/RtvRequirements'
import { mapBoolToYesNo } from '../../../../../../mappers/mapBoolToYesNo'
import { CopyableTableItemValue } from '../../../../../../components/PivotTable/CopyableTableItemValue'

type Props = {
  productConditions: ProductConditions
  rtvRequirements: RtvRequirements
}

export const PolicyRulesSectionView: React.FC<Props> = ({ productConditions, rtvRequirements }) => {
  const inventoryUnderPolicyTableItems = useMemo(() => {
    return [
      {
        key: 'Item Numbers Count',
        value: <CopyableTableItemValue value={productConditions.itemNumbers} />,
      },
      {
        key: 'Product Type',
        value: productConditions.type,
      },
      {
        key: 'Return Reason',
        value: productConditions.returnReason,
      },
    ]
  }, [productConditions.itemNumbers, productConditions.returnReason, productConditions.type])

  const vendorInspectionTableValue = useMemo(() => {
    const { required, timeframeDays } = rtvRequirements.vendorInspection
    if (required && timeframeDays) {
      return `Required within ${timeframeDays} business days`
    }
    return mapBoolToYesNo(required)
  }, [rtvRequirements.vendorInspection])

  const collectionMethodTableValue = useMemo(() => {
    const { type, timeframeDays } = rtvRequirements.collectionMethod
    if (!type && !timeframeDays) return '-'
    if (!timeframeDays) return type
    return `${type} within ${timeframeDays} business days`
  }, [rtvRequirements.collectionMethod])

  const rtvRequirementsTableItems = useMemo(() => {
    return [
      {
        key: 'Notes',
        value: rtvRequirements.note,
      },
      {
        key: 'RA Requirements',
        value: rtvRequirements.type,
      },
      {
        key: 'RA #',
        value: rtvRequirements.number,
      },
      {
        key: 'Vendor Inspection',
        value: vendorInspectionTableValue,
      },
      {
        key: 'Conditional Assessment Required',
        value: mapBoolToYesNo(rtvRequirements.conditionalAssessmentRequired),
      },
      {
        key: 'Purchase Order Required',
        value: mapBoolToYesNo(rtvRequirements.purchaseOrderRequired),
      },
      {
        key: 'Serial Number Required',
        value: mapBoolToYesNo(rtvRequirements.serialNumberRequired),
      },
      {
        key: 'Return Method',
        value: rtvRequirements.returnMethod,
      },
      {
        key: 'Collection Method',
        value: collectionMethodTableValue,
      },
    ]
  }, [rtvRequirements, vendorInspectionTableValue, collectionMethodTableValue])

  return (
    <>
      <PivotTable title="Inventory under Policy" items={inventoryUnderPolicyTableItems} />
      <PivotTable title="RTV Requirements" items={rtvRequirementsTableItems} />
    </>
  )
}
