/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Flex } from '@r1/ui-kit'
import {
  Logo,
  Divider,
  WireframeProvider,
  NavigationApiService,
  WarehousesApiService,
  TutorialsMenu,
  PrintLoginLabelModal,
} from '@r1/wireframe-primary'
import type {
  INavigationApiService,
  INavigationItem,
} from '@r1/wireframe-primary/typings/WireframeProvider/types/NavigationApiService'

import {
  CookieDrawer,
  CookieBanner,
  CookieGroup,
  getIsCookieBannerShown,
  getDisabledCookiesFromLocalStorage,
  setDisabledCookiesToLocalStorage,
} from '@r1/cookie-consent-tool'

// TypeScript don't like when I use regular JS code with flow so I've commented them all
// @ts-expect-error
import { getUserAccount } from '../../modules/userAccount'
import {
  MemberLogo,
  SettingsMenu,
  BackgroundTasksContainer,
  HelpMenu,
  // @ts-expect-error
} from '../../components/NavigationHeader'
import { httpClient } from '../../httpClient'
// @ts-expect-error
import { browserHistory } from '../browserHistory'
// @ts-expect-error
import { useToggle } from '../../utils'
import { humanizeString } from '../../utils/humanizeString'
import { useAccessControl } from '../../containers/user-management/hooks'
import { isInIframe } from '../../utils/isInIframe'
import { COOKIE_LIST } from './cookieList'

type Catalog = {
  id: string
  name: string
  description: string
  draft: boolean
  companyId: number
}

type MasterLayoutProps = React.PropsWithChildren<{ handleLogout: () => void }>

export type INavigationItemWithRequiredPermissions = {
  permissions?: Array<string>
} & INavigationItem

export type INavigationApiServiceWithRequiredPermissions = Pick<
  INavigationApiService,
  'addToStarred' | 'deleteFromStarred'
> & {
  getMenuItems: (programId: number | null) => Promise<Array<INavigationItemWithRequiredPermissions>>
}

const PAGE_PREFIX_URL = '/r1'

const renderHeaderLeftSide = () => (
  <Flex align="center">
    <Logo pagePrefixUrl={PAGE_PREFIX_URL} />
    <Divider ml="XS" mr="M" />
    <MemberLogo iconName="goTrgLight" />
  </Flex>
)

const getRenderHeaderRightSide =
  ({ onCookieBannerOpen }: { onCookieBannerOpen: () => void }) =>
  () =>
    (
      <Flex align="center" spaceBetween="XS">
        <TutorialsMenu />
        <SettingsMenu />
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
        <BackgroundTasksContainer httpClient={httpClient} />
        <HelpMenu
          pagePrefixUrl={PAGE_PREFIX_URL}
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          httpClient={httpClient}
          onCookieBannerOpen={onCookieBannerOpen}
        />
      </Flex>
    )

const originalNavigationApi = new NavigationApiService(httpClient)

const PRODUCT_CATALOG_ITEM_ID = '8'
const SECONDARY_CATALOGS_ITEM = {
  id: 'catalogs',
  parentId: PRODUCT_CATALOG_ITEM_ID,
  title: 'Catalogs',
  description: 'Main and secondary catalogs',
  // url: '/app/product-catalog/catalogs',
  isStarred: false,
}

function generateSecondaryCatalogMenuSection(
  secondaryCatalog: Catalog,
): INavigationItemWithRequiredPermissions[] {
  return [
    {
      id: secondaryCatalog.id,
      parentId: SECONDARY_CATALOGS_ITEM.id,
      title: humanizeString(secondaryCatalog.name),
      description: secondaryCatalog.name,
      isStarred: false,
    },
    {
      id: `${secondaryCatalog.id}-families`,
      parentId: secondaryCatalog.id,
      title: 'Families',
      description: '',
      url: `/app/product-catalog/catalogs/${secondaryCatalog.id}/families`,
      isStarred: false,
    },
    {
      id: `${secondaryCatalog.id}-templates`,
      parentId: secondaryCatalog.id,
      title: 'Templates',
      description: '',
      url: `/app/product-catalog/catalogs/${secondaryCatalog.id}/templates`,
      isStarred: false,
    },
    {
      id: `${secondaryCatalog.id}-sftp-connections`,
      parentId: secondaryCatalog.id,
      title: 'SFTP connections',
      description: '',
      url: `/app/product-catalog/catalogs/${secondaryCatalog.id}/sftp-connections`,
      isStarred: false,
      permissions: ['CatalogImportSFTPSettings:View'],
    },
  ]
}

const navigationApi = {
  getMenuItems: async (programId: number | null) => {
    const originalMenuItems = await originalNavigationApi.getMenuItems(programId)
    let secondaryCatalogs: INavigationItemWithRequiredPermissions[] = []

    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const response: Array<Catalog> = await httpClient.get('/api/catalog-view/catalogs/secondary')

      if (Array.isArray(response)) {
        secondaryCatalogs = response.reduce<INavigationItemWithRequiredPermissions[]>(
          (catalogs, secondaryCatalog) => {
            if (secondaryCatalog?.draft) {
              return catalogs
            }
            const secondaryCatalogSection = generateSecondaryCatalogMenuSection(secondaryCatalog)
            return [...catalogs, ...secondaryCatalogSection]
          },
          [],
        )
      }
      return originalMenuItems.concat([SECONDARY_CATALOGS_ITEM]).concat(secondaryCatalogs)
    } catch (e) {
      console.info(e)
      return originalMenuItems
    }
  },
  addToStarred: originalNavigationApi.addToStarred,
  deleteFromStarred: originalNavigationApi.deleteFromStarred,
}

export const MasterLayout = (props: MasterLayoutProps) => {
  const { children, handleLogout } = props
  const allowedPermissions = useAccessControl()

  const [userAccount, setUserAccount] = useState()
  const [isCookieDrawerShown, setCookieDrawerShown] = useState(false)
  const [isCookieBannerShown, setCookieBannerShown] = useState(false)
  const [disabledCookies, setDisabledCookies] = useState<CookieGroup[]>([])

  const isPageOpenedInIframe = useMemo(() => isInIframe(), [])

  useEffect(() => {
    setCookieBannerShown(getIsCookieBannerShown())
  }, [])

  useEffect(() => {
    const cookies = getDisabledCookiesFromLocalStorage()
    if (cookies) {
      setDisabledCookies(cookies)
    }
  }, [])

  const onSaveCookieChoice = (v: CookieGroup[]) => {
    if (v.includes('performance')) {
      if (window.gtag) {
        window.gtag('consent', 'update', {
          ad_storage: 'denied',
          ad_user_data: 'denied',
          ad_personalization: 'denied',
          analytics_storage: 'denied',
        })
      }
    } else if (window.gtag) {
      window.gtag('consent', 'update', {
        analytics_storage: 'granted',
      })
    }
    setDisabledCookiesToLocalStorage(v)
    setDisabledCookies(v)
    setCookieBannerShown(false)
    setCookieDrawerShown(false)
  }

  const onDisableAllCookies = useCallback(() => {
    setDisabledCookiesToLocalStorage(['performance'])
    setCookieBannerShown(false)
  }, [])

  const onAcceptAllCookies = useCallback(() => {
    setDisabledCookiesToLocalStorage([])
    setCookieBannerShown(false)
  }, [])

  const { state: printLoginLabelModal, toggle: togglePrintLoginLabelModal } = useToggle(false)

  useEffect(() => {
    const fetchUser = async () => {
      const user = await getUserAccount()
      setUserAccount(user)
    }

    fetchUser()
  }, [])

  const warehousesApi = React.useMemo(() => new WarehousesApiService(httpClient), [])

  const accountMenuItems = React.useMemo(
    () => [
      { id: 'printLoginLabel', title: 'Print Login Label', action: togglePrintLoginLabelModal },
    ],
    [togglePrintLoginLabelModal],
  )

  const getMenuItems = async (programId: number | null) => {
    const result: INavigationItemWithRequiredPermissions[] = await navigationApi.getMenuItems(
      programId,
    )

    return result.filter(item => {
      // if menuItem permissions isn't set or empty - menu item will be rendered
      if (!item.permissions || !item.permissions.length) {
        return true
      }

      // if user has any of menuItem permission - menu item will be rendered
      return item.permissions.some(permission => allowedPermissions.includes(permission))
    })
  }

  const renderHeaderRightSide = React.useMemo(
    () => getRenderHeaderRightSide({ onCookieBannerOpen: () => setCookieDrawerShown(true) }),
    [],
  )

  return (
    <WireframeProvider
      key={allowedPermissions.length}
      allowExport
      withSearch="All"
      pagePrefixUrl={PAGE_PREFIX_URL}
      userAccount={userAccount}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      httpClient={httpClient}
      warehousesApi={warehousesApi}
      navigationApi={{ ...navigationApi, getMenuItems }}
      browserHistory={browserHistory}
      renderHeaderLeftSide={renderHeaderLeftSide}
      renderHeaderRightSide={renderHeaderRightSide}
      accountMenuItems={accountMenuItems}
      onLogout={handleLogout}
    >
      {children}
      <CookieDrawer
        show={isCookieDrawerShown}
        cookies={COOKIE_LIST}
        privacyPolicyLink="https://www.gotrg.com/legal/privacy"
        cookiePolicyLink="https://www.gotrg.com/legal/cookie-policy"
        termsOfServiceLink="https://www.gotrg.com/legal/terms"
        disabledCookies={disabledCookies}
        onSave={onSaveCookieChoice}
        onClose={() => setCookieDrawerShown(false)}
      />
      {isCookieBannerShown && !isPageOpenedInIframe && (
        <CookieBanner
          onAcceptAll={onAcceptAllCookies}
          onDeclineAll={onDisableAllCookies}
          onCustomize={() => setCookieDrawerShown(true)}
        />
      )}
      <PrintLoginLabelModal isOpen={printLoginLabelModal} onClose={togglePrintLoginLabelModal} />
    </WireframeProvider>
  )
}
