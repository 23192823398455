import React, { useMemo } from 'react'

import { VendorDetails } from '../../../../../../types/VendorDetails'
import { PivotTable } from '../../../../../../components/PivotTable/PivotTable'

type Props = {
  vendorDetails: VendorDetails
}

export const VendorDetailsSectionView: React.FC<Props> = ({
  vendorDetails: { vendorInfo, contact, shippingInfo },
}) => {
  const vendorTableItems = useMemo(() => {
    return [
      {
        key: 'Vendor Name',
        value: vendorInfo.vendorName,
      },
      {
        key: 'Vendor #',
        value: vendorInfo.vendorNumber,
      },
      {
        key: 'Remit VBU',
        value: vendorInfo.remitVBU,
      },
    ]
  }, [vendorInfo])

  const shippingTableItems = useMemo(() => {
    return [
      {
        key: 'Address',
        value: shippingInfo.shippingAddress,
      },
      {
        key: 'Carrier',
        value: shippingInfo.shippingCarrier,
      },
      {
        key: 'Carrier Account Number',
        value: shippingInfo.shippingCarrierAccountNumber,
      },
    ]
  }, [shippingInfo])

  const contactsTableItems = useMemo(() => {
    return [
      {
        key: 'Contact Name',
        value: contact.name,
      },
      {
        key: 'Email',
        value: contact.email,
      },
      {
        key: 'Phone',
        value: contact.phoneNumber,
      },
    ]
  }, [contact])

  return (
    <>
      <PivotTable title="Identification" items={vendorTableItems} />
      <PivotTable title="Contact" items={contactsTableItems} />
      <PivotTable title="Shipping Information" items={shippingTableItems} />
    </>
  )
}
