import { GroupedPolicy } from '../types/GroupedPolicy'
import { TermType } from './TermType'

export const emptyGroupedPolicy: GroupedPolicy = {
  status: null,
  id: null,
  isDefault: false,
  policyTerm: {
    term: TermType['Open-ended'],
    effectiveDate: null,
    expiryDate: null,
  },
  vendorDetails: {
    vendorInfo: {
      vendorNumber: null,
      vendorName: null,
      remitVBU: null,
    },
    contact: {
      name: null,
      phoneNumber: null,
      email: null,
    },
    shippingInfo: {
      shippingAddress: null,
      shippingCarrier: null,
      shippingCarrierAccountNumber: null,
    },
  },
  productConditions: {
    type: null,
    returnReason: null,
    itemNumbers: [],
  },
  rtvRequirements: {
    type: null,
    number: null,
    vendorInspection: {
      required: false,
      timeframeDays: null,
    },
    conditionalAssessmentRequired: false,
    purchaseOrderRequired: false,
    serialNumberRequired: false,
    returnMethod: null,
    collectionMethod: {
      type: null,
      timeframeDays: null,
    },
    note: null,
  },
}
