import * as React from 'react'
import { useState, useCallback } from 'react'
import { TagInput, Modal, Flex, Text } from '@r1/ui-kit'
import { ControlledActionButtons } from '@r1/ui-kit/contracts/ts/Modal'

import {
  addItemsToKeepInStore,
  removeItemsFromKeepInStore,
} from '../../../services/keepInStoreService'

type KeepInStoreProps = {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

type KeepInStoreViewProps = {
  items: number[]
  isOpen: boolean
  isKISLoading: boolean
  isRLCLoading: boolean
  onChange: (values: number[]) => void
  onSaveKeepInStore: () => void
  onSaveSendToRlc: () => void
  onClose: () => void
}

const KeepInStoreView = ({
  items,
  isOpen,
  isKISLoading,
  isRLCLoading,
  onChange,
  onClose,
  onSaveKeepInStore,
  onSaveSendToRlc,
}: KeepInStoreViewProps) => {
  const disableButton = items.length === 0 || isKISLoading || isRLCLoading

  const actionButtons: ControlledActionButtons = [
    {
      align: 'right',
      title: 'Cancel',
      color: 'secondary',
      onClick: onClose,
    },
    {
      align: 'right',
      title: 'Keep In Store',
      color: 'primary',
      loading: isKISLoading,
      disabled: disableButton,
      onClick: onSaveKeepInStore,
    },
    {
      align: 'right',
      title: 'Send To RLC',
      color: 'primary',
      loading: isRLCLoading,
      disabled: disableButton,
      onClick: onSaveSendToRlc,
    },
  ]
  return (
    <Modal
      isControlled
      size="S"
      title='Manage "Keep In Store" items'
      show={isOpen}
      actionButtons={actionButtons}
      onEscapeKeyDown={onClose}
    >
      <Flex column spaceBetween="M">
        <Text>Added Items ({items.length})</Text>
        <TagInput
          $type="simpleTagValue"
          tagLimit={3}
          placeholder="Enter values"
          value={items.map(String)}
          accept={/^\d+$/}
          delimiter={['\n', ',']}
          onChange={(values: string[]) => {
            const safeValues = values.map(v => parseInt(v, 10))
            onChange(safeValues)
          }}
        />
      </Flex>
    </Modal>
  )
}

export const KeepInStore = ({ open, onClose, onConfirm }: KeepInStoreProps) => {
  const [items, setItems] = useState<number[]>([])
  const [isRLCLoading, setRLCLoading] = useState<boolean>(false)
  const [isKISLoading, setKISLoading] = useState<boolean>(false)

  const closeKeepInStore = useCallback(() => {
    setItems([])
    onClose()
  }, [onClose])

  const saveItemsAsKeepInStore = async () => {
    setKISLoading(true)
    await addItemsToKeepInStore(items)
    setKISLoading(false)
    onConfirm()
  }
  const saveItemsAsSendToRlc = async () => {
    setRLCLoading(true)
    await removeItemsFromKeepInStore(items)
    setRLCLoading(false)
    onConfirm()
  }

  const props: KeepInStoreViewProps = {
    items,
    isOpen: open,
    isKISLoading,
    isRLCLoading,
    onClose: closeKeepInStore,
    onChange: setItems,
    onSaveKeepInStore: saveItemsAsKeepInStore,
    onSaveSendToRlc: saveItemsAsSendToRlc,
  }

  return <KeepInStoreView {...props} />
}
