import React, { useMemo } from 'react'

import { PivotTable } from '../../../../../components/PivotTable/PivotTable'
import { InfoBlockWrapper } from '../../../../../components/InfoBlockWrapper'
import { FormLoadingPlaceholder } from '../../../../../../../components/FormLoadingPlaceholder'
import { AppearanceMode } from '../../../../../constants/AppearanceMode'

type Props = {
  mode: AppearanceMode
  itemNumber?: number
  itemDescription?: string
}

export const ProductInformationSection: React.FC<Props> = ({
  mode,
  itemNumber,
  itemDescription,
}) => {
  const tableItems = useMemo(() => {
    return [
      {
        key: 'Item Number',
        value: itemNumber,
      },
      {
        key: 'Product Name',
        value: itemDescription,
      },
    ]
  }, [itemDescription, itemNumber])

  if (mode === AppearanceMode.Loading) {
    return (
      <InfoBlockWrapper>
        <FormLoadingPlaceholder numberOfFields={2} />
      </InfoBlockWrapper>
    )
  }

  if (mode === AppearanceMode.View) {
    return (
      <InfoBlockWrapper>
        <PivotTable items={tableItems} />
      </InfoBlockWrapper>
    )
  }

  return null
}
