/* eslint-disable no-console */
// @flow

import { handleServerError } from '@r1/core-blocks'
import { programsApi } from '../../../../api/lightningPickConveyorManagement'
import type { Program } from './types'

export const getPrograms = async (): Promise<Program[]> => {
  try {
    const response = await programsApi.getPrograms()
    if (response.status !== 200) {
      handleServerError(response)
      return []
    }

    return response.body
  } catch (error) {
    console.error(error)
    return []
  }
}
