import React from 'react'

import { PolicyTerm } from '../../../../../../types/PolicyTerm'
import { FormLoadingPlaceholder } from '../../../../../../../../components/FormLoadingPlaceholder'
import { InfoBlockWrapper } from '../../../../../../components/InfoBlockWrapper'
import { AppearanceMode } from '../../../../../../constants/AppearanceMode'
import { OverviewSectionView } from './OverviewSectionView'

type Props = {
  mode: AppearanceMode
  isDefaultPolicy: boolean
  vendorName: string | null
  policyTerm: PolicyTerm
}

export const OverviewSection: React.FC<Props> = ({
  mode,
  isDefaultPolicy,
  vendorName,
  policyTerm,
}) => {
  if (mode === AppearanceMode.Loading) {
    return (
      <InfoBlockWrapper>
        <FormLoadingPlaceholder numberOfFields={5} />
      </InfoBlockWrapper>
    )
  }

  return (
    <InfoBlockWrapper>
      <OverviewSectionView
        isDefaultPolicy={isDefaultPolicy}
        vendorName={vendorName}
        policyTerm={policyTerm}
      />
    </InfoBlockWrapper>
  )
}
