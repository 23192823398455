// @flow

import { types, type ControllerState, type ControllerReducerAction } from './types'

export const reducer = (
  state: ControllerState,
  action: ControllerReducerAction,
): ControllerState => {
  switch (action.type) {
    case types.GRID_SET_FETCHING:
      return { ...state, gridState: { ...state.gridState, isFetching: action.payload } }
    case types.GRID_SET_PICKERS:
      return { ...state, gridState: { ...state.gridState, pickers: action.payload } }
    case types.GRID_SET_TOTAL_COUNT:
      return { ...state, gridState: { ...state.gridState, totalCount: action.payload } }
    case types.GRID_SET_ALL_SELECTED:
      return { ...state, gridState: { ...state.gridState, isAllSelected: action.payload } }
    case types.GRID_SET_SELECTED_PICKER_IDS:
      return { ...state, gridState: { ...state.gridState, selectedPickerIds: action.payload } }
    case types.GRID_SET_PAGE:
      return { ...state, gridState: { ...state.gridState, page: action.payload } }
    case types.GRID_SET_PAGE_SIZE:
      return { ...state, gridState: { ...state.gridState, pageSize: action.payload } }
    case types.FILTER_SET_PROGRAMS:
      return { ...state, filterState: { ...state.filterState, programs: action.payload } }
    case types.FILTER_SET_PROGRAM_ID:
      return { ...state, filterState: { ...state.filterState, programId: action.payload } }
    case types.FILTER_SET_FETCHING:
      return { ...state, filterState: { ...state.filterState, isFetching: action.payload } }
    case types.FILTER_SET_LOGINS:
      return { ...state, filterState: { ...state.filterState, logins: action.payload } }
    case types.FILTER_SET_NAMES:
      return { ...state, filterState: { ...state.filterState, names: action.payload } }
    case types.FILTER_SET_BARCODES:
      return { ...state, filterState: { ...state.filterState, barCodes: action.payload } }
    default:
      return state
  }
}
