/* eslint-disable no-console */
// @flow

import { handleServerError } from '@r1/core-blocks'
import { sortingIndexesApi } from '../../../../api/lightningPickConveyorManagement'
import type { FilterStateProgram } from '../../components/LocationsGrid/types'
import type { SortingIndex } from './types'

export const searchSortingIndexes = async ({
  program,
  searchTerm,
  limit,
}: {
  program: FilterStateProgram,
  searchTerm: string,
  limit: number,
}): Promise<SortingIndex[]> => {
  if (program) {
    try {
      const response = await sortingIndexesApi.searchSortingIndexes({
        programId: program.id,
        searchTerm,
        limit,
      })
      if (response.status !== 200) {
        handleServerError(response)
        return []
      }

      return response.body
    } catch (error) {
      console.error(error)
      return []
    }
  } else {
    console.error('Program is missing')
    return []
  }
}

export const getSortingIndexes = async (program: FilterStateProgram) => {
  if (program) {
    try {
      const response = await sortingIndexesApi.getSortingIndexes({ programId: program.id })
      if (response.status !== 200) {
        handleServerError(response)
        return []
      }

      return response.body
    } catch (error) {
      console.error(error)
      return []
    }
  } else {
    console.error('Program is missing')
    return []
  }
}
