import * as React from 'react'
import { Route, Navigate, Routes, useLocation } from 'react-router-dom'
import { useUserSettings } from '@r1/core-blocks'

import { redirect, redirectToLogin, ROUTES } from '../../navigation'
import { httpClient, addUnauthorizedErrorHandler } from '../../httpClient'
import { Loader } from '../../components/Loader'

import { ViewLPConveyorLocationsGrid } from '../../containers/lp-conveyor/routes/ViewLPConveyorLocationsGrid'
import { ViewLPConveyorPickersGrid } from '../../containers/lp-conveyor/routes/ViewLPConveyorPickersGrid'

import ProfileConfigurator from '../../containers/tax-commitment-reporter/routes/ProfileConfigurator'
import { PurchaseOrders } from '../../containers/tax-commitment-reporter/routes/PurchaseOrders'
import { FinancialEvents } from '../../containers/tax-commitment-reporter/routes/FinancialEvents'

import { PurposeList } from '../../containers/espm/routes/PurposeList'
import { ProfileList } from '../../containers/espm/routes/ProfileList'
import { EditProfile } from '../../containers/espm/routes/EditProfile'
import { CreateProfile } from '../../containers/espm/routes/CreateProfile'

import { ViewUsersGrid } from '../../containers/user-management/routes/ViewUsersGrid/ViewUsersGrid.tsx'
import { CreateUser } from '../../containers/user-management/routes/CreateUser/CreateUser.tsx'
import { EditUser } from '../../containers/user-management/routes/EditUser/EditUser.tsx'
import { RolesGrid } from '../../containers/user-management/routes/Roles/RolesGrid/RolesGrid.tsx'
import { RolePage } from '../../containers/user-management/routes/Roles/RolePage/RolePage.tsx'

import { ViewCategoriesGrid } from '../../containers/product-catalog/routes/ViewCategoriesGrid'
import { CategoryCreate } from '../../containers/product-catalog/routes/CategoryCreate'
import { CategoryDetails } from '../../containers/product-catalog/routes/CategoryDetails'
import { ViewProductsGrid } from '../../containers/product-catalog/routes/ViewProductsGrid'
import { ProductDetails } from '../../containers/product-catalog/routes/ProductDetails'
import { ProductView } from '../../containers/product-catalog/routes/ProductDetails/ProductView'
import { PricingRules } from '../../containers/product-catalog/routes/PricingRules'
import {
  GlobalPricingRules,
  GlobalPricingRuleView,
} from '../../containers/product-catalog/routes/GlobalPricingRules'
import { VariationsGrid } from '../../containers/product-catalog/routes/VariationsGrid'
import { VariationCreate } from '../../containers/product-catalog/routes/VariationCreate'
import { VariationEdit } from '../../containers/product-catalog/routes/VariationsEdit'
import {
  AttributeDefinitionsList,
  CreateAttribute,
  AttributeDetails,
} from '../../containers/product-catalog/routes/AttributeDefinitions'

import { ReturnsAuthorizationManagementGrid } from '../../containers/inventory/routes/ReturnsAuthorizationManagementGrid'
import { InventoryGrid } from '../../containers/inventory/routes/InventoryGrid'

import { ViewDiscountsGrid } from '../../containers/discounts/routes/ViewDiscountsGrid.tsx'
import { AddDiscountsRule } from '../../containers/discounts/routes/AddDiscountsRule.tsx'
import { EditDiscountsRule } from '../../containers/discounts/routes/EditDiscountsRule.tsx'

import { FacilitiesGrid } from '../../containers/supply-chain/routes/FacilitiesGrid/index.ts'
import { MasterProgramGridScreen } from '../../containers/supply-chain/routes/MasterProgramGrid/index.tsx'

import { SchedulePickup } from '../../containers/shipping/routes/SchedulePickup/SchedulePickup.tsx'

import { PolicyGridScreen } from '../../containers/policy-management/routes/PolicyGrid/index.tsx'
import { CatalogGrid } from '../../containers/policy-management/routes/LowesCatalogGrid/index.tsx'
import { GroupedPolicyGridScreen } from '../../containers/policy-management/routes/GroupedPolicyGrid/index.tsx'

import { ViewClaimsGrid } from '../../containers/rma-vendor/routes/ViewClaimsGrid'
import { CreateClaim } from '../../containers/rma-vendor/routes/CreateClaim'
import { ViewClaim } from '../../containers/rma-vendor/routes/ViewClaim'

import { RmaShippingRules } from '../../containers/rma-shipping-rules/routes/RmaShippingRulesGrid/RmaShippingRulesScreen.tsx'

import { ViewLabelsGrid, EditLabel } from '../../containers/label-management/routes'

import { ViewSupplyLevelRulesGrid } from '../../containers/parts/routes/ViewSupplyLevelRulesGrid.tsx'
import { AddSupplyLevelRule } from '../../containers/parts/routes/AddSupplyLevelRule.tsx'
import { EditSupplyLevelRule } from '../../containers/parts/routes/EditSupplyLevelRule.tsx'

import { GoWholesaleRootScreen } from '../../containers/gowholesale/routes/gowholesale-root'
import { GoWholesaleNotificationsScreen } from '../../containers/gowholesale/routes/gowholesale-notifications'
import { GoWholesaleHomePageEditorScreen } from '../../containers/gowholesale/routes/gowholesale-home-page-editor'

import { OfferManagementOffersGridScreen } from '../../containers/offer-management/routes'

import { DepositManagementDepositsGridScreen } from '../../containers/deposit-management/routes'

import { SiteMap } from '../../containers/sitemap/routes/SiteMap'

import { ReportGroupsScreen, ReportGroupScreen } from '../../containers/reports/routes'

import { FMSIExportPresets } from '../Admin/FMSIExportPresets/FMSIExportPresets'
import { OrderProductPickUpSettings } from '../Admin/OrderProductPickupSettings/OrderProductPickupSettings'
import { ConditionMappings } from '../Admin/Metadata/ConditionMappingProfiles/ConditionMappingsProfilesGrid'
import EditProfileMappings from '../Admin/Metadata/ConditionMappings/ConditionMappingsGrid'
import { DecisionRulesGrid } from '../Admin/DecisionRulesGrid/DecisionRulesGrid'
import { CategoryMapping } from '../Admin/ProductCatalog/CategoryMapping'

import { PowerBiReport } from '../PowerBiReport'

import { ProductTemplateScreen } from '../ProductTemplate/ProductTemplateScreen'

import { ProductTemplatePricesScreen } from '../ProductTemplatePrices/ProductTemplatePricesScreen'
import { ProductTemplatePricesAnomaliesByRule } from '../ProductTemplatePrices/ProductTemplatePricesAnomaliesByRule'
import { ProductTemplatePricesAnomaliesByProduct } from '../ProductTemplatePrices/ProductTemplatePricesAnomaliesByProduct'

import { ProductCard } from '../ProductCard/ProductCard'

import { ConsolidationStepsScreen } from '../Consolidator/ConsolidationStepsScreen'

import { FinancialEventsGrid } from '../Accounting/FinancialEventGrid/FinancialEventsGrid'

import { MasterLayout } from '../MasterLayout/MasterLayout.tsx'
import { HomePage } from '../HomePage'
import { NoPermission } from '../NoPermission/NoPermission'
import { NotFound } from '../NotFound/NotFound'
import { InitialPage } from './InitialPage'

export default () => {
  const location = useLocation()
  const { authenticateUser, logoutUser, isUserAuthorized } = useUserSettings()

  React.useEffect(() => {
    if (window.analytics) {
      window.analytics.setPage(location.pathname)
      window.analytics.sendPageView()
    }
  }, [location.pathname])

  const handleSystemLogout = React.useCallback(() => {
    logoutUser()
    httpClient.logout()
    redirectToLogin()
  }, [logoutUser])

  React.useEffect(() => {
    const authenticate = async () => {
      const { authenticated } = await authenticateUser()
      if (!authenticated) {
        handleSystemLogout()
      }
    }
    authenticate()
  }, [authenticateUser, handleSystemLogout])

  React.useEffect(() => {
    addUnauthorizedErrorHandler(() => {
      handleSystemLogout()
    })
  }, [handleSystemLogout])

  const handleManualLogout = React.useCallback(() => {
    logoutUser()
    httpClient.logout()
    redirect(ROUTES.LOGIN)
  }, [logoutUser])

  if (!isUserAuthorized) {
    return <Loader />
  }

  return (
    <MasterLayout handleLogout={handleManualLogout}>
      <Routes>
        <Route exact path={ROUTES.R1_HOME} element={<HomePage />} />
        <Route exact path={ROUTES.R1} element={<Navigate to={ROUTES.R1_HOME} />} />
        <Route path={ROUTES.AUTH_REDIRECT} element={<Navigate to={ROUTES.R1_HOME} />} />

        <Route path={ROUTES.FMSI_EXPORT_PRESETS} element={<FMSIExportPresets />} />
        <Route
          path={ROUTES.ORDER_PRODUCT_PICKUP_SETTINGS}
          element={<OrderProductPickUpSettings />}
        />

        {/* Condition mappings */}
        <Route path={ROUTES.CONDITION_MAPPINGS} element={<ConditionMappings />} />
        <Route path={ROUTES.CONDITION_MAPPINGS_EDIT} element={<EditProfileMappings />} />

        <Route path={ROUTES.DECISION_GRID} element={<DecisionRulesGrid />} />
        <Route path={ROUTES.REPORTS_DASHBOARDS} element={<ReportGroupsScreen />} />
        <Route path={ROUTES.REPORTS_GROUP} element={<ReportGroupScreen />} />

        {/* Conveyor */}
        <Route path={ROUTES.CONVEYOR_LOCATIONS} element={<ViewLPConveyorLocationsGrid />} />
        <Route path={ROUTES.CONVEYOR_PICKERS} element={<ViewLPConveyorPickersGrid />} />

        {/* External services profile management */}
        <Route exact path={ROUTES.PROFILE_EDIT} element={<EditProfile />} />
        <Route path={ROUTES.PROFILES}>
          <Route index element={<PurposeList />} />
          <Route exact path={ROUTES.PROFILES_LIST} element={<ProfileList />} />
          <Route exact path={ROUTES.PROFILE_CREATE} element={<CreateProfile />} />
          <Route exact path={ROUTES.PROFILE_CONFIGURATOR} element={<ProfileConfigurator />} />
          <Route path="*" element={<Navigate to={ROUTES.R1_NOT_FOUND} />} />
        </Route>

        {/* Product Catalog - Category Mapping */}
        <Route path={ROUTES.CATEGORY_MAPPING} element={<CategoryMapping />} />

        {/* GoWholesale  */}
        <Route path={ROUTES.GOWHOLESALE_ROOT}>
          <Route index element={<GoWholesaleRootScreen />} />
          <Route
            exact
            path={ROUTES.GOWHOLESALE_NOTIFICATIONS}
            element={<GoWholesaleNotificationsScreen />}
          />
          <Route
            exact
            path={ROUTES.GOWHOLESALE_HOME_PAGE_EDITOR}
            element={<GoWholesaleHomePageEditorScreen />}
          />
        </Route>

        {/* Tax commitment reporter */}
        <Route exact path={ROUTES.PURCHASE_ORDERS} element={<PurchaseOrders />} />
        <Route exact path={ROUTES.FINANCIAL_EVENTS} element={<FinancialEvents />} />

        {/* Product template and prices */}
        <Route exact path={ROUTES.PRODUCT_TEMPLATE} element={<ProductTemplateScreen />} />
        <Route exact path={ROUTES.PRODUCT} element={<ProductCard />} />
        <Route
          exact
          path={ROUTES.PRODUCT_TEMPLATE_PRICES}
          element={<ProductTemplatePricesScreen />}
        />
        <Route
          exact
          path={ROUTES.PRODUCT_TEMPLATE_PRICES_ANOMALIES_BY_RULE}
          element={<ProductTemplatePricesAnomaliesByRule />}
        />
        <Route
          exact
          path={ROUTES.PRODUCT_TEMPLATE_PRICES_ANOMALIES_BY_PRODUCT}
          element={<ProductTemplatePricesAnomaliesByProduct />}
        />

        <Route exact path={ROUTES.CONSOLIDATION_STEPS} element={<ConsolidationStepsScreen />} />

        <Route exact path={ROUTES.FINANCIAL_EVENTS_GRID} element={<FinancialEventsGrid />} />

        <Route path={ROUTES.POWER_BI_REPORT} element={<PowerBiReport />} />

        <Route exact path={ROUTES.SHIPPINGS_SCHEDULE_PICKUP} element={<SchedulePickup />} />

        {/* Pricing Rules */}
        <Route exact path={ROUTES.PRICING_RULES} element={<PricingRules />} />
        <Route exact path={ROUTES.GLOBAL_PRICING_RULES} element={<GlobalPricingRules />} />
        <Route
          exact
          path={ROUTES.GLOBAL_PRICING_RULES_DETAILS}
          element={<GlobalPricingRuleView />}
        />

        {/* Policy Management */}
        <Route exact path={ROUTES.POLICIES} element={<PolicyGridScreen />} />
        <Route exact path={ROUTES.POLICIES_CATALOG} element={<CatalogGrid />} />
        <Route exact path={ROUTES.GROUPED_POLICIES} element={<GroupedPolicyGridScreen />} />

        {/* Supply Chain */}
        <Route exact path={ROUTES.MASTER_PROGRAMS} element={<MasterProgramGridScreen />} />
        <Route exact path={ROUTES.FACILITIES} element={<FacilitiesGrid />} />

        {/* User Management */}
        <Route exact path={ROUTES.USERS} element={<ViewUsersGrid />} />
        <Route exact path={ROUTES.USER_CREATE} element={<CreateUser />} />
        <Route exact path={ROUTES.USER_DUPLICATE} element={<CreateUser />} />
        <Route exact path={ROUTES.USER_EDIT} element={<EditUser />} />
        <Route exact path={ROUTES.ROLES} element={<RolesGrid />} />
        <Route exact path={ROUTES.ROLE_PAGE} element={<RolePage />} />

        {/* Product Catalog */}
        <Route exact path={ROUTES.CATEGORIES} element={<ViewCategoriesGrid />} />
        <Route exact path={ROUTES.CATEGORY_CREATE} element={<CategoryCreate />} />
        <Route exact path={ROUTES.CATEGORY_DETAILS} element={<CategoryDetails />} />

        <Route exact path={ROUTES.PRODUCTS} element={<ViewProductsGrid />} />
        <Route exact path={ROUTES.PRODUCT_DETAILS} element={<ProductDetails />} />
        <Route exact path={ROUTES.PRODUCT_VIEW} element={<ProductView />} />

        <Route exact path={ROUTES.ATTRIBUTES_DEFINITIONS} element={<AttributeDefinitionsList />} />
        <Route exact path={ROUTES.ATTRIBUTE_DEFINITION_CREATE} element={<CreateAttribute />} />
        <Route exact path={ROUTES.ATTRIBUTE_DEFINITION_DETAILS} element={<AttributeDetails />} />

        <Route exact path={ROUTES.VARIATIONS} element={<VariationsGrid />} />
        <Route exact path={ROUTES.VARIATIONS_CREATE} element={<VariationCreate />} />
        <Route exact path={ROUTES.VARIATIONS_EDIT} element={<VariationEdit />} />

        {/* RMA */}
        <Route exact path={ROUTES.CLAIMS_B2C} element={<ViewClaimsGrid claimType="B2C" />} />
        <Route exact path={ROUTES.CLAIMS_B2B} element={<ViewClaimsGrid claimType="B2B" />} />
        <Route exact path={ROUTES.CLAIM_CREATE_B2C} element={<CreateClaim claimType="B2C" />} />
        <Route exact path={ROUTES.CLAIM_CREATE_B2B} element={<CreateClaim claimType="B2B" />} />
        <Route exact path={ROUTES.CLAIM_VIEW_B2C} element={<ViewClaim claimType="B2C" />} />
        <Route exact path={ROUTES.CLAIM_VIEW_B2B} element={<ViewClaim claimType="B2B" />} />
        <Route exact path={ROUTES.RMA_SHIPPING_RULES} element={<RmaShippingRules />} />

        {/* Inventory */}
        <Route exact path={ROUTES.RA_MANAGEMENT} element={<ReturnsAuthorizationManagementGrid />} />
        <Route exact path={ROUTES.UNITS} element={<InventoryGrid />} />

        {/* Label Management */}
        <Route path={ROUTES.LABEL_MANAGEMENT}>
          <Route index element={<ViewLabelsGrid />} />
          <Route exact path={ROUTES.LABEL_EDIT} element={<EditLabel />} />
          <Route path="*" element={<Navigate to={ROUTES.R1_NOT_FOUND} />} />
        </Route>

        {/* Discount Rules */}
        <Route path={ROUTES.DISCOUNTS}>
          <Route index element={<ViewDiscountsGrid />} />
          <Route exact path={ROUTES.DISCOUNT_CREATE} element={<AddDiscountsRule />} />
          <Route exact path={ROUTES.DISCOUNT_EDIT} element={<EditDiscountsRule />} />
          <Route path="*" element={<Navigate to={ROUTES.R1_NOT_FOUND} />} />
        </Route>

        {/* Supply Level Rules */}
        <Route exact path={ROUTES.SUPPLY_LEVEL_RULES} element={<ViewSupplyLevelRulesGrid />} />
        <Route exact path={ROUTES.SUPPLY_LEVEL_RULE_CREATE} element={<AddSupplyLevelRule />} />
        <Route exact path={ROUTES.SUPPLY_LEVEL_RULE_EDIT} element={<EditSupplyLevelRule />} />

        {/* Go Wholesale */}
        <Route path={ROUTES.OFFER_MANAGEMENT} element={<OfferManagementOffersGridScreen />} />
        <Route path={ROUTES.DEPOSIT_MANAGEMENT} element={<DepositManagementDepositsGridScreen />} />

        <Route path={ROUTES.SITEMAP} element={<SiteMap />} />

        <Route exact path="/" element={<InitialPage />} />
        {/**
         * Route that will be rendered if redirect to <ROUTES.NO_PERMISSION> was happened.
         * Useful when server sent 403 status on main request and need redirect user to 403 page.
         */}
        <Route exact path={ROUTES.R1_NO_PERMISSION} element={<NoPermission />} />
        {/**
         * Route that will be rendered if redirect to <ROUTES.R1_NOT_FOUND> was happened.
         * Useful when server sent 404 status on main request and need redirect user to 404 page.
         */}
        <Route exact path={ROUTES.R1_NOT_FOUND} element={<NotFound />} />
        {/**
         * Route that will be rendered if user has inputted incorrect url.
         * E.g. https://<domaine_name>/blablabla
         */}
        <Route path="*" element={<Navigate to={ROUTES.R1_NOT_FOUND} />} />
      </Routes>
    </MasterLayout>
  )
}
