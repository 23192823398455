// @flow

import { useEffect } from 'react'
import { queryParametersUtils } from '../../../../../utils'
import { getPrograms, getLocationTypes, getLocations } from '../../../services'
import { saveFilterStateToQueryParameters, getFilterStateFromQueryParameters } from '../utils'
import type {
  FilterStateDivert,
  FilterStateLocationType,
  FilterStateProgram,
  ViewProps,
} from './types'
import { useControllerStateReducer } from './useControllerStateReducer'
import { MIN_PAGE_SIZE } from './defaultState'

type ControllerProps = {|
  children: ViewProps => React$Node,
|}

export const LocationsGridController = ({ children }: ControllerProps) => {
  const { state, reducers } = useControllerStateReducer()
  const { setQueries, getValueByKey } = queryParametersUtils

  const fetchLocations = async (filter: {|
    program: FilterStateProgram,
    divert: FilterStateDivert,
    locationType: FilterStateLocationType,
  |}) => {
    reducers.setGridFetching(true)

    const locations = await getLocations(filter)

    reducers.setGridLocations(locations)

    // try to get page and pageSize from query params
    const page = getValueByKey('page')
    const pageSize = getValueByKey('pageSize')
    if (page && pageSize) {
      reducers.setGridPage(Number(page))
      reducers.setGridPageSize(Number(pageSize))
      // if not found, then set them
    } else {
      setQueries([
        { key: 'page', value: '1' },
        { key: 'pageSize', value: String(MIN_PAGE_SIZE) },
      ])
    }

    reducers.setGridFetching(false)
  }

  // fetch filter data, then select program and fetch locations
  useEffect(() => {
    const fetchData = async () => {
      reducers.setFilterFetching(true)

      const [programs, locationTypes] = await Promise.all([getPrograms(), getLocationTypes()])

      reducers.setFilterPrograms(programs)
      reducers.setFilterLocationTypes(locationTypes)
      reducers.setFilterFetching(false)

      // program should always be selected
      // for starters, try to get programId from query params
      const { program, divert, locationType } = getFilterStateFromQueryParameters({ programs })
      if (divert) reducers.setFilterDivert(divert)
      if (locationType) reducers.setFilterLocationType(locationType)
      if (program) {
        reducers.setFilterProgram(program)
        await fetchLocations({ program, divert, locationType })
      }
      // if programId not found select the first one from the list or do nothing if programs array is empty
      else if (Array.isArray(programs) && programs.length) {
        reducers.setFilterProgram(programs[0])
        // not using program from state because dispatch is not async
        await fetchLocations({ program: programs[0], divert, locationType })
        saveFilterStateToQueryParameters({
          program: programs[0],
          locationType: state.filterState.locationType,
          divert: state.filterState.divert,
        })
      }
    }

    fetchData()
  }, [])

  return children({ ...state, reducers })
}
