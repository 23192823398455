import React from 'react'

import { Flex } from '@r1/ui-kit'
import { FormLoadingPlaceholder } from '../../../../../../../../components/FormLoadingPlaceholder'
import { InfoBlockWrapper } from '../../../../../../components/InfoBlockWrapper'
import { AppearanceMode } from '../../../../../../constants/AppearanceMode'
import { VendorDetails } from '../../../../../../types/VendorDetails'
import { VendorDetailsSectionView } from './VendorDetailsSectionView'

type Props = {
  mode: AppearanceMode
  vendorDetails: VendorDetails
}

export const VendorDetailsSection: React.FC<Props> = ({ mode, vendorDetails }) => {
  if (mode === AppearanceMode.Loading) {
    return (
      <InfoBlockWrapper>
        <Flex column mb="L" spaceBetween="L" minWidth={1}>
          <FormLoadingPlaceholder numberOfFields={3} />
          <FormLoadingPlaceholder numberOfFields={3} />
          <FormLoadingPlaceholder numberOfFields={3} />
        </Flex>
      </InfoBlockWrapper>
    )
  }

  return (
    <InfoBlockWrapper>
      <VendorDetailsSectionView vendorDetails={vendorDetails} />
    </InfoBlockWrapper>
  )
}
