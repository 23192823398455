import React, { useCallback, useMemo, useState } from 'react'
import { Offer, SortedColumn } from '@r1-webui/gowholesale-offermanagement-v1'
import { Table, Pagination } from '@r1/grid'
import { Text, Flex, Button } from '@r1/ui-kit'
import { useAccessControl } from '@r1/core-blocks'
import { MenuItem } from '@r1/ui-kit/contracts/ts/Dropdown'
import { Sorting } from '@r1/grid/contracts/ts/DisplaySettings'
import { CounterSection } from '../../../../components/GridComponents/CounterSection'
import { DialogModal } from '../../../../components/DialogModal/DialogModal'
import { openNewWindow } from '../../../../navigation'
import { offerManagementApi } from '../../api'
import { OfferDrawer } from './components/OfferDrawer'
import { CounterOfferData, OfferAction, OffersGridDialogCase, OffersGridProps } from './types'
import { CounterOfferModal, OfferHistoryModal, OffersGridFilter } from './components'
import { PAGE_SIZES, sortingTypeUiFilterMap, RECENT_OFFERS_TIMESPAN_DAYS } from './const'
import {
  getConfirmApprovalMessage,
  getConfirmApprovalOfCounterWithHigherSimilarMessage,
  getConfirmApprovalOfCounterWithHistoryHigherSimilarMessage,
  getConfirmRejectMessage,
  getCustomerUrl,
  getOffersApprovalActionAbility,
  getOffersRejectActionAbility,
} from './utils'
import { getDisplaySettings } from './displaySettings'

export const OffersGrid = ({
  loading,
  isExportInProgress,
  data,
  total,
  pagination,
  filter,
  onChangeFilter,
  onApproveOffers,
  onExportOffers,
  onRejectOffers,
  onCounterOffer,
  onRequestOfferHistory,
  onChangePagination,
  onRequestCustomer,
}: OffersGridProps) => {
  const [allowPermissions] = useAccessControl()

  const [isAllOffersSelected, setIsAllOffersSelected] = useState<boolean>(false)
  const [selectedOfferIds, setSelectedOfferIds] = useState<string[]>([])

  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [dialogMessage, setDialogMessage] = useState<JSX.Element | null>(null)
  const [dialogCase, setDialogCase] = useState<OffersGridDialogCase | null>()

  const [offersForBulkActions, setOffersForBulkActions] = useState<Offer[]>([])

  const [counterOfferData, setCounterOfferData] = useState<CounterOfferData | null>(null)
  const [isCounterModalOpen, setIsCounterModalOpen] = useState<boolean>(false)

  const [offerForHistory, setOfferForHistory] = useState<Offer | null>(null)
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState<boolean>(false)

  const [offerForView, setOfferForView] = useState<Offer | null>(null)
  const [isOfferDrawerOpen, setIsOfferDrawerOpen] = useState<boolean>(false)

  const onSelectAllOffers = useCallback(() => {
    if (isAllOffersSelected) {
      setSelectedOfferIds([])
    } else {
      setSelectedOfferIds(data.map(dataItem => dataItem.id))
    }

    setIsAllOffersSelected(!isAllOffersSelected)
  }, [data, isAllOffersSelected])

  const onOfferSelect = useCallback(
    (id: string, wasChecked: boolean) => () => {
      if (wasChecked) {
        setIsAllOffersSelected(false)
        const newSelectedIds = [...selectedOfferIds]
        newSelectedIds.splice(newSelectedIds.indexOf(id), 1)
        setSelectedOfferIds(newSelectedIds)
      } else {
        const newSelectedIds = [...selectedOfferIds, id]
        if (newSelectedIds.length === data.length) {
          setIsAllOffersSelected(true)
        }
        setSelectedOfferIds(newSelectedIds)
      }
    },
    [data.length, selectedOfferIds],
  )

  const resetOfferSelection = () => {
    setIsAllOffersSelected(false)
    setSelectedOfferIds([])
  }

  const onDialogCancel = () => {
    setIsDialogOpen(false)
    setDialogMessage(null)
    setDialogCase(null)
  }

  const resetOfferDrawer = () => {
    setIsOfferDrawerOpen(false)
    setOfferForView(null)
  }

  const resetCounterModal = () => {
    setIsCounterModalOpen(false)
    setCounterOfferData(null)
  }

  const onOfferDrawerClose = () => setIsOfferDrawerOpen(false)

  const onDialogConfirm = useMemo<(() => void) | null>(() => {
    switch (dialogCase) {
      case OffersGridDialogCase.ConfirmOffersApproval:
      case OffersGridDialogCase.ConfirmApprovalWithHigherSimilar: {
        return () => {
          onApproveOffers(offersForBulkActions)
          onDialogCancel()
          resetOfferSelection()
          resetOfferDrawer()
        }
      }
      case OffersGridDialogCase.ConfirmOffersReject: {
        return () => {
          onRejectOffers(offersForBulkActions)
          onDialogCancel()
          resetOfferSelection()
          resetOfferDrawer()
        }
      }
      case OffersGridDialogCase.ConfirmCounterOfferWithHigherSimilar: {
        return () => {
          if (counterOfferData) onCounterOffer(counterOfferData)
          onDialogCancel()
          resetOfferDrawer()
          resetCounterModal()
        }
      }
      default: {
        return null
      }
    }
  }, [
    dialogCase,
    onApproveOffers,
    offersForBulkActions,
    onRejectOffers,
    onCounterOffer,
    counterOfferData,
  ])

  const onApprovalRequest = useCallback(
    async (offerIds: string[]) => {
      const selectedOffers = data.filter(dataItem => offerIds.includes(dataItem.id))

      const ability = getOffersApprovalActionAbility(selectedOffers)

      if (!ability.canPerform) {
        setDialogCase(OffersGridDialogCase.ShowApprovalError)
        setDialogMessage(<Text>{ability.reasonMessage}</Text>)
        setIsDialogOpen(true)
        return
      }

      setOffersForBulkActions(selectedOffers)

      // In case of one offer we look for similar higher offers and notify user in case they're found
      if (selectedOffers.length === 1) {
        // First, look for active offers
        const offer = selectedOffers[0]
        const similarHigherOffers = await offerManagementApi.getSimilarHigherOffers({
          offer,
          price: offer.price.amount,
        })
        if (similarHigherOffers.length > 0) {
          setDialogCase(OffersGridDialogCase.ConfirmApprovalWithHigherSimilar)
          setDialogMessage(
            getConfirmApprovalOfCounterWithHigherSimilarMessage({
              offerIds: similarHigherOffers,
            }),
          )
          setIsDialogOpen(true)
          return
        }

        // Then, look for offers from history
        const similarHigherHistoryOffers = await offerManagementApi.getSimilarHigherHistoryOffers({
          offer,
          price: offer.price.amount,
          recentTimespanInDays: RECENT_OFFERS_TIMESPAN_DAYS,
        })
        if (similarHigherHistoryOffers.length > 0) {
          setDialogCase(OffersGridDialogCase.ConfirmApprovalWithHigherSimilar)
          setDialogMessage(
            getConfirmApprovalOfCounterWithHistoryHigherSimilarMessage({
              offerIds: similarHigherHistoryOffers,
            }),
          )
          setIsDialogOpen(true)
          return
        }
      }

      setDialogMessage(getConfirmApprovalMessage(selectedOffers))
      setDialogCase(OffersGridDialogCase.ConfirmOffersApproval)
      setIsDialogOpen(true)
    },
    [data],
  )

  const onRejectRequest = useCallback(
    (offerIds: string[]) => {
      const selectedOffers = data.filter(dataItem => offerIds.includes(dataItem.id))

      const ability = getOffersRejectActionAbility(selectedOffers)

      if (!ability.canPerform) {
        setDialogCase(OffersGridDialogCase.ShowRejectError)
        setDialogMessage(<Text>{ability.reasonMessage}</Text>)
        setIsDialogOpen(true)
        return
      }

      setOffersForBulkActions(selectedOffers)

      const message = getConfirmRejectMessage(selectedOffers)
      setDialogMessage(message)
      setDialogCase(OffersGridDialogCase.ConfirmOffersReject)
      setIsDialogOpen(true)
    },
    [data],
  )

  const onViewCustomerRequest = useCallback(
    async (offer: Offer) => {
      const customer = await onRequestCustomer(offer)

      if (customer) {
        const url = getCustomerUrl(customer)
        openNewWindow(url)
      }
    },
    [onRequestCustomer],
  )

  const onCounterOfferRequest = useCallback((offer: Offer) => {
    setCounterOfferData({ offer, counterPriceAmount: 0, counterQty: 0 })
    setIsCounterModalOpen(true)
  }, [])

  const onCounterConfirm = useCallback(
    async ({
      counterPriceAmount,
      counterQty,
    }: {
      counterPriceAmount: number
      counterQty: number
    }) => {
      setCounterOfferData(currentState =>
        currentState ? { offer: currentState.offer, counterPriceAmount, counterQty } : null,
      )

      // We look for similar higher offers and notify user in case they're found
      if (counterOfferData) {
        // First, look for active offers
        const similarHigherOffers = await offerManagementApi.getSimilarHigherOffers({
          offer: counterOfferData.offer,
          price: String(counterPriceAmount),
        })
        if (similarHigherOffers.length > 0) {
          setDialogCase(OffersGridDialogCase.ConfirmCounterOfferWithHigherSimilar)
          setDialogMessage(
            getConfirmApprovalOfCounterWithHigherSimilarMessage({
              offerIds: similarHigherOffers,
            }),
          )
          setIsDialogOpen(true)
          return
        }

        // Then, look for offers from history
        const similarHigherHistoryOffers = await offerManagementApi.getSimilarHigherHistoryOffers({
          offer: counterOfferData.offer,
          price: String(counterPriceAmount),
          recentTimespanInDays: RECENT_OFFERS_TIMESPAN_DAYS,
        })
        if (similarHigherHistoryOffers.length > 0) {
          setDialogCase(OffersGridDialogCase.ConfirmCounterOfferWithHigherSimilar)
          setDialogMessage(
            getConfirmApprovalOfCounterWithHistoryHigherSimilarMessage({
              offerIds: similarHigherHistoryOffers,
            }),
          )
          setIsDialogOpen(true)
          return
        }

        onCounterOffer({ offer: counterOfferData.offer, counterPriceAmount, counterQty })
        onDialogCancel()
        resetOfferDrawer()
        resetCounterModal()
      }
    },
    [counterOfferData, onCounterOffer],
  )

  const handleRequestOfferHistory = useCallback(async () => {
    if (!offerForHistory) return []

    const history = await onRequestOfferHistory(offerForHistory)
    return history
  }, [offerForHistory, onRequestOfferHistory])

  const onHistoryClose = useCallback(() => {
    setOfferForHistory(null)
    setIsHistoryModalOpen(false)
  }, [])

  const onOfferActionClick = useCallback(
    (offer: Offer, action: OfferAction) => {
      switch (action) {
        case OfferAction.Approve: {
          onApprovalRequest([offer.id])
          break
        }
        case OfferAction.Counter: {
          onCounterOfferRequest(offer)
          break
        }
        case OfferAction.History: {
          setOfferForHistory(offer)
          setIsHistoryModalOpen(true)
          break
        }
        case OfferAction.Reject: {
          onRejectRequest([offer.id])
          break
        }
        case OfferAction.ViewOffer: {
          setIsOfferDrawerOpen(true)
          setOfferForView(offer)
          break
        }
        case OfferAction.ViewCustomer: {
          onViewCustomerRequest(offer)
          break
        }
        default:
          break
      }
    },
    [onApprovalRequest, onRejectRequest, onViewCustomerRequest],
  )

  const onBulkActionClick = (action: string) => {
    switch (action) {
      case OfferAction.Approve: {
        onApprovalRequest(selectedOfferIds)
        break
      }
      case OfferAction.Reject: {
        onRejectRequest(selectedOfferIds)
        break
      }
      default:
        break
    }
  }

  const onSortingChange = (sorting: Sorting) => {
    if (sorting.type === 'none') {
      onChangeFilter({ ...filter, sorting: undefined })
      return
    }

    onChangeFilter({
      ...filter,
      sorting: {
        column: sorting.field as SortedColumn,
        sorting: sortingTypeUiFilterMap[sorting.type],
      },
    })
  }

  const displaySettings = useMemo(
    () =>
      getDisplaySettings({
        allowedToEditOffer: allowPermissions.allowGwsOfferManagementEdit,
        isAllOffersSelected,
        filter,
        selectedOfferIds,
        onOfferActionClick,
        onOfferSelect,
        onSelectAllOffers,
      }),
    [
      allowPermissions.allowGwsOfferManagementEdit,
      isAllOffersSelected,
      onOfferActionClick,
      onOfferSelect,
      onSelectAllOffers,
      selectedOfferIds,
      filter,
    ],
  )

  const bulkActionItems = useMemo<MenuItem<string>[] | undefined>(() => {
    const actionItems: MenuItem<string>[] = [
      {
        id: OfferAction.Approve,
        title: <Text>Approve</Text>,
      },
      {
        id: OfferAction.Reject,
        title: <Text>Reject</Text>,
      },
    ]

    return actionItems
  }, [])

  if (!data) {
    return null
  }

  return (
    <>
      <Flex column spaceBetween="L">
        <OffersGridFilter filter={filter} onChange={onChangeFilter} />

        <Flex column spaceBetween="S">
          <Flex align="center" justify="space-between">
            <CounterSection
              withFilterFlush={false}
              count={selectedOfferIds.length || total}
              isSelected={!!selectedOfferIds.length}
              actionItems={bulkActionItems}
              onSelectItem={onBulkActionClick}
            />

            <Button loading={isExportInProgress} onClick={onExportOffers}>
              Export
            </Button>
          </Flex>

          <Pagination
            rowCount={total}
            page={pagination.page}
            pageSize={pagination.pageSize}
            availablePageSizes={PAGE_SIZES}
            onChange={onChangePagination}
          >
            <Table
              resizable
              suppressVirtualization
              loading={loading}
              data={data}
              displaySettings={displaySettings}
              onChangeSorting={onSortingChange}
            />
          </Pagination>
        </Flex>
      </Flex>

      <DialogModal
        show={isDialogOpen}
        message={dialogMessage}
        onClose={onDialogCancel}
        onConfirm={onDialogConfirm}
      />

      <OfferDrawer
        isOpen={isOfferDrawerOpen}
        offer={offerForView}
        allowEdit={allowPermissions.allowGwsOfferManagementEdit}
        onActionClick={onOfferActionClick}
        onClose={onOfferDrawerClose}
      />

      {offerForHistory && (
        <OfferHistoryModal
          show={isHistoryModalOpen}
          getOfferHistory={handleRequestOfferHistory}
          onClose={onHistoryClose}
        />
      )}

      {counterOfferData && (
        <CounterOfferModal
          currentOffer={counterOfferData.offer}
          show={isCounterModalOpen}
          onConfirm={onCounterConfirm}
          onClose={resetCounterModal}
        />
      )}
    </>
  )
}
