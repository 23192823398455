// @flow

import React from 'react'
import { Main, Content, ContentHeader } from '@r1/wireframe-primary'
import { Filter } from './Filter'
import { Grid } from './Grid'
import { LocationManagementDrawer, DeleteConfirmationModal } from './assistive'
import type { ViewProps } from './types'

export const LocationsGridView = ({
  gridState,
  filterState,
  locationManagementState,
  deleteConfirmationState,
  reducers,
}: ViewProps) => {
  return (
    <Main fullScreen>
      <ContentHeader>
        <ContentHeader.Title />
      </ContentHeader>

      <Content spaceBetween="M">
        <Filter state={filterState} reducers={reducers} />

        <Grid state={gridState} filterState={filterState} reducers={reducers} />
      </Content>

      <LocationManagementDrawer
        state={locationManagementState}
        filterState={filterState}
        reducers={reducers}
      />

      <DeleteConfirmationModal
        state={deleteConfirmationState}
        filterState={filterState}
        reducers={reducers}
      />
    </Main>
  )
}
