import {
  createOfferManagementApi,
  Offer,
  OfferFilter,
  OfferManagement,
} from '@r1-webui/gowholesale-offermanagement-v1'
import { createCustomersApi } from '@r1-webui/customerprovider-v1'
import { handleServerError } from '@r1/core-blocks'
import { format } from 'date-fns'
import { httpClient } from '../../httpClient/httpClient'
import { downloadBlob } from '../../utils/download'
import { GridPagination } from './types'

const OFFER_MANAGEMENT_URL_PREFIX = '/uisvc/gowholesale-offer-management'

const offerManagementApiInner = createOfferManagementApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: OFFER_MANAGEMENT_URL_PREFIX,
})

export const offerManagementApi = {
  ...offerManagementApiInner,

  exportAndDownloadOffers: async ({ filter }: { filter: OfferFilter }) => {
    const fileBlob = await httpClient.post<Blob>(
      `${OFFER_MANAGEMENT_URL_PREFIX}/gowholesale/offer-management/offers/export`,
      { filter },
      { responseType: 'blob' },
    )

    downloadBlob({
      name: `${format(Date.now(), 'MM-dd-yyyy')} Offer Management.xlsx`,
      blob: fileBlob,
    })
  },

  getSimilarHigherOffers: async ({
    offer,
    price,
  }: {
    offer: Offer
    price: string
  }): Promise<string[]> => {
    const response = await offerManagementApiInner.getSimilarHigherOffers(
      {
        offerId: offer.id,
      },
      {
        price,
        sku: offer.masterSku,
      },
    )
    if (response.status === 200) return response.body
    handleServerError(response)
    return []
  },

  getSimilarHigherHistoryOffers: async ({
    offer,
    price,
    recentTimespanInDays,
  }: {
    offer: Offer
    price: string
    recentTimespanInDays: number
  }): Promise<string[]> => {
    const response = await offerManagementApiInner.getHistorySimilarHigherOffers(
      {
        offerId: offer.id,
      },
      {
        sku: offer.masterSku,
        price,
        recentTimespanInDays,
      },
    )
    if (response.status === 200) return response.body
    handleServerError(response)
    return []
  },
}

export const customerProviderApi = createCustomersApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: `/uisvc/customer-provider`,
})

const getEnhancedOfferFilter = (filter: OfferFilter): OfferFilter => {
  const filterStatuses = [...(filter?.statuses ?? [])]

  if (filterStatuses.includes('Expired')) {
    filterStatuses.push('AcceptExpired', 'Outdated')
  }
  if (filterStatuses.includes('Cancelled')) {
    filterStatuses.push('AcceptFailed', 'CancelAccepted', 'RejectAccepted')
  }

  return { ...filter, statuses: filterStatuses }
}

export const fetchOffers = async ({
  filter,
  pagination,
}: {
  filter: OfferFilter
  pagination: GridPagination
}): Promise<OfferManagement.SearchOffersResponse> => {
  const enhancedFilter = getEnhancedOfferFilter(filter)

  return offerManagementApi.searchOffers({
    filter: enhancedFilter,
    page: pagination.page - 1,
    pageSize: pagination.pageSize,
  })
}
