// @flow

import type { MappedLocationType } from './types'

export const getLocationTypes = async (): Promise<MappedLocationType[]> =>
  new Promise(resolve =>
    // eslint-disable-next-line no-promise-executor-return
    resolve([
      // this mapping is necessary for single select
      { id: 'Bin', name: 'Bin' },
      { id: 'Gaylord', name: 'Gaylord' }, // lol
      { id: 'CatchAll', name: 'CatchAll' },
    ]),
  )
