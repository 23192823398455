import { NotificationSystem } from '@r1/ui-kit'
import { handleServerError } from '@r1/core-blocks'
import { KeepInStore as KeepInStoreNamespace } from '@r1-webui/rtv-contractmanagement-v1'

import { keepInStoreApi } from '../api/keepInStoreApi'

function handleResponse(
  response:
    | KeepInStoreNamespace.AddItemsToKeepInStoreResponse
    | KeepInStoreNamespace.RemoveItemsFromKeepInStoreResponse,
) {
  switch (response.status) {
    case 200:
      NotificationSystem.addNotification({
        level: 'success',
        title: `${response.body} records has been updated`,
      })
      break
    case 403:
      NotificationSystem.addNotification({
        level: 'error',
        title: 'You have no permission to edit "Keep In Store" items',
      })
      break
    default:
      handleServerError(response)
  }
}

export async function addItemsToKeepInStore(items: number[]): Promise<void> {
  const response = await keepInStoreApi.addItemsToKeepInStore(items)
  handleResponse(response)
}

export async function removeItemsFromKeepInStore(items: number[]): Promise<void> {
  const response = await keepInStoreApi.removeItemsFromKeepInStore(items)
  handleResponse(response)
}
