import React, { useMemo } from 'react'

import { PivotTable } from '../../../../../components/PivotTable/PivotTable'
import { InfoBlockWrapper } from '../../../../../components/InfoBlockWrapper'
import { FormLoadingPlaceholder } from '../../../../../../../components/FormLoadingPlaceholder'
import { parseAndFormatDate } from '../../../../../utils/helpers'
import { AppearanceMode } from '../../../../../constants/AppearanceMode'

type Props = {
  mode: AppearanceMode
  returnMethod?: string
  collectionMethod?: string
  contractStartDate?: string
  contractEndDate?: string
}

function getDateValue(date?: string | null) {
  if (!date) return 'Not specified'
  return parseAndFormatDate(date)
}

export const PolicySection: React.FC<Props> = ({
  mode,
  returnMethod,
  collectionMethod,
  contractStartDate,
  contractEndDate,
}) => {
  const tableItems = useMemo(() => {
    return [
      {
        key: 'Return Method',
        value: returnMethod,
      },
      {
        key: 'Collection Method',
        value: collectionMethod,
      },
      {
        key: 'Start Date',
        value: getDateValue(contractStartDate),
      },
      {
        key: 'End Date',
        value: getDateValue(contractEndDate),
      },
    ]
  }, [collectionMethod, contractEndDate, contractStartDate, returnMethod])

  if (mode === AppearanceMode.Loading) {
    return (
      <InfoBlockWrapper>
        <FormLoadingPlaceholder numberOfFields={4} />
      </InfoBlockWrapper>
    )
  }

  if (mode === AppearanceMode.View) {
    return (
      <InfoBlockWrapper>
        <PivotTable items={tableItems} />
      </InfoBlockWrapper>
    )
  }

  return null
}
