import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from '@emotion/styled'
import { Modal, Flex, NumericInput, Text, Box } from '@r1/ui-kit'
import { Offer } from '@r1-webui/gowholesale-offermanagement-v1'
import { ControlledActionButtons } from '@r1/ui-kit/contracts/ts/Modal'
import { DialogModal } from '../../../../../../components/DialogModal/DialogModal'
import { getCounterConfirmMessage, getPriceAmountError, getQtyError } from './utils'
import { PriceAmountError, QtyError } from './const'

const ErrorBox = styled(Box)`
  min-height: 32px;
`

type CounterOfferModalProps = {
  currentOffer: Offer
  show: boolean
  onConfirm: ({
    counterPriceAmount,
    counterQty,
  }: {
    counterPriceAmount: number
    counterQty: number
  }) => void
  onClose: () => void
}

export const CounterOfferModal = ({
  currentOffer,
  show,
  onConfirm,
  onClose,
}: CounterOfferModalProps) => {
  const [priceAmount, setPriceAmount] = useState<number>(Number(currentOffer.askPrice?.amount || 0))
  const [priceAmountError, setPriceAmountError] = useState<PriceAmountError>(PriceAmountError.NONE)
  const [qty, setQty] = useState<number>(currentOffer.quantity.value)
  const [qtyError, setQtyError] = useState<QtyError>(QtyError.NONE)
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false)

  const toggleConfirmModal = useCallback(() => setIsConfirmModalOpen(prev => !prev), [])

  const onPriceAmountChanged = (value: string | null) => {
    const numberValue = Number(value)
    const newPriceAmount = typeof value === 'string' && numberValue >= 0 ? numberValue : 0
    setPriceAmount(newPriceAmount)
  }

  const onQtyChanged = (value: string | null) => {
    const numberValue = Number(value)
    const newQty = typeof value === 'string' && numberValue >= 0 ? numberValue : 0
    setQty(newQty)
  }

  // Validate price amount change
  useEffect(() => {
    const error = getPriceAmountError(priceAmount)
    setPriceAmountError(error)
  }, [priceAmount])

  // Validate qty change
  useEffect(() => {
    const error = getQtyError(qty)
    setQtyError(error)
  }, [qty])

  const modalActionButtons: ControlledActionButtons = useMemo(
    () => [
      {
        title: 'Cancel',
        color: 'secondary',
        onClick: onClose,
        align: 'right',
      },
      {
        title: 'Confirm',
        color: 'primary',
        onClick: toggleConfirmModal,
        disabled: !(priceAmountError === PriceAmountError.NONE && qtyError === QtyError.NONE),
        align: 'right',
      },
    ],
    [priceAmountError, qtyError, onClose, toggleConfirmModal],
  )

  const onConfirmCounterOffer = () => {
    setIsConfirmModalOpen(false)
    onConfirm({ counterPriceAmount: priceAmount, counterQty: qty })
  }

  const confirmCounterMessage = useMemo<JSX.Element>(() => {
    return (
      <>
        <Text type="paragraph">{getCounterConfirmMessage(qty, priceAmount, currentOffer)}</Text>
        <Text type="paragraph">Are you sure?</Text>
      </>
    )
  }, [currentOffer, qty, priceAmount])

  return (
    <Modal
      isControlled
      size="S"
      show={show}
      title="Make a Counter Offer"
      actionButtons={modalActionButtons}
      onEscapeKeyDown={onClose}
    >
      <Flex column spaceBetween="S">
        <Flex column spaceBetween="XXS">
          <Text weight="bold" overflow="rtl-ellipsis">
            Product
          </Text>
          <Text>{currentOffer.product.name}</Text>
        </Flex>

        <Flex>
          <Box minWidth={160}>
            <Text weight="bold" overflow="rtl-ellipsis">
              Quantity
            </Text>
          </Box>
          <Text>{currentOffer.quantity.value}</Text>
        </Flex>

        <Flex>
          <Box minWidth={160}>
            <Text weight="bold" overflow="rtl-ellipsis">
              Current offer
            </Text>
          </Box>
          <Text>
            &#36;{currentOffer.price.amount}
            &nbsp;per&nbsp;item
          </Text>
        </Flex>

        <Flex>
          <Box minWidth={160}>
            <Text weight="bold" overflow="rtl-ellipsis">
              Ask
            </Text>
          </Box>
          <Text>&#36;{currentOffer.product.price.amount}</Text>
        </Flex>

        {currentOffer.askPrice ? (
          <Flex>
            <Box minWidth={160}>
              <Text weight="bold" overflow="rtl-ellipsis">
                Current Counter Offer
              </Text>
            </Box>
            <Text>&#36;{currentOffer.askPrice.amount}</Text>
          </Flex>
        ) : null}

        <Flex spaceBetween="M">
          <Flex column basis={1} mt="XL" align="flex-start" spaceBetween="XS">
            <Text weight="bold">Counter Offer</Text>

            <Flex align="center" spaceBetween="XXS">
              <NumericInput
                maxFractionDigits={0}
                value={priceAmount}
                onChange={onPriceAmountChanged}
              />
              <Box shrink={false}>
                <Text>&#36;&nbsp;per&nbsp;item</Text>
              </Box>
            </Flex>

            <ErrorBox>
              <Text type="caption" color="red">
                {priceAmountError}
              </Text>
            </ErrorBox>
          </Flex>

          <Flex column basis={1} mt="XL" align="flex-start" spaceBetween="XS">
            <Text weight="bold">Quantity</Text>

            <NumericInput maxFractionDigits={0} value={qty} onChange={onQtyChanged} />

            <ErrorBox>
              <Text type="caption" color="red">
                {qtyError}
              </Text>
            </ErrorBox>
          </Flex>
        </Flex>
      </Flex>

      <DialogModal
        message={confirmCounterMessage}
        show={isConfirmModalOpen}
        onConfirm={onConfirmCounterOffer}
        onClose={toggleConfirmModal}
      />
    </Modal>
  )
}
