import React, { useState, useEffect, useMemo } from 'react'

import { handleServerError } from '@r1/core-blocks'
import {
  Box,
  DualList,
  Flex,
  FormField,
  H4,
  NotificationSystem,
  Placeholder,
  Select,
  HR,
} from '@r1/ui-kit'
import type { Program, ProgramId, ProgramSearchOption } from '@r1-webui/usermanagement-v1'
import type { TestableProps } from '@r1/types/typescript'
import type { UserProgramSettings } from '../../types/common'
import { programsApi } from '../../api/programs'
import { TextReadOnly, DualListContainer } from '../common'

type Props = {
  userSettings: UserProgramSettings
  isEditing: boolean
  onChange?: (newSettings: UserProgramSettings) => void
}

type ProgramsListProps = {
  allPrograms: ReadonlyMap<string, string>
  selectedProgramIds: ProgramId[]
  isEditing: boolean
  onChange: (ids: string[]) => void
}

type SelectLineProps = {
  label: string
  options: { id: string; name: string }[]
  value?: string | null
  isEditing: boolean
  onChange: (id: string) => void
} & TestableProps

const SelectLine = (props: SelectLineProps) => {
  if (!props.isEditing) {
    const selectedValue = props.options.find(option => option.id === props.value)?.name
    return (
      <Flex column minWidth={1}>
        <HR />
        <Flex justify="space-between" align="flex-start" mt="S">
          <FormField.Label>{props.label}</FormField.Label>
          <TextReadOnly data-test-id={props['data-test-id']}>{selectedValue}</TextReadOnly>
        </Flex>
      </Flex>
    )
  }

  return (
    <FormField>
      <FormField.Label>{props.label}</FormField.Label>
      <Select
        data-test-id={props['data-test-id']}
        options={props.options}
        value={props.value || null}
        clearable={false}
        onChange={props.onChange}
      />
    </FormField>
  )
}

const ProgramsList = (props: ProgramsListProps) => {
  if (props.isEditing) {
    return (
      <DualListContainer>
        <DualList
          options={Array.from(props.allPrograms, ([id, name]) => ({ id, name }))}
          value={props.selectedProgramIds}
          data-test-id="um-programs-editable-list"
          // @ts-expect-error
          onChange={props.onChange}
        />
      </DualListContainer>
    )
  }

  const names = props.selectedProgramIds.map(id => {
    const elem = props.allPrograms.get(id)
    if (!elem) {
      throw new Error(`User's selected program with id "${id}" doesn't exist`)
    }
    return elem
  })

  return (
    <Flex column minWidth={1}>
      <HR />
      <Flex justify="space-between" align="flex-start" mt="S">
        <FormField.Label>Selected Programs</FormField.Label>
        <TextReadOnly>
          <Flex column>
            {names.map(name => (
              <TextReadOnly key={name} data-test-id={`selected-program-${name}`}>
                {name}
              </TextReadOnly>
            ))}
          </Flex>
        </TextReadOnly>
      </Flex>
    </Flex>
  )
}

const { addNotification } = NotificationSystem

export const ProgramsSettings = ({ userSettings, isEditing, onChange }: Props) => {
  const [isLoadingPrograms, setIsLoadingPrograms] = useState(true)
  const [allPrograms, setAllPrograms] = useState<Program[]>([])

  const [selectedProgramIds, setSelectedPrograms] = useState<ProgramId[]>(
    userSettings.selectedProgramIds,
  )
  const [defaultLoginProgramId, setDefaultLoginProgram] = useState(
    userSettings.defaultLoginProgramId,
  )
  const [defaultSearchOptionId, setDefaultSearchOption] = useState(
    userSettings.defaultSearchOptionId,
  )

  const [isLoadingSearchOptions, setIsLoadingSearchOptions] = useState(false)
  const [searchOptions, setSearchOptions] = useState<ProgramSearchOption[]>([])

  useEffect(() => {
    const loadAllPrograms = async () => {
      const allProgramsResponse = await programsApi.getAllPrograms()
      if (allProgramsResponse.status === 200) {
        setAllPrograms(allProgramsResponse.body)
        setIsLoadingPrograms(false)
      } else {
        handleServerError(allProgramsResponse)
        addNotification({ level: 'error', title: "Can't load all programs" })
      }
    }

    loadAllPrograms()
  }, [])

  useEffect(() => {
    const loadNewOptions = async (selectedPrograms: ProgramId[]) => {
      setIsLoadingSearchOptions(true)
      const searchOptionsResponse = await programsApi.buildSearchOptions(selectedPrograms)
      if (searchOptionsResponse.status === 200) {
        setSearchOptions(searchOptionsResponse.body)
        setIsLoadingSearchOptions(false)
      } else {
        handleServerError(searchOptionsResponse)
        addNotification({ level: 'error', title: "Can't load search options" })
      }
    }

    loadNewOptions(selectedProgramIds)
  }, [selectedProgramIds])

  // remove default search program if search options don't contain it
  useEffect(() => {
    if (isLoadingPrograms || isLoadingSearchOptions || !defaultSearchOptionId) {
      return
    }
    if (!searchOptions.some(option => option.id === defaultSearchOptionId)) {
      setDefaultSearchOption(undefined)
    }
  }, [isLoadingPrograms, isLoadingSearchOptions, searchOptions, defaultSearchOptionId])

  // remove default login program if selected programs don't contain it
  useEffect(() => {
    if (isLoadingPrograms || !defaultLoginProgramId) {
      return
    }
    if (!selectedProgramIds.includes(defaultLoginProgramId)) {
      setDefaultLoginProgram(undefined)
    }
  }, [isLoadingPrograms, selectedProgramIds, defaultLoginProgramId])

  useEffect(() => {
    if (onChange) {
      onChange({
        selectedProgramIds,
        defaultLoginProgramId,
        defaultSearchOptionId,
      })
    }
  }, [selectedProgramIds, defaultLoginProgramId, defaultSearchOptionId])

  const programsMap = useMemo<ReadonlyMap<string, string>>(() => {
    return new Map(allPrograms.map(program => [program.id, program.name]))
  }, [allPrograms])

  const selectedPrograms = useMemo(() => {
    if (isLoadingPrograms) {
      return []
    }
    return selectedProgramIds.map(id => {
      const name = programsMap.get(id)
      if (!name) {
        throw new Error(`User's selected program with id "${id}" doesn't exist`)
      }
      return {
        id,
        name,
      }
    })
  }, [isLoadingPrograms, programsMap, selectedProgramIds])

  return isLoadingPrograms ? (
    <Placeholder type="form" height={5} />
  ) : (
    <Flex column spaceBetween="XL">
      <H4>Programs Settings</H4>
      <Flex column minWidth={650} maxWidth={650} spaceBetween="M">
        <Box>
          <SelectLine
            label="Default Login Program"
            data-test-id="default-login-program"
            isEditing={isEditing}
            options={selectedPrograms}
            value={defaultLoginProgramId}
            onChange={setDefaultLoginProgram}
          />
        </Box>
        <Box>
          <SelectLine
            label="Default Search Program"
            data-test-id="default-search-program"
            isEditing={isEditing}
            options={searchOptions}
            value={defaultSearchOptionId}
            onChange={setDefaultSearchOption}
          />
        </Box>
        <Box>
          <ProgramsList
            isEditing={isEditing}
            allPrograms={programsMap}
            selectedProgramIds={selectedProgramIds}
            onChange={setSelectedPrograms}
          />
        </Box>
      </Flex>
    </Flex>
  )
}
