import React from 'react'
import { Offer, OfferFilter, SortedColumn } from '@r1-webui/gowholesale-offermanagement-v1'
import { FreezeType, Sorting } from '@r1/grid/contracts/ts/DisplaySettings'
import { Customer } from '@r1-webui/customerprovider-v1'
import { Box, Link, Text } from '@r1/ui-kit'
import { ROUTES } from '../../../../navigation'
import { OFFERS_FILTER_OFFER_ID_QUERY_KEY } from '../../const'
import { OffersGridHeaderType, ActionAbility } from './types'
import {
  OFFER_STATUSES_FOR_APPROVE,
  OFFER_STATUSES_FOR_COUNTER_OFFER,
  OFFER_STATUSES_FOR_REJECT,
  sortingColumnsHeaders,
  sortingFieldsHeadersMap,
  sortingTypeFilterUiMap,
} from './const'

export const getCustomerUrl = (customer: Customer): string => {
  return `/Modules/Orders/CustomerDetails.aspx?id=${customer.id}`
}

export const getConfirmApprovalMessage = (offersToApprove: Offer[]): JSX.Element => {
  if (offersToApprove.length === 0) {
    return <Box />
  }

  if (offersToApprove.length === 1) {
    const offer = offersToApprove[0]
    return (
      <>
        <Text type="paragraph">{`You are approving an offer of $${
          offer.price?.amount ?? 0
        } on product ${offer.product.name} that has an ask of $${
          offer.product.price?.amount ?? 0
        }`}</Text>
        <Text type="paragraph">Are you sure you want to approve this offer?</Text>
      </>
    )
  }

  return (
    <>
      <Text type="paragraph">Are you sure?</Text>
      <Text type="paragraph">
        {`You are going to approve the offers ${offersToApprove
          .map(offer => offer.id)
          .join(', ')}.`}
      </Text>
      <Text type="paragraph">Do you want to continue?</Text>
    </>
  )
}

export const getConfirmRejectMessage = (offersToReject: Offer[]): JSX.Element => {
  if (offersToReject.length === 0) {
    return <Box />
  }

  if (offersToReject.length === 1) {
    const offer = offersToReject[0]
    return (
      <>
        <Text type="paragraph">{`You are rejecting an offer of $${
          offer.price?.amount ?? 0
        } on product ${offer.product.name} that has an ask of $${
          offer.product.price?.amount ?? 0
        }`}</Text>
        <Text type="paragraph">Are you sure you want to reject this offer?</Text>
      </>
    )
  }

  return (
    <>
      <Text type="paragraph">Are you sure?</Text>
      <Text type="paragraph">
        {`You are going to reject the offers ${offersToReject.map(offer => offer.id).join(', ')}.`}
      </Text>
      <Text type="paragraph">Do you want to continue?</Text>
    </>
  )
}

export const getOffersApprovalActionAbility = (offers: Offer[]): ActionAbility => {
  const offersStatuses = offers.map(offer => offer.status)

  const hasOffersWithWrongStatus = offersStatuses.some(
    status => !OFFER_STATUSES_FOR_APPROVE.includes(status),
  )

  if (hasOffersWithWrongStatus) {
    return {
      canPerform: false,
      reasonMessage: 'Only Active or Counter offers can be approved.',
    }
  }

  // check stock only is case of one offer
  if (offers.length !== 1) {
    return {
      canPerform: true,
    }
  }
  const offer = offers[0]
  const isNotEnoughStockForOffer = offer.statistic.targetStock.quantity.value < offer.quantity.value
  if (isNotEnoughStockForOffer) {
    return {
      canPerform: false,
      reasonMessage: 'There are not enough products in stock for this offer.',
    }
  }

  return {
    canPerform: true,
  }
}

export const getOfferCounterActionAbility = (offers: Offer[]): ActionAbility => {
  const offersStatuses = offers.map(offer => offer.status)

  const hasOffersWithWrongStatus = offersStatuses.some(
    status => !OFFER_STATUSES_FOR_COUNTER_OFFER.includes(status),
  )

  if (hasOffersWithWrongStatus) {
    return {
      canPerform: false,
      reasonMessage: 'Only Active or Counter offers can be used for Counter Offer.',
    }
  }

  return {
    canPerform: true,
  }
}

export const getOffersRejectActionAbility = (offers: Offer[]): ActionAbility => {
  const offersStatuses = offers.map(offer => offer.status)

  const hasOffersWithWrongStatus = offersStatuses.some(
    status => !OFFER_STATUSES_FOR_REJECT.includes(status),
  )

  if (hasOffersWithWrongStatus) {
    return {
      canPerform: false,
      reasonMessage: 'Only Active or Counter offers can be rejected.',
    }
  }

  return {
    canPerform: true,
  }
}

const getSortingFieldFromHeader = (header: OffersGridHeaderType): SortedColumn | 'unknown' => {
  switch (header) {
    case OffersGridHeaderType.Id:
      return 'Id'
    case OffersGridHeaderType.Sku:
      return 'Sku'
    case OffersGridHeaderType.Status:
      return 'Status'
    case OffersGridHeaderType.ApprovedDate:
      return 'ApprovalDate'
    case OffersGridHeaderType.Offer:
      return 'CustomerOfferPrice'
    case OffersGridHeaderType.ExpirationDate:
      return 'ExpirationDate'
    case OffersGridHeaderType.SellersCounterOffer:
      return 'SellerOfferPrice'
    default:
      return 'unknown'
  }
}

export const getOffersGridColumnFreeze = (header: OffersGridHeaderType): FreezeType | undefined => {
  switch (header) {
    case OffersGridHeaderType.Id:
      return 'left'
    case OffersGridHeaderType.Actions:
      return 'right'
    default:
      return undefined
  }
}

export const getOffersGridHeaderSorting = (
  header: OffersGridHeaderType,
  filters: OfferFilter,
): Sorting | undefined => {
  if (!sortingColumnsHeaders.includes(header)) {
    return undefined
  }

  if (filters.sorting && sortingFieldsHeadersMap[filters.sorting.column] === header) {
    return {
      field: filters.sorting.column,
      type: sortingTypeFilterUiMap[filters.sorting.sorting],
    }
  }

  return {
    field: getSortingFieldFromHeader(header),
    type: 'none',
  }
}

export const getConfirmApprovalOfCounterWithHigherSimilarMessage = ({
  offerIds,
}: {
  offerIds: string[]
}) => {
  const searchParams = new URLSearchParams()

  return (
    <>
      <Text type="paragraph">
        There is another active offer(s) at a higher price than this:{' '}
        {offerIds.map((offerId, index) => {
          searchParams.set(OFFERS_FILTER_OFFER_ID_QUERY_KEY, offerId)

          return (
            <span key={offerId}>
              {index === 0 ? null : ', '}
              <Link target="_blank" href={`${ROUTES.OFFER_MANAGEMENT}?${searchParams.toString()}`}>
                {offerId}
              </Link>
            </span>
          )
        })}
      </Text>
      <Text type="paragraph">Are you sure you want to proceed?</Text>
    </>
  )
}

export const getConfirmApprovalOfCounterWithHistoryHigherSimilarMessage = ({
  offerIds,
}: {
  offerIds: string[]
}) => {
  const searchParams = new URLSearchParams()

  return (
    <>
      <Text type="paragraph">
        A higher offer was submitted in the last 2 weeks. See higher offers{' '}
        {offerIds.map((offerId, index) => {
          searchParams.set(OFFERS_FILTER_OFFER_ID_QUERY_KEY, offerId)

          return (
            <span key={offerId}>
              {index === 0 ? null : ', '}
              <Link target="_blank" href={`${ROUTES.OFFER_MANAGEMENT}?${searchParams.toString()}`}>
                {offerId}
              </Link>
            </span>
          )
        })}
      </Text>
      <Text type="paragraph">Are you sure you want to proceed?</Text>
    </>
  )
}
