import React from 'react'
import { format } from 'date-fns'
import { Checkbox, Link, Text } from '@r1/ui-kit'
import { ColumnSettings, DisplaySettings } from '@r1/grid'
import { Offer, OfferFilter } from '@r1-webui/gowholesale-offermanagement-v1'
import { GOWHOLESALE_URL_DEV } from '../../../gowholesale/const'
import { GRID_DATE_FORMAT, offersGridHeaders } from './const'
import { OfferAction, OffersGridHeaderType } from './types'
import { CustomerLink } from './components/CustomerLink'
import { OfferStatusLabel } from './components/OfferStatusLabel'
import { OfferUnitsLink } from './components/OfferUnitsLink'
import { EdgeActiveOfferInfo } from './components/EdgeActiveOfferInfo'
import { OfferLastSale } from './components/OfferLastSale'
import { OfferAskPriceLink } from './components/OfferAskPrice'
import { OfferActionsMenu } from './components/OfferActionsMenu'
import { getOffersGridColumnFreeze, getOffersGridHeaderSorting } from './utils'

const getOffersGridCellRenderer = ({
  headerType,
  allowedToEditOffer,
  onOfferActionClick,
}: {
  headerType: OffersGridHeaderType
  allowedToEditOffer: boolean
  onOfferActionClick: (offer: Offer, action: OfferAction) => void
}) => {
  switch (headerType) {
    case OffersGridHeaderType.Id:
      return (offer: Offer) => (
        <Link onClick={() => onOfferActionClick(offer, OfferAction.ViewOffer)}>{offer.id}</Link>
      )
    case OffersGridHeaderType.Status:
      return (offer: Offer) => (
        <OfferStatusLabel
          status={offer.status}
          isCustomerCounterOfferLast={offer.statistic.isCustomerCounterOfferLast}
        />
      )
    case OffersGridHeaderType.CompanyName:
      return (offer: Offer) => {
        return (
          <CustomerLink
            customer={offer.customer}
            onClick={() => onOfferActionClick(offer, OfferAction.ViewCustomer)}
          />
        )
      }
    case OffersGridHeaderType.Product:
      return (offer: Offer) => (
        <Link
          target="_blank"
          href={`${window._env_?.GOWHOLESALE_URL || GOWHOLESALE_URL_DEV}${offer.product.slug}`}
        >
          <Text overflow="ellipsis">{offer.product.name}</Text>
        </Link>
      )
    case OffersGridHeaderType.Sku:
      return (offer: Offer) => <Text>{offer.masterSku}</Text>
    case OffersGridHeaderType.Upc:
      return (offer: Offer) => <Text>{offer.product.upc}</Text>
    case OffersGridHeaderType.Ask:
      return (offer: Offer) => <Text>&#36;{offer.product.price.amount}</Text>
    case OffersGridHeaderType.Offer:
      return (offer: Offer) => <Text>&#36;{offer.price.amount}</Text>
    case OffersGridHeaderType.OfferUnits:
      return (offer: Offer) => <OfferUnitsLink offer={offer} />
    case OffersGridHeaderType.HighestActiveOffer:
      return (offer: Offer) => (
        <EdgeActiveOfferInfo
          qty={offer.statistic.maxQuantity?.value}
          price={offer.statistic.maxPrice?.amount}
        />
      )
    case OffersGridHeaderType.LowestActiveOffer:
      return (offer: Offer) => (
        <EdgeActiveOfferInfo
          qty={offer.statistic.minQuantity?.value}
          price={offer.statistic.minPrice?.amount}
        />
      )
    case OffersGridHeaderType.Facility:
      return (offer: Offer) => <Text>{offer.statistic.targetStock.facilityName}</Text>
    case OffersGridHeaderType.RemainingUnits:
      return (offer: Offer) => (
        <Text>
          {offer.statistic.targetStock.quantity.value}&nbsp;(Reserved&nbsp;
          {offer.statistic.reserved.value})
        </Text>
      )
    case OffersGridHeaderType.LastSale:
      return (offer: Offer) => <OfferLastSale offer={offer} />
    case OffersGridHeaderType.Recovery:
      return (offer: Offer) => <Text>{offer.statistic.recovery}%</Text>
    case OffersGridHeaderType.ExpirationDate:
      return (offer: Offer) => <Text>{format(new Date(offer.expiringOn), GRID_DATE_FORMAT)}</Text>
    case OffersGridHeaderType.CancellationDate:
      return (offer: Offer) => (
        <Text>
          {offer.cancelledOn ? format(new Date(offer.cancelledOn), GRID_DATE_FORMAT) : '-'}
        </Text>
      )
    case OffersGridHeaderType.SellersCounterOffer:
      return (offer: Offer) => <OfferAskPriceLink offer={offer} />
    case OffersGridHeaderType.SellersCounterOfferUnits:
      return (offer: Offer) => <Text>{(offer.askQty && offer.askQty.value) || '-'}</Text>
    case OffersGridHeaderType.ApprovedDate:
      return (offer: Offer) => (
        <Text>{offer.approvedOn ? format(new Date(offer.approvedOn), GRID_DATE_FORMAT) : '-'}</Text>
      )
    case OffersGridHeaderType.ApprovedBy:
      return (offer: Offer) => <Text>{offer.approvedBy || '-'}</Text>
    case OffersGridHeaderType.LastTwoWeekAverageProductPrice:
      return (offer: Offer) => (
        <Text>
          {offer.statistic.lastTwoWeeksAveregeProductPrice?.amount == null
            ? 'N/A'
            : `$${offer.statistic.lastTwoWeeksAveregeProductPrice?.amount}`}
        </Text>
      )
    case OffersGridHeaderType.LastTwoWeekUnitsSold:
      return (offer: Offer) => <Text>{offer.statistic.lastTwoWeeksUnitsSold}</Text>
    case OffersGridHeaderType.Actions:
      return (offer: Offer) => (
        <OfferActionsMenu
          offer={offer}
          allowedToEdit={allowedToEditOffer}
          onActionClick={onOfferActionClick}
        />
      )
    default:
      return () => null
  }
}

export function getDisplaySettings({
  allowedToEditOffer,
  isAllOffersSelected,
  selectedOfferIds,
  filter,
  onOfferSelect,
  onSelectAllOffers,
  onOfferActionClick,
}: {
  allowedToEditOffer: boolean
  isAllOffersSelected: boolean
  selectedOfferIds: string[]
  filter: OfferFilter
  onOfferSelect: (offerId: string, wasChecked: boolean) => () => void
  onSelectAllOffers: () => void
  onOfferActionClick: (offer: Offer, action: OfferAction) => void
}): DisplaySettings<Offer> {
  return {
    showCounter: false,
    columnsSettings: [
      {
        width: 50,
        freeze: 'left',
        header: {
          content: (
            <Checkbox
              indeterminate={!isAllOffersSelected && !!selectedOfferIds.length}
              checked={isAllOffersSelected}
              onChange={onSelectAllOffers}
            />
          ),
        },
        cell: {
          $type: 'custom',
          renderer: (offer: Offer) => {
            const checked = isAllOffersSelected || selectedOfferIds.includes(offer.id)
            return <Checkbox checked={checked} onChange={onOfferSelect(offer.id, checked)} />
          },
        },
      },
      ...Array.from(offersGridHeaders).map(
        ([headerType, columnSettings]): ColumnSettings<Offer> => {
          const freeze = getOffersGridColumnFreeze(headerType)
          const sorting = getOffersGridHeaderSorting(headerType, filter)

          return {
            freeze,
            width: columnSettings.width,
            minWidth: 100,
            header: {
              content: <div>{columnSettings.header}</div>,
              sorting,
            },
            verticalAlign: 'middle' as const,
            horizontalAlign: 'left' as const,
            cell: {
              $type: 'custom' as const,
              renderer: getOffersGridCellRenderer({
                headerType,
                allowedToEditOffer,
                onOfferActionClick,
              }),
            },
          }
        },
      ),
    ],
  }
}
