/* eslint-disable no-console */
// @flow

import { handleServerError } from '@r1/core-blocks'
import { pickersApi } from '../../../../api/lightningPickConveyorManagement'
import type { Picker, FilterState } from './types'

type SearchPickersResponseBody = {|
  totalCount: number,
  pickers: Picker[],
|}

type SearchParams = {|
  filterState: FilterState,
  page: number,
  pageSize: number,
|}

export const searchPickers = async ({
  filterState: { programId: programIdFromState, logins, names, barCodes },
  page,
  pageSize,
}: SearchParams): Promise<SearchPickersResponseBody> => {
  try {
    const response = await pickersApi.searchPickers({
      filter: {
        programId: programIdFromState || '',
        logins,
        names,
        barCodes,
      },
      page,
      pageSize,
    })

    if (response.status !== 200) {
      handleServerError(response)
      return { totalCount: 0, pickers: [] }
    }

    return response.body
  } catch (error) {
    console.error(error)
    return { totalCount: 0, pickers: [] }
  }
}

export const exportAllPickersGetUrl = async (): Promise<string | null> => {
  try {
    const response = await pickersApi.exportAllPickersGetUrl()

    if (response.status !== 200) {
      handleServerError(response)
      return null
    }

    return response.body.url
  } catch (error) {
    console.error(error)
    return null
  }
}

export const printPickersGetDocumentId = async ({
  userPrincipalIds,
  machineId,
}: {|
  userPrincipalIds: string[],
  machineId: string,
|}): Promise<string | null> => {
  try {
    const response = await pickersApi.printPickers({
      userPrincipalIds,
      machineId,
    })

    if (response.status !== 200) {
      handleServerError(response)
      return null
    }

    return response.body.documentId
  } catch (error) {
    console.error(error)
    return null
  }
}
