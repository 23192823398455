// @flow

import * as React from 'react'

export const getNotificationLevel = (code: string) => {
  switch (code) {
    case 'Error':
      return 'error'
    case 'Warning':
      return 'warning'
    case 'Success':
    default:
      return 'success'
  }
}

export const getTitle = (details: mixed) => {
  if (!Array.isArray(details)) {
    return ''
  }

  return `Would you like to create ${details.length} requests?`
}

export const getMessage = (details: mixed) => {
  if (!Array.isArray(details)) {
    return ''
  }

  const units = details.map(item =>
    item && typeof item === 'object'
      ? `${String(item.vendor)}, ${String(item.unitsCount)} ${
          Number(item.unitsCount) === 1 ? 'unit' : 'units'
        }`
      : '',
  )

  return (
    <>
      A request can only be created with units from the same vendor. The following requests will be
      created:
      <br />
      {units.map(unit => (
        <>
          <br />
          {unit}
          <br />
        </>
      ))}
    </>
  )
}
export const getNotificationTitle = (req: $AnyObject) => {
  if (req.code === 'Error') {
    return 'Request error'
  }

  if (req.code !== 'Success') {
    return ''
  }

  let payload = []

  try {
    payload = typeof req.payload === 'string' ? JSON.parse(req.payload) : []
  } catch (e) {
    console.error(e)
  }

  const ids = payload
    .map(id => `#${id}`)
    .join(' ')
    .trim()

  return `RA Request ${ids} created`
}

export const isMultipleVendorError = (details: mixed) => {
  const checkDetailsFormat = item =>
    typeof item === 'object' &&
    item !== null &&
    item.vendor &&
    item.unitsCount !== null &&
    item.unitsCount !== undefined

  return Array.isArray(details) && details.length && details.every(checkDetailsFormat)
}
