// @flow

import type { Program, Picker } from '../types'

type FilterState = {|
  isFetching: boolean,
  programs: Program[],
  programId: string | null,
  logins: string[],
  names: string[],
  barCodes: string[],
|}

type GridState = {|
  isFetching: boolean,
  pickers: Picker[],
  totalCount: number,
  selectedPickerIds: string[],
  isAllSelected: boolean,
  page: number,
  pageSize: number,
|}

type ControllerState = {|
  gridState: GridState,
  filterState: FilterState,
|}

type GRID_SET_FETCHING = 'GRID_SET_FETCHING'
type GRID_SET_PICKERS = 'GRID_SET_PICKERS'
type GRID_SET_TOTAL_COUNT = 'GRID_SET_TOTAL_COUNT'
type GRID_SET_SELECTED_PICKER_IDS = 'GRID_SET_SELECTED_PICKER_IDS'
type GRID_SET_ALL_SELECTED = 'GRID_SET_ALL_SELECTED'
type GRID_SET_PAGE = 'GRID_SET_PAGE'
type GRID_SET_PAGE_SIZE = 'GRID_SET_PAGE_SIZE'
type FILTER_SET_PROGRAMS = 'FILTER_SET_PROGRAMS'
type FILTER_SET_PROGRAM_ID = 'FILTER_SET_PROGRAM_ID'
type FILTER_SET_FETCHING = 'FILTER_SET_FETCHING'
type FILTER_SET_LOGINS = 'FILTER_SET_LOGINS'
type FILTER_SET_NAMES = 'FILTER_SET_NAMES'
type FILTER_SET_BARCODES = 'FILTER_SET_BARCODES'

type Types = {|
  GRID_SET_FETCHING: GRID_SET_FETCHING,
  GRID_SET_PICKERS: GRID_SET_PICKERS,
  GRID_SET_TOTAL_COUNT: GRID_SET_TOTAL_COUNT,
  GRID_SET_SELECTED_PICKER_IDS: GRID_SET_SELECTED_PICKER_IDS,
  GRID_SET_ALL_SELECTED: GRID_SET_ALL_SELECTED,
  GRID_SET_PAGE: GRID_SET_PAGE,
  GRID_SET_PAGE_SIZE: GRID_SET_PAGE_SIZE,
  FILTER_SET_PROGRAMS: FILTER_SET_PROGRAMS,
  FILTER_SET_PROGRAM_ID: FILTER_SET_PROGRAM_ID,
  FILTER_SET_FETCHING: FILTER_SET_FETCHING,
  FILTER_SET_LOGINS: FILTER_SET_LOGINS,
  FILTER_SET_NAMES: FILTER_SET_NAMES,
  FILTER_SET_BARCODES: FILTER_SET_BARCODES,
|}

const types: Types = {
  GRID_SET_FETCHING: 'GRID_SET_FETCHING',
  GRID_SET_PICKERS: 'GRID_SET_PICKERS',
  GRID_SET_TOTAL_COUNT: 'GRID_SET_TOTAL_COUNT',
  GRID_SET_SELECTED_PICKER_IDS: 'GRID_SET_SELECTED_PICKER_IDS',
  GRID_SET_ALL_SELECTED: 'GRID_SET_ALL_SELECTED',
  GRID_SET_PAGE: 'GRID_SET_PAGE',
  GRID_SET_PAGE_SIZE: 'GRID_SET_PAGE_SIZE',
  FILTER_SET_PROGRAMS: 'FILTER_SET_PROGRAMS',
  FILTER_SET_PROGRAM_ID: 'FILTER_SET_PROGRAM_ID',
  FILTER_SET_FETCHING: 'FILTER_SET_FETCHING',
  FILTER_SET_LOGINS: 'FILTER_SET_LOGINS',
  FILTER_SET_NAMES: 'FILTER_SET_NAMES',
  FILTER_SET_BARCODES: 'FILTER_SET_BARCODES',
}

type ControllerReducerAction =
  | {|
      type: GRID_SET_FETCHING,
      payload: boolean,
    |}
  | {|
      type: GRID_SET_PICKERS,
      payload: Picker[],
    |}
  | {|
      type: GRID_SET_TOTAL_COUNT,
      payload: number,
    |}
  | {|
      type: GRID_SET_SELECTED_PICKER_IDS,
      payload: string[],
    |}
  | {|
      type: GRID_SET_ALL_SELECTED,
      payload: boolean,
    |}
  | {|
      type: GRID_SET_PAGE,
      payload: number,
    |}
  | {|
      type: GRID_SET_PAGE_SIZE,
      payload: number,
    |}
  | {|
      type: FILTER_SET_PROGRAMS,
      payload: Program[],
    |}
  | {|
      type: FILTER_SET_PROGRAM_ID,
      payload: string | null,
    |}
  | {|
      type: FILTER_SET_FETCHING,
      payload: boolean,
    |}
  | {|
      type: FILTER_SET_LOGINS,
      payload: string[],
    |}
  | {|
      type: FILTER_SET_NAMES,
      payload: string[],
    |}
  | {|
      type: FILTER_SET_BARCODES,
      payload: string[],
    |}

type ControllerStateReducers = {|
  setGridFetching: (isFetching: boolean) => void,
  setGridPickers: (pickers: Picker[]) => void,
  setGridTotalCount: (totalCount: number) => void,
  setGridSelectedPickerIds: (selectedPickerIds: string[]) => void,
  setGridAllSelected: (isAllSelected: boolean) => void,
  setGridPage: (page: number) => void,
  setGridPageSize: (pageSize: number) => void,
  setFilterPrograms: (programs: Program[]) => void,
  setFilterProgramId: (programId: string | null) => void,
  setFilterFetching: (isFetching: boolean) => void,
  setFilterLogins: (logins: string[]) => void,
  setFilterNames: (logins: string[]) => void,
  setFilterBarCodes: (logins: string[]) => void,
|}

export { types }
export type {
  ControllerState,
  ControllerReducerAction,
  ControllerStateReducers,
  Picker,
  FilterState,
  GridState,
}
