// @flow

import React, { memo, useEffect, useCallback, useMemo } from 'react'
import styled from '@emotion/styled'
import {
  Drawer,
  Flex,
  Text,
  Box,
  NumericInput,
  Radio,
  RadioGroup,
  useConfigurationProvider,
  BottomButtonsBar,
} from '@r1/ui-kit'
import type { EnhancedFlexProps as FlexProps } from '@r1/ui-kit/build/types/Flex'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'
import { createLocation, getLocations, updateLocation } from '../../../services'
import { defaultState } from '../LocationsGridController'
import type { LocationManagementState, ControllerStateReducers, FilterState } from '../types'
import { SortingIndexesSelect } from './SortingIndexesSelect'

type Props = {|
  state: LocationManagementState,
  filterState: FilterState,
  reducers: ControllerStateReducers,
|}

const DrawerInnerContainerFlex: React$ComponentType<FlexProps> = styled(Flex)`
  height: 100%;
`

export const LocationManagementDrawer = memo<Props>(function LocationManagementDrawer({
  state,
  filterState,
  reducers,
}) {
  const {
    isOpen,
    isLoading,
    type,
    divert,
    sort,
    locationType,
    locationName,
    sortingIndexId,
    sortingIndexes,
  } = state
  const {
    setGridLocations,
    setGridFetching,
    setLocationManagementOpen,
    setLocationManagementDivert,
    setLocationManagementSort,
    setLocationManagementSortingIndexId,
    setLocationManagementSortingIndexes,
    setLocationManagementLocationType,
    setLocationManagementLoading,
    setLocationManagementLocationName,
  } = reducers

  const { palette } = useConfigurationProvider()

  useEffect(() => {
    if (type === 'create') {
      const { locationManagementState } = defaultState

      setLocationManagementSortingIndexes(locationManagementState.sortingIndexes)
      setLocationManagementDivert(locationManagementState.divert)
      setLocationManagementSort(locationManagementState.sort)
      setLocationManagementSortingIndexId(locationManagementState.sortingIndexId)
      setLocationManagementLocationType(locationManagementState.locationType)
      setLocationManagementLocationName(locationManagementState.locationName)
    }
  }, [type])

  useEffect(() => {
    if (locationType === 'CatchAll') setLocationManagementSortingIndexId(null)
  }, [locationType])

  const toggleLocationManagement = useCallback(() => setLocationManagementOpen(!isOpen), [isOpen])

  const onSaveClick = async () => {
    setLocationManagementLoading(true)
    let success: boolean = false

    if (type === 'edit') {
      success = await updateLocation({ locationManagementState: state, filterState })
    } else {
      success = await createLocation({ locationManagementState: state, filterState })
    }

    if (success) {
      setLocationManagementOpen(false)

      setGridFetching(true)
      const locations = await getLocations({
        program: filterState.program,
        divert: filterState.divert,
        locationType: filterState.locationType,
      })
      setGridLocations(locations)
      setGridFetching(false)
    }

    setLocationManagementLoading(false)
  }

  const title = useMemo(
    () =>
      type === 'edit' ? `Edit Conveyor Location ${locationName || ''}` : 'Create Conveyor Location',
    [locationName, type],
  )

  const label = useMemo(
    () =>
      locationName ? (
        <Flex>
          <Text>Conveyor Locations #:</Text>&nbsp;
          <Text color={palette.blue[500]}>{locationName}</Text>
        </Flex>
      ) : (
        <Text>Conveyor Locations</Text>
      ),
    [locationName, palette.blue],
  )

  const bottomActionButtons: BottomActionButton[] = [
    {
      title: 'Cancel',
      align: 'right',
      color: 'secondary',
      transparent: true,
      onClick: toggleLocationManagement,
    },
    {
      title: 'Save',
      align: 'right',
      loading: isLoading,
      onClick: onSaveClick,
    },
  ]

  return (
    <Drawer.Form
      closeButton
      title={title}
      label={label}
      show={isOpen}
      size={600}
      placement="right"
      footer={<BottomButtonsBar bottomActionButtons={bottomActionButtons} />}
      onClose={toggleLocationManagement}
    >
      <DrawerInnerContainerFlex column px="XL" py="L" justify="space-between">
        <Flex column>
          <Flex column mb="M" align="flex-start" spaceBetween="XS">
            <Text>Sorting index</Text>
            <SortingIndexesSelect
              width={400}
              program={filterState.program}
              disabled={isLoading || locationType === 'CatchAll'}
              sortingIndexId={sortingIndexId}
              sortingIndexes={sortingIndexes}
              reducers={reducers}
            />
          </Flex>

          <Flex column mt="XL" mb="M" spaceBetween="S">
            <Text weight="bold">Conveyor route</Text>

            <Flex spaceBetween="L">
              <Flex column align="flex-start" spaceBetween="XS">
                <Text>Divert</Text>
                <Box minWidth={80} maxWidth={80}>
                  <NumericInput
                    min={1}
                    max={99}
                    value={divert}
                    onChange={setLocationManagementDivert}
                  />
                </Box>
              </Flex>

              <Flex column align="flex-start" spaceBetween="XS">
                <Text>Slot</Text>
                <Box minWidth={80} maxWidth={80}>
                  <NumericInput
                    min={1}
                    max={99}
                    value={sort}
                    onChange={setLocationManagementSort}
                  />
                </Box>
              </Flex>
            </Flex>
          </Flex>

          <Flex column mt="XL" spaceBetween="S">
            <Text weight="bold">Slot type</Text>
            <RadioGroup
              name="slot-type-radio-group"
              value={locationType}
              onChange={setLocationManagementLocationType}
            >
              <Flex column>
                {filterState.locationTypes.map(lt => (
                  <Flex key={lt.id} align="center" spaceBetween="S">
                    <Radio value={lt.name} />
                    <Text>{lt.name}</Text>
                  </Flex>
                ))}
              </Flex>
            </RadioGroup>
          </Flex>
        </Flex>
      </DrawerInnerContainerFlex>
    </Drawer.Form>
  )
})
