import React from 'react'

import { Flex } from '@r1/ui-kit'

import { RtvRequirements } from '../../../../../../types/RtvRequirements'
import { InfoBlockWrapper } from '../../../../../../components/InfoBlockWrapper'
import { ProductConditions } from '../../../../../../types/ProductConditions'
import { FormLoadingPlaceholder } from '../../../../../../../../components/FormLoadingPlaceholder'
import { AppearanceMode } from '../../../../../../constants/AppearanceMode'
import { PolicyRulesSectionView } from './PolicyRulesSectionView'

type Props = {
  mode: AppearanceMode
  productConditions: ProductConditions
  rtvRequirements: RtvRequirements
}

export const PolicyRulesSection: React.FC<Props> = ({
  mode,
  productConditions,
  rtvRequirements,
}) => {
  if (mode === AppearanceMode.Loading) {
    return (
      <Flex column mb="L" spaceBetween="L">
        <FormLoadingPlaceholder numberOfFields={3} />
        <FormLoadingPlaceholder numberOfFields={9} />
      </Flex>
    )
  }

  return (
    <InfoBlockWrapper>
      <PolicyRulesSectionView
        productConditions={productConditions}
        rtvRequirements={rtvRequirements}
      />
    </InfoBlockWrapper>
  )
}
