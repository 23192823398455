export const RETURN_METHOD_OPTIONS = [
  'VENDOR PICKUP',
  'DESTROY',
  'DESTROY WITH CONDITION',
  'DESTROY WITH AUTHORIZATION',
  'PARCEL COLLECT',
  'CALL FOR DISPOSITION',
  'COM CARRIER COLLECT',
]

export const POLICY_REQUIREMENT_FOR_ACCEPTENCE_OPTIONS = [
  'BLANKET RA',
  'NO RA REQUIRED',
  'CALL FOR RA',
  'CONDITIONAL RA',
]
