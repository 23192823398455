// @flow

import React from 'react'
import styled from '@emotion/styled'
import { Button, Flex, Placeholder, Select, Text, TagInput, Box } from '@r1/ui-kit'
import { searchPickers } from '../../services/lightningPickConveyorManagement'
import type { ControllerStateReducers, GridState, FilterState } from './types'

const FieldWrapper = styled.div`
  width: 300px;
`

type Props = {|
  state: FilterState,
  gridState: GridState,
  reducers: ControllerStateReducers,
|}

export const Filter = ({
  state,
  gridState: { isFetching: isGridFetching, page, pageSize },
  reducers: {
    setGridFetching,
    setGridAllSelected,
    setGridSelectedPickerIds,
    setGridPickers,
    setGridTotalCount,
    setFilterLogins,
    setFilterNames,
    setFilterBarCodes,
    setFilterProgramId,
  },
}: Props) => {
  const { isFetching: isFilterFetching, programs, programId, names, logins, barCodes } = state

  const onSearchClick = async () => {
    setGridFetching(true)

    const pickersResponse = await searchPickers({ pageSize, page, filterState: state })
    setGridPickers(pickersResponse.pickers)
    setGridTotalCount(pickersResponse.totalCount)

    setGridSelectedPickerIds([])
    setGridAllSelected(false)

    setGridFetching(false)
  }

  return isFilterFetching ? (
    <Placeholder type="rectangle" height={40} />
  ) : (
    <Flex column spaceBetween="M">
      <Flex flexWrap spaceBetween="M">
        <Box alignSelf="flex-start">
          <Flex column spaceBetween="XXS">
            <Text>Program</Text>
            <Select
              clearable={false}
              width={300}
              options={programs}
              value={programId}
              onChange={setFilterProgramId}
            />
          </Flex>
        </Box>

        <Flex column spaceBetween="XXS">
          <Text>Logins</Text>
          <FieldWrapper>
            <TagInput $type="simpleTagValue" value={logins} onChange={setFilterLogins} />
          </FieldWrapper>
        </Flex>

        <Flex column spaceBetween="XXS">
          <Text>Names</Text>
          <FieldWrapper>
            <TagInput $type="simpleTagValue" value={names} onChange={setFilterNames} />
          </FieldWrapper>
        </Flex>

        <Flex column spaceBetween="XXS">
          <Text>Bar codes</Text>
          <FieldWrapper>
            <TagInput $type="simpleTagValue" value={barCodes} onChange={setFilterBarCodes} />
          </FieldWrapper>
        </Flex>
      </Flex>
      <Box>
        <Button loading={isGridFetching} onClick={onSearchClick}>
          Search
        </Button>
      </Box>
    </Flex>
  )
}
