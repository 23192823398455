// @flow

import type {
  Location,
  LocationType,
  Program,
  MappedLocationType,
  SortingIndex,
  FilterStateDivert,
  FilterStateLocationType,
  FilterStateProgram,
  ViewProps,
} from '../types'

type GridState = {|
  isFetching: boolean,
  page: number,
  pageSize: number,
  locations: Location[],
  isAllSelected: boolean,
  selectedLocationIds: string[],
|}

type FilterState = {|
  isFetching: boolean,
  program: Program | null,
  divert: string | null,
  locationType: string | null,
  programs: Program[],
  locationTypes: MappedLocationType[],
|}

type LocationManagementType = 'create' | 'edit'

type LocationManagementState = {|
  isOpen: boolean,
  isLoading: boolean,
  type: LocationManagementType,
  locationId: string | null,
  locationName: string | null,
  locationType: string | null,
  divert: string | null,
  sort: string | null,
  sortingIndexId: string | null,
  sortingIndexes: SortingIndex[],
|}

type DeleteConfirmationState = {|
  isOpen: boolean,
  isLoading: boolean,
  location: Location | null,
  locationIds: string[] | null,
|}

type ControllerState = {|
  gridState: GridState,
  filterState: FilterState,
  locationManagementState: LocationManagementState,
  deleteConfirmationState: DeleteConfirmationState,
|}

type GRID_SET_FETCHING = 'GRID_SET_FETCHING'
type GRID_SET_LOCATIONS = 'GRID_SET_LOCATIONS'
type GRID_SET_PAGE = 'GRID_SET_PAGE'
type GRID_SET_PAGE_SIZE = 'GRID_SET_PAGE_SIZE'
type GRID_SET_SELECTED_LOCATION_IDS = 'GRID_SET_SELECTED_LOCATION_IDS'
type GRID_SET_ALL_SELECTED = 'GRID_SET_ALL_SELECTED'
type FILTER_SET_LOCATION_TYPES = 'FILTER_SET_LOCATION_TYPES'
type FILTER_SET_FETCHING = 'FILTER_SET_FETCHING'
type FILTER_SET_PROGRAM = 'FILTER_SET_PROGRAM'
type FILTER_SET_DIVERT = 'FILTER_SET_DIVERT'
type FILTER_SET_LOCATION_TYPE = 'FILTER_SET_LOCATION_TYPE'
type FILTER_SET_PROGRAMS = 'FILTER_SET_PROGRAMS'
type LOCATION_MNGMNT_SET_OPEN = 'LOCATION_MNGMNT_SET_OPEN'
type LOCATION_MNGMNT_SET_LOADING = 'LOCATION_MNGMNT_SET_LOADING'
type LOCATION_MNGMNT_SET_MODAL_TYPE = 'LOCATION_MNGMNT_SET_MODAL_TYPE'
type LOCATION_MNGMNT_SET_LOCATION_ID = 'LOCATION_MNGMNT_SET_LOCATION_ID'
type LOCATION_MNGMNT_SET_LOCATION_NAME = 'LOCATION_MNGMNT_SET_LOCATION_NAME'
type LOCATION_MNGMNT_SET_DIVERT = 'LOCATION_MNGMNT_SET_DIVERT'
type LOCATION_MNGMNT_SET_SORT = 'LOCATION_MNGMNT_SET_SORT'
type LOCATION_MNGMNT_SET_SORTING_INDEX_ID = 'LOCATION_MNGMNT_SET_SORTING_INDEX_ID'
type LOCATION_MNGMNT_SET_SORTING_INDEXES = 'LOCATION_MNGMNT_SET_SORTING_INDEXES'
type LOCATION_MNGMNT_SET_LOCATION_TYPE = 'LOCATION_MNGMNT_SET_LOCATION_TYPE'
type DELETE_CONFIRMATION_SET_OPEN = 'DELETE_CONFIRMATION_SET_OPEN'
type DELETE_CONFIRMATION_SET_LOADING = 'DELETE_CONFIRMATION_SET_LOADING'
type DELETE_CONFIRMATION_SET_LOCATION = 'DELETE_CONFIRMATION_SET_LOCATION'
type DELETE_CONFIRMATION_SET_LOCATION_IDS = 'DELETE_CONFIRMATION_SET_LOCATION_IDS'

type Types = {|
  GRID_SET_FETCHING: GRID_SET_FETCHING,
  GRID_SET_LOCATIONS: GRID_SET_LOCATIONS,
  GRID_SET_PAGE: GRID_SET_PAGE,
  GRID_SET_PAGE_SIZE: GRID_SET_PAGE_SIZE,
  GRID_SET_SELECTED_LOCATION_IDS: GRID_SET_SELECTED_LOCATION_IDS,
  GRID_SET_ALL_SELECTED: GRID_SET_ALL_SELECTED,
  FILTER_SET_LOCATION_TYPES: FILTER_SET_LOCATION_TYPES,
  FILTER_SET_FETCHING: FILTER_SET_FETCHING,
  FILTER_SET_PROGRAM: FILTER_SET_PROGRAM,
  FILTER_SET_DIVERT: FILTER_SET_DIVERT,
  FILTER_SET_LOCATION_TYPE: FILTER_SET_LOCATION_TYPE,
  FILTER_SET_PROGRAMS: FILTER_SET_PROGRAMS,
  LOCATION_MNGMNT_SET_OPEN: LOCATION_MNGMNT_SET_OPEN,
  LOCATION_MNGMNT_SET_LOADING: LOCATION_MNGMNT_SET_LOADING,
  LOCATION_MNGMNT_SET_MODAL_TYPE: LOCATION_MNGMNT_SET_MODAL_TYPE,
  LOCATION_MNGMNT_SET_LOCATION_ID: LOCATION_MNGMNT_SET_LOCATION_ID,
  LOCATION_MNGMNT_SET_LOCATION_NAME: LOCATION_MNGMNT_SET_LOCATION_NAME,
  LOCATION_MNGMNT_SET_DIVERT: LOCATION_MNGMNT_SET_DIVERT,
  LOCATION_MNGMNT_SET_SORT: LOCATION_MNGMNT_SET_SORT,
  LOCATION_MNGMNT_SET_SORTING_INDEX_ID: LOCATION_MNGMNT_SET_SORTING_INDEX_ID,
  LOCATION_MNGMNT_SET_SORTING_INDEXES: LOCATION_MNGMNT_SET_SORTING_INDEXES,
  LOCATION_MNGMNT_SET_LOCATION_TYPE: LOCATION_MNGMNT_SET_LOCATION_TYPE,
  DELETE_CONFIRMATION_SET_OPEN: DELETE_CONFIRMATION_SET_OPEN,
  DELETE_CONFIRMATION_SET_LOADING: DELETE_CONFIRMATION_SET_LOADING,
  DELETE_CONFIRMATION_SET_LOCATION: DELETE_CONFIRMATION_SET_LOCATION,
  DELETE_CONFIRMATION_SET_LOCATION_IDS: DELETE_CONFIRMATION_SET_LOCATION_IDS,
|}

const types: Types = {
  GRID_SET_FETCHING: 'GRID_SET_FETCHING',
  GRID_SET_LOCATIONS: 'GRID_SET_LOCATIONS',
  GRID_SET_PAGE: 'GRID_SET_PAGE',
  GRID_SET_PAGE_SIZE: 'GRID_SET_PAGE_SIZE',
  GRID_SET_SELECTED_LOCATION_IDS: 'GRID_SET_SELECTED_LOCATION_IDS',
  GRID_SET_ALL_SELECTED: 'GRID_SET_ALL_SELECTED',
  FILTER_SET_LOCATION_TYPES: 'FILTER_SET_LOCATION_TYPES',
  FILTER_SET_FETCHING: 'FILTER_SET_FETCHING',
  FILTER_SET_PROGRAM: 'FILTER_SET_PROGRAM',
  FILTER_SET_DIVERT: 'FILTER_SET_DIVERT',
  FILTER_SET_LOCATION_TYPE: 'FILTER_SET_LOCATION_TYPE',
  FILTER_SET_PROGRAMS: 'FILTER_SET_PROGRAMS',
  LOCATION_MNGMNT_SET_OPEN: 'LOCATION_MNGMNT_SET_OPEN',
  LOCATION_MNGMNT_SET_LOADING: 'LOCATION_MNGMNT_SET_LOADING',
  LOCATION_MNGMNT_SET_MODAL_TYPE: 'LOCATION_MNGMNT_SET_MODAL_TYPE',
  LOCATION_MNGMNT_SET_LOCATION_ID: 'LOCATION_MNGMNT_SET_LOCATION_ID',
  LOCATION_MNGMNT_SET_LOCATION_NAME: 'LOCATION_MNGMNT_SET_LOCATION_NAME',
  LOCATION_MNGMNT_SET_DIVERT: 'LOCATION_MNGMNT_SET_DIVERT',
  LOCATION_MNGMNT_SET_SORT: 'LOCATION_MNGMNT_SET_SORT',
  LOCATION_MNGMNT_SET_SORTING_INDEX_ID: 'LOCATION_MNGMNT_SET_SORTING_INDEX_ID',
  LOCATION_MNGMNT_SET_SORTING_INDEXES: 'LOCATION_MNGMNT_SET_SORTING_INDEXES',
  LOCATION_MNGMNT_SET_LOCATION_TYPE: 'LOCATION_MNGMNT_SET_LOCATION_TYPE',
  DELETE_CONFIRMATION_SET_OPEN: 'DELETE_CONFIRMATION_SET_OPEN',
  DELETE_CONFIRMATION_SET_LOADING: 'DELETE_CONFIRMATION_SET_LOADING',
  DELETE_CONFIRMATION_SET_LOCATION: 'DELETE_CONFIRMATION_SET_LOCATION',
  DELETE_CONFIRMATION_SET_LOCATION_IDS: 'DELETE_CONFIRMATION_SET_LOCATION_IDS',
}

type ControllerReducerAction =
  | {|
      type: GRID_SET_FETCHING,
      payload: boolean,
    |}
  | {|
      type: GRID_SET_LOCATIONS,
      payload: Location[],
    |}
  | {|
      type: GRID_SET_PAGE,
      payload: number,
    |}
  | {|
      type: GRID_SET_PAGE_SIZE,
      payload: number,
    |}
  | {|
      type: GRID_SET_SELECTED_LOCATION_IDS,
      payload: string[],
    |}
  | {|
      type: GRID_SET_ALL_SELECTED,
      payload: boolean,
    |}
  | {|
      type: FILTER_SET_PROGRAMS,
      payload: Program[],
    |}
  | {|
      type: FILTER_SET_LOCATION_TYPES,
      payload: MappedLocationType[],
    |}
  | {|
      type: FILTER_SET_FETCHING,
      payload: boolean,
    |}
  | {|
      type: FILTER_SET_PROGRAM,
      payload: Program | null,
    |}
  | {|
      type: FILTER_SET_DIVERT,
      payload: string | null,
    |}
  | {|
      type: FILTER_SET_LOCATION_TYPE,
      payload: string | null,
    |}
  | {|
      type: LOCATION_MNGMNT_SET_OPEN,
      payload: boolean,
    |}
  | {|
      type: LOCATION_MNGMNT_SET_LOADING,
      payload: boolean,
    |}
  | {|
      type: LOCATION_MNGMNT_SET_MODAL_TYPE,
      payload: LocationManagementType,
    |}
  | {|
      type: LOCATION_MNGMNT_SET_DIVERT,
      payload: string | null,
    |}
  | {|
      type: LOCATION_MNGMNT_SET_SORT,
      payload: string | null,
    |}
  | {|
      type: LOCATION_MNGMNT_SET_LOCATION_ID,
      payload: string | null,
    |}
  | {|
      type: LOCATION_MNGMNT_SET_LOCATION_NAME,
      payload: string | null,
    |}
  | {|
      type: LOCATION_MNGMNT_SET_LOCATION_TYPE,
      payload: string | null,
    |}
  | {|
      type: LOCATION_MNGMNT_SET_SORTING_INDEX_ID,
      payload: string | null,
    |}
  | {|
      type: LOCATION_MNGMNT_SET_SORTING_INDEXES,
      payload: SortingIndex[],
    |}
  | {|
      type: DELETE_CONFIRMATION_SET_OPEN,
      payload: boolean,
    |}
  | {|
      type: DELETE_CONFIRMATION_SET_LOADING,
      payload: boolean,
    |}
  | {|
      type: DELETE_CONFIRMATION_SET_LOCATION,
      payload: Location | null,
    |}
  | {|
      type: DELETE_CONFIRMATION_SET_LOCATION_IDS,
      payload: string[] | null,
    |}

type ControllerStateReducers = {|
  setGridFetching: (isFetching: boolean) => void,
  setGridLocations: (locations: Location[]) => void,
  setGridPage: (page: number) => void,
  setGridPageSize: (pageSize: number) => void,
  setGridSelectedLocationIds: (selectedLocationIds: string[]) => void,
  setGridAllSelected: (isAllSelected: boolean) => void,
  setFilterLocationTypes: (locationTypes: MappedLocationType[]) => void,
  setFilterFetching: (isFetching: boolean) => void,
  setFilterProgram: (program: Program | null) => void,
  setFilterLocationType: (locationType: string | null) => void,
  setFilterDivert: (divert: string | null) => void,
  setFilterPrograms: (programs: Program[]) => void,
  setLocationManagementOpen: (isOpen: boolean) => void,
  setLocationManagementLoading: (isLoading: boolean) => void,
  setLocationManagementLocationId: (locationId: string | null) => void,
  setLocationManagementLocationName: (locationName: string | null) => void,
  setLocationManagementDivert: (divert: string | null) => void,
  setLocationManagementSort: (sort: string | null) => void,
  setLocationManagementModalType: (type: LocationManagementType) => void,
  setLocationManagementLocationType: (locationType: string | null) => void,
  setLocationManagementSortingIndexId: (sortingIndexId: string | null) => void,
  setLocationManagementSortingIndexes: (sortingIndexes: SortingIndex[]) => void,
  setDeleteConfirmationOpen: (isOpen: boolean) => void,
  setDeleteConfirmationLoading: (isLoading: boolean) => void,
  setDeleteConfirmationLocation: (location: Location | null) => void,
  setDeleteConfirmationLocationIds: (locationIds: string[] | null) => void,
|}

export { types }
export type {
  GridState,
  LocationType,
  ControllerStateReducers,
  ControllerState,
  ControllerReducerAction,
  FilterState,
  LocationManagementState,
  DeleteConfirmationState,
  FilterStateDivert,
  FilterStateLocationType,
  FilterStateProgram,
  ViewProps,
  Location,
  Program,
  MappedLocationType,
  SortingIndex,
}
