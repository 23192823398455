/* eslint-disable no-console */
// @flow

import { handleServerError } from '@r1/core-blocks'
import { locationsApi } from '../../../../api/lightningPickConveyorManagement'
import type {
  FilterState,
  FilterStateLocationType,
  FilterStateProgram,
  FilterStateDivert,
  LocationManagementState,
} from '../../components/LocationsGrid/types'
import { isErrorInLocationParams, mapErrorResponse } from './utils'
import type { Location, LocationType } from './types'

export const getLocations = async ({
  program,
  divert,
  locationType,
}: {|
  program: FilterStateProgram,
  divert: FilterStateDivert,
  locationType: FilterStateLocationType,
|}): Promise<Location[]> => {
  if (program) {
    const body: {| programId: string, divert?: number, locationType?: LocationType |} = {
      programId: program.id,
    }
    if (divert) body.divert = Number(divert)
    if (locationType) body.locationType = ((locationType: any): LocationType)

    try {
      const response = await locationsApi.getLocations(body)
      if (response.status !== 200) {
        handleServerError(response)
        return []
      }

      return response.body
    } catch (error) {
      console.error(error)
      return []
    }
  } else {
    console.error('Program is missing')
    return []
  }
}

export const updateLocation = async ({
  locationManagementState,
  filterState,
}: {|
  locationManagementState: LocationManagementState,
  filterState: FilterState,
|}): Promise<boolean> => {
  if (isErrorInLocationParams({ locationManagementState })) return false

  const {
    locationId,
    divert,
    locationType: locationTypeFromState,
    sortingIndexId,
    sort,
  } = locationManagementState
  const { program } = filterState

  if (locationId && divert && locationTypeFromState && sort && program) {
    const locationType: LocationType = (locationTypeFromState: any)

    try {
      const response = await locationsApi.updateLocation(
        { locationId },
        {
          divert: Number(divert),
          locationType,
          sortingIndexId: sortingIndexId || undefined,
          sort: Number(sort),
          programId: program.id,
        },
      )

      if (response.status !== 200) {
        let errorToHandle = response
        if (response.status === 422) {
          errorToHandle = mapErrorResponse(response)
        }
        handleServerError(errorToHandle)
        return false
      }

      return true
    } catch (error) {
      console.error(error)
    }
  }

  return false
}

export const createLocation = async ({
  locationManagementState,
  filterState,
}: {|
  locationManagementState: LocationManagementState,
  filterState: FilterState,
|}): Promise<boolean> => {
  if (isErrorInLocationParams({ locationManagementState })) return false

  const {
    divert,
    locationType: locationTypeFromState,
    sortingIndexId,
    sort,
  } = locationManagementState
  const { program } = filterState

  if (program && divert && locationTypeFromState && sort) {
    const locationType: LocationType = (locationTypeFromState: any)

    try {
      const response = await locationsApi.createLocation({
        divert: Number(divert),
        locationType,
        sortingIndexId: sortingIndexId || undefined,
        sort: Number(sort),
        programId: program.id,
      })

      if (response.status !== 200) {
        let errorToHandle = response
        if (response.status === 422) {
          errorToHandle = mapErrorResponse(response)
        }
        handleServerError(errorToHandle)
        return false
      }

      return true
    } catch (error) {
      console.error(error)
    }
  }

  return false
}

export const deleteLocations = async (locationIds: string[]): Promise<boolean> => {
  try {
    const response = await locationsApi.deleteLocations({ locationIds })

    if (response.status !== 200) {
      handleServerError(response)
      return false
    }

    return true
  } catch (error) {
    console.error(error)
  }

  return false
}
