// @flow

import { useEffect } from 'react'
import { getPrograms, searchPickers } from '../../../services'
import type { ViewProps } from '../types'
import { MIN_PAGE_SIZE } from './defaultState'
import { useControllerStateReducer } from './useControllerStateReducer'

type ControllerProps = {|
  children: ViewProps => React$Node,
|}

export const PickersGridController = ({ children }: ControllerProps) => {
  const { state, reducers } = useControllerStateReducer()

  useEffect(() => {
    const fetchPickers = async (programId: string) => {
      reducers.setGridFetching(true)

      const pickersResponse = await searchPickers({
        // not using programId from state because dispatch is not async
        filterState: { ...state.filterState, programId },
        page: 1,
        pageSize: MIN_PAGE_SIZE,
      })
      const { totalCount, pickers } = pickersResponse
      reducers.setGridTotalCount(totalCount)
      reducers.setGridPickers(pickers)

      reducers.setGridFetching(false)
    }

    const fetchData = async () => {
      reducers.setFilterFetching(true)

      const programs = await getPrograms()
      reducers.setFilterPrograms(programs)

      if (Array.isArray(programs) && programs.length) {
        reducers.setFilterProgramId(programs[0].id)
        await fetchPickers(programs[0].id)
      }

      reducers.setFilterFetching(false)
    }

    fetchData()
  }, [])

  return children({ ...state, reducers })
}
