import React, { useMemo } from 'react'

import { PivotTable } from '../../../../../../components/PivotTable/PivotTable'
import { parseAndFormatDate } from '../../../../../../utils/helpers'
import { PolicyTerm } from '../../../../../../types/PolicyTerm'
import { mapBoolToYesNo } from '../../../../../../mappers/mapBoolToYesNo'

type Props = {
  isDefaultPolicy: boolean
  vendorName: string | null
  policyTerm: PolicyTerm
}

function getDateValue(date: string | null) {
  if (date === null) return 'Not specified'
  return parseAndFormatDate(date)
}

export const OverviewSectionView: React.FC<Props> = ({
  isDefaultPolicy,
  vendorName,
  policyTerm,
}) => {
  const tableItems = useMemo(() => {
    return [
      {
        key: 'Default Policy',
        value: mapBoolToYesNo(isDefaultPolicy),
      },
      {
        key: 'Vendor',
        value: vendorName,
      },
      {
        key: 'Term type',
        value: policyTerm.term,
      },
      {
        key: 'Effective Date',
        value: getDateValue(policyTerm.effectiveDate),
      },
      {
        key: 'Expire Date',
        value: getDateValue(policyTerm.expiryDate),
      },
    ]
  }, [isDefaultPolicy, vendorName, policyTerm.term, policyTerm.effectiveDate])

  return <PivotTable items={tableItems} />
}
