import React, { useCallback, useMemo } from 'react'
import { Flex, Box, Text, Select } from '@r1/ui-kit'

import { Warehouse } from '@r1-webui/facilitypickupscheduler-v1'

type Props = {
  selectedWarehouse: Warehouse | null
  warehouses: Warehouse[]
  loading: boolean
  onChange: (warehouse: Warehouse) => void
  onRemove: () => void
}

export const WarehouseSelect = ({
  selectedWarehouse,
  warehouses,
  loading,
  onChange,
  onRemove,
}: Props) => {
  const options = useMemo(() => {
    return warehouses.map(x => ({ id: x.locationId, name: x.name }))
  }, [warehouses])

  const handleWarehouseChange = useCallback(
    (id: string) => {
      if (id) {
        const foundWarehouse = warehouses.find(x => x.locationId === id)
        if (foundWarehouse) {
          onChange(foundWarehouse)
        }
      } else {
        onRemove()
      }
    },
    [onChange, onRemove, warehouses],
  )

  return (
    <Flex column spaceBetween="M">
      <Box>
        <Select
          simpleValue
          placeholder="Select Warehouse"
          options={options}
          value={selectedWarehouse?.locationId || null}
          loading={loading}
          valueKey="id"
          labelKey="name"
          data-test-id="shipping__schedule-pickup__warehouse-select"
          onChange={handleWarehouseChange}
        />
      </Box>
      {selectedWarehouse && (
        <Box>
          <Text>
            Timezone: {selectedWarehouse.timeZoneId} Gates: {selectedWarehouse.countOfGates}
          </Text>
        </Box>
      )}
    </Flex>
  )
}
