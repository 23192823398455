import { handleServerError } from '@r1/core-blocks'

import { policyManagementApi } from '../api/policyManagementApi'

import { Policy } from '../types/Policy'

export async function getPolicy(policyId: string): Promise<Policy | null> {
  const response = await policyManagementApi.getPolicy({ policyId })
  if (response.status !== 200) {
    handleServerError(response)
    return null
  }
  return response.body
}

export async function getLastUploadedFileName(): Promise<string | null> {
  const response = await policyManagementApi.getLastUploadedFileName()
  if (response.status !== 200) {
    handleServerError(response)
    return null
  }

  return response.body
}
