import React from 'react'
import { Link, NotificationSystem } from '@r1/ui-kit'
import { handleServerError } from '@r1/core-blocks'

import {
  CreatePolicyConflict,
  UpdatePolicyConflict,
  HistoryEvent,
} from '@r1-webui/rtv-policymanagement-v1'
import { groupedPolicyManagementApi } from '../../api/groupedPolicyManagementApi'

import { GroupedPolicy } from '../../types/GroupedPolicy'
import { replaceTemplateValues } from '../../../../utils/responseUtils'
import { ConflictError } from '../../types/ConflictError'
import * as GroupedPolicyMapper from './groupedPolicyMapper/index'

type SuccessResponse<T> = {
  success: true
  data: T
}

type ErrorResponse<T = undefined> = {
  success: false
  field?: string
  errorMessage: string
  data: T
}

type RequestResult<T, ErrorData = undefined> = Promise<
  SuccessResponse<T> | ErrorResponse<ErrorData>
>

function handleConflictError(
  conflictError: CreatePolicyConflict | UpdatePolicyConflict,
): ErrorResponse<ConflictError> {
  const { error, ...errorData } = conflictError
  const errorMessage = replaceTemplateValues(error)
  NotificationSystem.addNotification({
    level: 'error',
    title: errorMessage,
  })

  return {
    success: false,
    field: 'itemNumbers',
    errorMessage,
    data: {
      ...errorData,
    },
  }
}

export async function getGroupedPolicy(policyId: string): RequestResult<GroupedPolicy> {
  return groupedPolicyManagementApi.getPolicy({ policyId }).then(response => {
    if (response.status !== 200) {
      handleServerError(response)
      return {
        success: false,
        errorMessage: 'Some error occurred',
      }
    }
    return {
      success: true,
      data: GroupedPolicyMapper.toState(response.body),
    }
  })
}

export async function updateGroupedPolicy(
  groupedPolicy: GroupedPolicy,
): RequestResult<void, ConflictError | undefined> {
  if (groupedPolicy.id === null) return { success: false, errorMessage: '', data: undefined }

  const groupedPolicyData = GroupedPolicyMapper.fromState(groupedPolicy)
  const response = await groupedPolicyManagementApi.updatePolicy(
    { policyId: groupedPolicy.id },
    groupedPolicyData,
  )

  if (response.status === 200) {
    NotificationSystem.addNotification({
      level: 'success',
      title: 'Grouped Policy has been updated',
    })
    return { success: true, data: undefined }
  }

  if (response.status === 409) {
    return handleConflictError(response.body)
  }

  handleServerError(response)
  return {
    success: false,
    errorMessage: 'Some error occurred',
    data: undefined,
  }
}

export async function createGroupedPolicy(
  groupedPolicy: GroupedPolicy,
): RequestResult<void, ConflictError | undefined> {
  const groupedPolicyData = GroupedPolicyMapper.fromState(groupedPolicy)
  const response = await groupedPolicyManagementApi.createPolicy(groupedPolicyData)
  if (response.status === 200) {
    const policyId = response.body
    NotificationSystem.addNotification({
      level: 'success',
      title: 'Grouped Policy has been created',
      autoDismiss: false,
      message: (
        <Link target="_blank" href={`?policyId=${policyId}`}>
          {policyId}
        </Link>
      ),
    })
    return {
      success: true,
      data: undefined,
    }
  }
  if (response.status === 409) {
    return handleConflictError(response.body)
  }

  handleServerError(response)
  return {
    success: false,
    errorMessage: 'Some error occurred',
    data: undefined,
  }
}

export async function removeItemsFromPolicy(
  policyId: string,
  itemNumbers: number[],
): RequestResult<void> {
  const response = await groupedPolicyManagementApi.removeItemsFromPolicy({ policyId }, itemNumbers)
  if (response.status !== 200) {
    switch (response.status) {
      case 403:
        NotificationSystem.addNotification({
          level: 'error',
          title: 'You have no permission to remove items from the policy',
        })
        break
      case 404:
        NotificationSystem.addNotification({
          level: 'error',
          title: `There is no policy with such id ${policyId}`,
        })
        break
      default:
        handleServerError(response)
    }
    return {
      success: false,
      errorMessage: 'Some error occurred',
      data: undefined,
    }
  }
  return {
    success: true,
    data: undefined,
  }
}

export async function getHistoryByPolicyId(
  policyId: string,
  page: number,
  pageSize: number,
): RequestResult<HistoryEvent[]> {
  const response = await groupedPolicyManagementApi.getHistoryByPolicyId(
    { policyId },
    { page, pageSize },
  )

  if (response.status !== 200) {
    switch (response.status) {
      case 403:
        NotificationSystem.addNotification({
          level: 'error',
          title: 'You have no permission to see history',
        })
        break
      case 404:
        NotificationSystem.addNotification({
          level: 'error',
          title: `There is no policy with such id ${policyId}`,
        })
        break
      default:
        handleServerError(response)
    }
    return {
      success: false,
      errorMessage: 'Some error occurred',
      data: undefined,
    }
  }

  return {
    success: true,
    data: response.body,
  }
}
