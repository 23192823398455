import * as React from 'react'
import { useCallback, useMemo } from 'react'
import { GridGenerator, parseQuery, setQuery } from '@r1/grid-generator'
import { Link } from '@r1/ui-kit'
import { useAccessControl } from '@r1/core-blocks'
import { useBackgroundTasksProvider } from '@r1/wireframe-primary'

import { httpClient } from '../../../../httpClient'

export type Props = {
  onDetailsClick: (policyId: string) => void
}

export const GroupedPolicyGrid = React.memo(({ onDetailsClick }: Props) => {
  const [allowPermissions] = useAccessControl()
  const initialStateQuery = useMemo(parseQuery, [])
  const { showBackgroundTasks } = useBackgroundTasksProvider()

  const customCellRenderer = useCallback(
    (data: Record<string, string>) => (
      <Link onClick={() => onDetailsClick(data.PolicyId)}>View Details</Link>
    ),
    [onDetailsClick],
  )

  return (
    <GridGenerator
      exportable={allowPermissions.allowPolicyExport}
      gridId="RtvGroupedPolicy"
      gridEngineBaseUrl="/uisvc/contract-management-grid"
      initialStateQuery={initialStateQuery}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      httpClient={httpClient}
      aggregationCount={{
        title: 'All Policies',
        suppressAggregation: false,
      }}
      initialCustomColumnsSettings={[
        {
          columnId: 'PolicyId',
          cell: {
            $type: 'custom',
            renderer: customCellRenderer,
          },
        },
      ]}
      onChangeState={setQuery}
      onOpenBackgroundTasks={showBackgroundTasks}
    />
  )
})
