import { ObjectMap } from '../types/ObjectMap'
import { ValidationSchemas } from './schemas'

export type ValidationErrors<T> = ObjectMap<T>

type ValidatitionResult<T> = ValidationErrors<T> | Record<string, never>

export const createValidator =
  <T>(validationSchemas: ValidationSchemas<T>) =>
  (model: T): ValidatitionResult<T> => {
    const errors: ValidatitionResult<T> = {}
    // eslint-disable-next-line guard-for-in,no-restricted-syntax
    for (const key in model) {
      const value = model[key]
      // eslint-disable-next-line no-nested-ternary
      const safeValue = value ? (Array.isArray(value) ? value : String(value)) : ''
      const validationSchema = validationSchemas[key]
      if (!validationSchema) {
        // eslint-disable-next-line no-continue
        continue
      }
      validationSchema.forEach(validationRule => {
        const { validator: validateValue, messenger } = validationRule
        if (validateValue(safeValue)) {
          return
        }

        // @ts-ignore
        if (errors[key]) {
          return // take first error
        }
        // @ts-ignore
        errors[key] = messenger(safeValue)
      })
    }

    return errors
  }
