// @flow

import React from 'react'
import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { LocationsGridScreen } from '../components/LocationsGrid'

export const ViewLPConveyorLocationsGrid = () => (
  <PageAccessControl permissions={[PERMISSIONS.allowDivertLocationsView]}>
    <LocationsGridScreen />
  </PageAccessControl>
)
