// @flow

import React from 'react'
import { PageAccessControl, PERMISSIONS } from '@r1/core-blocks'
import { PickersGridScreen } from '../components/PickersGrid'

export const ViewLPConveyorPickersGrid = () => (
  <PageAccessControl permissions={[PERMISSIONS.allowConveyorPickersView]}>
    <PickersGridScreen />
  </PageAccessControl>
)
