// @flow

import { NotificationSystem } from '@r1/ui-kit'
import type {
  UpdateLocationResponse422,
  CreateLocationResponse422,
} from '@r1-webui/lightningpickconveyormanagement-v1/src/locations'
import type { LocationManagementState } from '../../components/LocationsGrid/types'

export const isErrorInLocationParams = ({
  locationManagementState,
}: {|
  locationManagementState: LocationManagementState,
|}): boolean => {
  const missingParams = []

  const { locationType, sort, divert } = locationManagementState

  if (!locationType) missingParams.push('Slot type')
  if (!sort) missingParams.push('Slot')
  if (!divert) missingParams.push('Divert')

  if (missingParams.length) {
    NotificationSystem.addNotification({
      level: 'error',
      message: `Following parameters are missing: ${missingParams.join(', ')}`,
    })

    return true
  }

  return false
}

type ErrorType = UpdateLocationResponse422 | CreateLocationResponse422

export const mapErrorResponse = (response: ErrorType): $AnyObject => {
  const message = response.body.defaultFormat.replace(
    /\{(\w+|\d+)}/gi,
    match => response.body[match.replace(/(\{|\})/g, '')],
  )
  return {
    response: {
      status: response.status,
      statusText: String(response.status),
      data: { defaultFormat: message },
    },
  }
}
