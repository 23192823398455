export const getEmptyFacility = () => {
  return {
    id: '',
    name: '',
    accountingName: '',
    numberOfGates: 0,
    timezoneId: '',
    addresses: {
      Physical: {
        addressType: 'Physical',
        countryCode: '',
        cityName: '',
        postalCode: '',
        addressLine1: '',
        addressLine2: '',
      },
      Shipping: {
        addressType: 'Shipping',
        countryCode: '',
        cityName: '',
        postalCode: '',
        addressLine1: '',
        addressLine2: '',
      },
    },
    nonWorkingDays: [],
    schedule: [],
    shifts: [],
    shippingCutOffTimes: [],
  }
}
