import { Offer } from '@r1-webui/gowholesale-offermanagement-v1'
import { PriceAmountError, QtyError } from './const'

export const getPriceAmountError = (priceAmount: number): PriceAmountError => {
  if (priceAmount <= 0) {
    return PriceAmountError.MIN_VALUE
  }
  return PriceAmountError.NONE
}

export const getQtyError = (qty: number): QtyError => {
  if (qty <= 0) {
    return QtyError.MIN_VALUE
  }
  return QtyError.NONE
}

export const getCounterConfirmMessage = (
  qty: number,
  priceAmount: number,
  currentOffer: Offer,
): string =>
  `You are countering an offer of $${priceAmount} and quantity ${qty} on product ${currentOffer.product.name} that has a current offer of $${currentOffer.price.amount} and quantity ${currentOffer.quantity.value}.`
