import * as React from 'react'
import { Button, Dropdown, Icon } from '@r1/ui-kit'
import { MenuItem } from '@r1/ui-kit/contracts/ts/Dropdown'

export type PolicyActionId = 'createPolicy'
export type CatalogActionId = 'manageKeepInStore'
export type ActionId = PolicyActionId | CatalogActionId

type CatalogActionButtonProps = {
  onSelect: (id: ActionId) => void
}

type ActionButtonProps = {
  onSelect: (id: ActionId) => void
  items: MenuItem<ActionId>[]
}

export const ActionButtonView = (props: ActionButtonProps) => {
  return (
    <Dropdown.Menu
      items={props.items}
      mode="click"
      placement="bottom-end"
      onSelectItem={props.onSelect}
    >
      <Button transparent color="secondary" shape="square">
        <Icon type="dots" />
      </Button>
    </Dropdown.Menu>
  )
}

export const CatalogActionButton = (props: CatalogActionButtonProps) => {
  const items: MenuItem<ActionId>[] = [
    { id: 'manageKeepInStore', title: ' Manage "Keep In Store" items' },
  ]
  return <ActionButtonView items={items} onSelect={props.onSelect} />
}
