import React from 'react'
import { Flex } from '@r1/ui-kit'

export const InfoBlockWrapper = (props: { children: React.ReactNode }) => {
  return (
    <Flex column mb="L" align="flex-start">
      {props.children}
    </Flex>
  )
}
