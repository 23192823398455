// @flow

import React from 'react'
import { ContentHeader, Main, Content } from '@r1/wireframe-primary'
import { Box, HR } from '@r1/ui-kit'
import { Grid } from './Grid'
import type { ViewProps } from './types'
import { Filter } from './Filter'

export const PickersGridView = ({ gridState, filterState, reducers }: ViewProps) => {
  return (
    <Main fullScreen>
      <ContentHeader>
        <ContentHeader.Title>Conveyor Pickers</ContentHeader.Title>
      </ContentHeader>
      <Content>
        <Filter state={filterState} gridState={gridState} reducers={reducers} />

        <Box mt="M">
          <HR />
        </Box>

        <Grid state={gridState} filterState={filterState} reducers={reducers} />
      </Content>
    </Main>
  )
}
