import { LabelType } from '@r1/ui-kit/contracts/ts/Label'
import { PolicyStatus } from '../constants/PolicyStatus'

export function mapPolicyStatusToLabelType(status: PolicyStatus): LabelType {
  switch (status) {
    case PolicyStatus.Active:
      return 'success'
    case PolicyStatus.MissingData:
      return 'error'
    default:
      return 'default'
  }
}
