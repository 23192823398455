import { createKeepInStoreApi } from '@r1-webui/rtv-contractmanagement-v1'
import { httpClient } from '../../../../httpClient'

const API_PREFIX = `/uisvc/contract-management`

export const keepInStoreApi = createKeepInStoreApi({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  http: httpClient,
  urlPrefix: API_PREFIX,
})
