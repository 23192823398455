import { parseISO, isBefore } from 'date-fns'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Validator = (value: any) => boolean
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Messenger = (value: any) => string

export type ValidationRule = {
  validator: Validator
  messenger: Messenger
}

type ValidationRules = ValidationRule[]

export type ValidationSchemas<T> = {
  [k in keyof T]: ValidationRules
}

type ValidationOptionsRuleFactory = (options: string[]) => ValidationRule
type ValidationRuleFactory = () => ValidationRule

export const requiredField: ValidationRuleFactory = () => ({
  validator: (value: string) => value.length > 0,
  messenger: () => `field is required`,
})

export const atLeastOneElement: ValidationRuleFactory = () => ({
  validator: value => Array.isArray(value) && value.length > 0,
  messenger: () => `field require at least one element`,
})

export const inOptions: ValidationOptionsRuleFactory = (validOptions: string[]) => ({
  validator: (value: string) => validOptions.includes(value),
  messenger: () => `field has invalid value`,
})

export const outOfDate: ValidationRuleFactory = () => ({
  validator: (dateString: string) => {
    if (dateString) {
      const date = parseISO(dateString)
      if (isBefore(date, new Date())) {
        return false
      }
    }
    return true
  },
  messenger: () => `field has invalid value or empty`,
})
