// @flow

import type { ControllerState } from './types'

export const MIN_PAGE_SIZE = 50

export const defaultState: ControllerState = {
  gridState: {
    page: 1,
    pageSize: MIN_PAGE_SIZE,
    isFetching: false,
    totalCount: 0,
    pickers: [],
    isAllSelected: false,
    selectedPickerIds: [],
  },
  filterState: {
    programs: [],
    isFetching: false,
    programId: null,
    logins: [],
    names: [],
    barCodes: [],
  },
}
