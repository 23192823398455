import * as React from 'react'
import { assoc, assocPath, evolve, identity, memoizeWith, update, always, merge, prop } from 'ramda'

import type { Lib$Id } from '@r1/types/typescript'

import { handleServerError } from '@r1/core-blocks'
import { formatDate } from '../../utils/formatDate'
import type { Role, UserProgramSettings, ImageEditorInfo, Step, StepInfo } from '../../types/common'
import { isErrorResponse } from '../../api/helpers'
import { validate } from '../../validation'

import type {
  Actions,
  GeneralInfo,
  RolesInfo,
  UserStepId,
  UserCreationFormProps,
  ProgramsSettings,
} from '../../types/UserCreationForm'
import type { CreateUserBody, User } from '../../api/contracts'
import type { DataProviderChildProps } from '../DataProvider/types'
import type { UserCreateError } from '../../api/userManagementErrorHandler'
import { programsApi } from '../../api/programs'
import { showNotification } from '../../../../utils/notifications/showNotification'
import { defaultState } from './defaultState'

export type Props = DataProviderChildProps & {
  copyDefaultSettingsFromUserId?: string
  onFormCanceled: () => void
  onFormSubmitted: () => void
  children: (props: UserCreationFormProps) => React.ReactNode
}

type Navigation = StepInfo<UserStepId>

export type UserState = {
  active: boolean
  email?: string
  login: string
  password: string
  confirmPassword?: string
  changePasswordOnNextLogin: boolean
  dateFormat?: Lib$Id
  timeZoneId?: Lib$Id
  fullName: string
  jobTitle?: string
  userCompanyId?: Lib$Id
  locationId?: Lib$Id
  manager?: Lib$Id
  department?: string
  phone?: string
  phoneExtension?: string
  roles: Lib$Id[]
  profileImageSrc?: string
  externalId?: string
  userType?: string
  programsSettings: UserProgramSettings
  isSalesRepresentative?: boolean
  reportLoginId?: string
  reportLogins: string[]
}

type UserStateKey = keyof UserState
type EditableStepId = 'generalInfo' | 'programs' | 'roles'

type Errors = Partial<Record<UserStateKey, string>>

export type State = {
  navigation: Navigation
  user: UserState
  errors: Errors
  isVisibleImageEditor: boolean
}

type HandleChangeField = (fieldName: UserStateKey) => (value: UserState[UserStateKey]) => void
type ValidateField = (fieldName: UserStateKey, value: UserState[UserStateKey]) => string

type StepToFieldMap = Record<EditableStepId, UserStateKey[]>

type FieldToStepMap = Record<UserStateKey, EditableStepId>

const stepToFieldMap: StepToFieldMap = {
  generalInfo: [
    'confirmPassword',
    'active',
    'email',
    'login',
    'password',
    'fullName',
    'jobTitle',
    'manager',
    'department',
    'phone',
    'phoneExtension',
    'dateFormat',
    'timeZoneId',
    'locationId',
    'userCompanyId',
    'reportLoginId',
    'isSalesRepresentative',
  ],
  programs: ['programsSettings'],
  roles: ['roles'],
}

const fieldToStepMap: FieldToStepMap = (
  Object.keys(stepToFieldMap) as Array<keyof typeof stepToFieldMap>
).reduce((acc, stepId) => {
  const fields = stepToFieldMap[stepId]
  fields.forEach(field => {
    acc[field] = stepId
  })
  return acc
}, {} as FieldToStepMap)

const mapToUserState = (
  user: User,
  roles: Role[],
  state: UserState,
  programsSettings?: UserProgramSettings,
): UserState => {
  return {
    ...state,
    jobTitle: user.info.jobTitle,
    userCompanyId: user.info.userCompanyId || '',
    locationId: user.info.locationId || '',
    manager: user.info.manager,
    department: user.info.department,
    userType: user.info.userType,
    dateFormat: user.info.localizationSettings.dateFormat,
    timeZoneId: user.info.timeZoneId,
    roles: roles.map(prop('id')),
    programsSettings: programsSettings || state.programsSettings,
    reportLoginId: user.info.reportLoginId,
    isSalesRepresentative: user.info.isSalesRepresentative,
  }
}

export class UserCreationFormController extends React.Component<Props, State> {
  state = defaultState

  /** Handlers */

  handleChangeField: HandleChangeField = memoizeWith(identity, fieldName => value => {
    this.resetError(fieldName)

    const {
      errors,
      user: { confirmPassword },
    } = this.state

    const error = this.validateField(fieldName, value)

    let updatedErrors: Errors = { ...errors, [fieldName]: error }

    // Reset confirm password error. When first fill field 'Confirm password' and THEN 'Password'
    if (fieldName === 'password' && !error && confirmPassword && value === confirmPassword) {
      updatedErrors = {
        ...updatedErrors,
        confirmPassword: '',
      }
    }

    // TODO: Fix depend on previous state
    this.setState((prevState: State, _) => {
      const evolver = evolve({
        user: (user: UserState) => assoc(fieldName, value, user),
        errors: always(updatedErrors),
      })
      return evolver(prevState)
    })
  })

  componentDidMount() {
    if (this.props.copyDefaultSettingsFromUserId) this.fetchCurrentUser()

    this.props.userManagement.methods.fetchManagers()
    this.props.userManagement.methods.fetchUserCompanies()
    this.props.userManagement.methods.fetchLocations()
    this.props.userManagement.methods.fetchRoles()
    this.props.userManagement.methods.fetchDateFormats()
    this.props.userManagement.methods.fetchTimeZones()
    this.props.userManagement.methods.fetchReportLogins()
  }

  fetchCurrentUser = async () => {
    const userId = this.props.copyDefaultSettingsFromUserId

    if (!userId) return

    const user = await this.props.userManagement.methods.fetchUser(userId)
    const roles: Role[] = (await this.props.userManagement.methods.fetchUserRoles(userId)) || []

    const settingsResponse = await programsApi.getUserProgramSettings({ userId })
    let programSettings: UserProgramSettings | undefined
    if (settingsResponse.status === 200) {
      programSettings = {
        selectedProgramIds: settingsResponse.body.availablePrograms,
        defaultLoginProgramId: settingsResponse.body.defaultLoginProgram,
        defaultSearchOptionId: settingsResponse.body.defaultSearchProgram,
      }
    } else {
      handleServerError(settingsResponse)
    }

    if (user) {
      this.setState(state => ({
        user: mapToUserState(user, roles, state.user, programSettings),
      }))
    }
  }

  /** Getters */

  getStepIdxById = (stepId: UserStepId) => {
    const { steps } = this.state.navigation
    return steps.findIndex(({ id }) => id === stepId)
  }

  getStepDataById = (stepId: UserStepId): Step<UserStepId> => {
    const { steps } = this.state.navigation
    return steps.filter(({ id }) => id === stepId)[0]
  }

  resetError = (fieldName: UserStateKey) => {
    const stepId = fieldToStepMap[fieldName]
    const stepIdx = this.getStepIdxById(stepId)
    const stepData = this.getStepDataById(stepId)

    const newErrors: Errors = { [fieldName]: '' }

    const hasError = (Object.keys(newErrors) as Array<keyof typeof newErrors>).some(key => {
      if (key === fieldName) return false
      return !!this.state.errors[key]
    })

    // TODO: Fix depend on previous state
    this.setState((prevState: State, _) => {
      const evolver = evolve({
        navigation: {
          steps: update<Step<UserStepId>>(stepIdx, { ...stepData, error: hasError }),
        },
        errors: (prevErrors: Errors) => merge(prevErrors, newErrors),
      })
      return evolver(prevState)
    })
  }

  /** Validation */

  validateField: ValidateField = (fieldName, value) => {
    let error = ''

    if (value === null || typeof value === 'string') {
      error = validate(fieldName, value || '')
    }

    if (fieldName === 'confirmPassword') {
      const { password } = this.state.user
      error = password !== value ? 'Password does not match the confirm password.' : ''
    }

    if (fieldName === 'programsSettings') {
      const { selectedProgramIds, defaultSearchOptionId, defaultLoginProgramId } =
        value as UserProgramSettings
      const isValid =
        selectedProgramIds.length > 0 &&
        typeof defaultSearchOptionId !== 'undefined' &&
        typeof defaultLoginProgramId !== 'undefined'
      error = !isValid ? 'Current programs settings are invalid' : ''
    }

    return error
  }

  validateStepById = (stepId: EditableStepId): boolean => {
    const stepFields = stepToFieldMap[stepId]

    let hasStepErrors = false
    const newErrors: Errors = {}

    stepFields.forEach(fieldName => {
      const value = this.state.user[fieldName]
      const error = this.validateField(fieldName, value)

      newErrors[fieldName] = error

      if (error) hasStepErrors = true
    })

    this.setState(
      evolve({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        errors: errors => merge(errors, newErrors),
      }),
    )

    return hasStepErrors
  }

  /**
   * @returns {boolean} failed validation
   */
  validateCurrentStep = (): boolean => {
    const { currentStep } = this.state.navigation

    if (currentStep === 'generalInfo') {
      return this.validateStepById(currentStep)
    }
    if (currentStep === 'programs') {
      return this.validateStepById(currentStep)
    }

    return false
  }

  /** Navigation */

  changeStep = (updater: 1 | -1) => {
    const { currentStep, steps } = this.state.navigation
    const currentStepIdx = this.getStepIdxById(currentStep)
    const stepsCount = steps.length - 1

    if (updater > 0 && currentStepIdx === stepsCount) return
    if (updater < 0 && currentStepIdx === 0) return

    const newStepId = steps[currentStepIdx + updater].id

    this.setState(assocPath(['navigation', 'currentStep'], newStepId))
  }

  handleNextStep = () => {
    const hasCurrentStepError = this.validateCurrentStep()

    if (hasCurrentStepError) return

    this.changeStep(1)
  }

  handlePrevStep = () => {
    this.changeStep(-1)
  }

  /** Image Editor */

  setProfileImageSrc = (base64Image: string | null) => {
    this.setState(assocPath(['user', 'profileImageSrc'], base64Image))
  }

  showImageEditor = () => {
    this.setState({ isVisibleImageEditor: true })
  }

  hideImageEditor = () => {
    this.setState({ isVisibleImageEditor: false })
  }

  handleCancelEditImage = () => {
    this.setProfileImageSrc(null)
    this.hideImageEditor()
  }

  handleUploadImage = (base64Image: string) => {
    this.setProfileImageSrc(base64Image)
    this.showImageEditor()
  }

  handleChangeImage = (base64Image: string) => {
    this.setProfileImageSrc(base64Image)
    this.hideImageEditor()
  }

  handleDeleteImage = () => {
    this.setProfileImageSrc(null)
  }

  /** Form actions */

  processServerError = (error: UserCreateError) => {
    const { steps } = this.state.navigation

    if (error.$type === 'Unhandled') return

    const updatedErrors: Errors = {}
    const updatedSteps = [...steps]

    if (error.data) {
      error.data.errors.forEach(({ fieldName, message }) => {
        const userKey = fieldName as UserStateKey
        const stepId = fieldToStepMap[userKey]
        if (!stepId) return
        const stepIdx = this.getStepIdxById(stepId)
        updatedErrors[userKey] = message
        updatedSteps[stepIdx].error = true
      })
    }

    // TODO: Fix depend on previous state
    this.setState(
      evolve({
        navigation: {
          steps: () => updatedSteps,
        },
        errors: () => updatedErrors,
      }),
    )
  }

  asApiProgramsSettings = ({
    selectedProgramIds,
    defaultLoginProgramId,
    defaultSearchOptionId,
  }: UserProgramSettings) => {
    if (selectedProgramIds.length === 0 || !defaultLoginProgramId || !defaultSearchOptionId) {
      throw Error('Invalid programs settings')
    }
    return {
      availablePrograms: selectedProgramIds,
      defaultLoginProgram: defaultLoginProgramId,
      defaultSearchProgram: defaultSearchOptionId,
    }
  }

  handleSubmitForm = () => {
    const userData = this.getCreationUserData()
    const { profileImageSrc, programsSettings } = this.state.user
    const apiProgramSettings = this.asApiProgramsSettings(programsSettings)

    const handle = async () => {
      const createUserResponse = await this.props.userManagement.methods.createUser(
        userData,
        profileImageSrc,
      )
      if (
        createUserResponse &&
        typeof createUserResponse !== 'string' &&
        isErrorResponse(createUserResponse)
      ) {
        this.processServerError(createUserResponse)
        return
      }
      if (!createUserResponse || typeof createUserResponse !== 'string') {
        throw Error('Invalid create user response')
      }
      const setProgramSettingsResponse = await programsApi.setUserProgramSettings(
        { userId: createUserResponse },
        apiProgramSettings,
      )
      if (setProgramSettingsResponse.status === 200) {
        this.props.onFormSubmitted()
      } else {
        handleServerError(setProgramSettingsResponse)
      }
    }

    handle()
  }

  handleCancelForm = () => {
    showNotification({ title: 'User creation was canceled', level: 'error' })
    this.props.onFormCanceled()
  }

  /** Mappers */

  getCreationUserData = (): CreateUserBody => {
    const { user } = this.state
    const { copyDefaultSettingsFromUserId } = this.props
    const copySettings = copyDefaultSettingsFromUserId ? { copyDefaultSettingsFromUserId } : {}

    return {
      authInfo: {
        login: user.login,
      },
      userInfo: {
        fullName: user.fullName,
        jobTitle: user.jobTitle || undefined,
        department: user.department || undefined,
        userCompanyId: user.userCompanyId,
        locationId: user.locationId,
        timeZoneId: user.timeZoneId,
        manager: user.manager,
        externalId: user.externalId || undefined,
        userType: user.userType || 'FullTimeEmployee',
        reportLoginId: user.reportLoginId || undefined,
        isSalesRepresentative: user.isSalesRepresentative,
        contactInfo: {
          email: user.email || undefined,
          phone: user.phone || undefined,
          phoneExtension: user.phoneExtension || undefined,
        },
        localizationSettings: {
          dateFormat: user.dateFormat || 'YYYY-MM-DD',
        },
      },
      changePasswordOnNextLogin: user.changePasswordOnNextLogin,
      password: user.password,
      disabled: !user.active,
      roles: user.roles,
      groups: [],
      ...copySettings,
    }
  }

  hasUnsavedChanges = () => {
    const { user } = this.state
    const excludedFields = ['active', 'roles']

    return (Object.keys(user) as Array<keyof typeof user>).some(fieldName => {
      if (excludedFields.includes(fieldName)) return false
      return Boolean(user[fieldName])
    })
  }

  getGeneralInfoProps = (): GeneralInfo => {
    const { user, errors } = this.state
    const { managers, userCompanies, locations, dateFormats, timeZones, reportLogins } =
      this.props.userManagement.data
    const { requestsFlags } = this.props

    return {
      fullName: {
        value: user.fullName,
        onChange: this.handleChangeField('fullName'),
        error: errors.fullName,
      },
      jobTitle: {
        value: user.jobTitle,
        onChange: this.handleChangeField('jobTitle'),
        error: errors.jobTitle,
      },
      email: {
        value: user.email || '',
        onChange: this.handleChangeField('email'),
        error: errors.email,
      },
      login: {
        value: user.login,
        onChange: this.handleChangeField('login'),
        error: errors.login,
      },
      userCompany: {
        isLoading: requestsFlags.isFetchingUserCompanies,
        value: user.userCompanyId,
        error: errors.userCompanyId,
        onChange: this.handleChangeField('userCompanyId'),
        availableOptions: userCompanies,
      },
      location: {
        isLoading: requestsFlags.isFetchingLocations,
        value: user.locationId,
        error: errors.locationId,
        onChange: this.handleChangeField('locationId'),
        availableOptions: locations,
        onAdd: this.props.userManagement.methods.addLocation,
        onDelete: this.props.userManagement.methods.deleteLocation,
      },
      manager: {
        isLoading: requestsFlags.isFetchingManagers,
        value: user.manager,
        onChange: this.handleChangeField('manager'),
        availableOptions: managers,
      },
      department: {
        value: user.department,
        onChange: this.handleChangeField('department'),
        error: errors.department,
      },
      phone: {
        value: user.phone,
        onChange: this.handleChangeField('phone'),
        error: errors.phone,
      },
      phoneExtension: {
        value: user.phoneExtension,
        onChange: this.handleChangeField('phoneExtension'),
        error: errors.phoneExtension,
      },
      dateFormat: {
        isLoading: requestsFlags.isFetchingDateFormats,
        value: user.dateFormat || 'MM-DD-YYYY',
        onChange: this.handleChangeField('dateFormat'),
        availableOptions: dateFormats.map(dateFormatString => ({
          id: dateFormatString,
          name: `${dateFormatString} (${formatDate(new Date(), dateFormatString)})`,
        })),
      },
      timeZone: {
        isLoading: requestsFlags.isFetchingTimeZones,
        value: user.timeZoneId,
        error: errors.timeZoneId,
        onChange: this.handleChangeField('timeZoneId'),
        availableOptions: timeZones,
      },
      password: {
        value: user.password,
        onChange: this.handleChangeField('password'),
        error: errors.password,
      },
      confirmPassword: {
        value: user.confirmPassword,
        onChange: this.handleChangeField('confirmPassword'),
        error: errors.confirmPassword,
      },
      changePasswordOnNextLogin: {
        value: user.changePasswordOnNextLogin,
        onChange: this.handleChangeField('changePasswordOnNextLogin'),
      },
      activation: {
        value: user.active,
        onChange: this.handleChangeField('active'),
      },
      externalId: {
        value: user.externalId,
        onChange: this.handleChangeField('externalId'),
        error: errors.externalId,
      },
      reportLogin: {
        value: user.reportLoginId,
        onChange: this.handleChangeField('reportLoginId'),
        availableOptions: reportLogins,
        error: errors.reportLoginId,
      },
      isSalesRepresentative: {
        value: user.isSalesRepresentative || false,
        onChange: this.handleChangeField('isSalesRepresentative'),
      },
      userType: {
        value: user.userType,
        onChange: this.handleChangeField('userType'),
        error: errors.userType,
        isLoading: false,
        availableOptions: [
          { id: 'FullTimeEmployee', name: 'Full Time Employee' },
          { id: 'Operator', name: 'Operator' },
          { id: 'OperatorTemplate', name: 'Operator Template' },
        ],
      },
    }
  }

  getImageEditorInfoProps = (): ImageEditorInfo => {
    const { isVisibleImageEditor, user } = this.state
    return {
      visible: isVisibleImageEditor,
      profileImageSrc: user.profileImageSrc,
      onCancel: this.handleCancelEditImage,
      onShow: this.showImageEditor,
      onUploadImage: this.handleUploadImage,
      onChangeImage: this.handleChangeImage,
      onDeleteImage: this.handleDeleteImage,
    }
  }

  getRolesInfoProps = (): RolesInfo => {
    const { user } = this.state
    const { roles } = this.props.userManagement.data

    return {
      roles: {
        availableOptions: roles.map(({ id, name }) => ({
          id,
          name,
        })),
        selected: user.roles,
        onChange: this.handleChangeField('roles'),
      },
    }
  }

  getProgramSettings = (): ProgramsSettings => {
    const { user } = this.state

    return {
      value: user.programsSettings,
      onChange: this.handleChangeField('programsSettings'),
    }
  }

  getActionsProps = (): Actions => {
    const { currentStep, steps } = this.state.navigation
    const currentStepIdx = this.getStepIdxById(currentStep)
    const stepsCount = steps.length - 1

    return {
      prevStep: {
        allowed: currentStepIdx > 0,
        dispatch: this.handlePrevStep,
      },
      nextStep: {
        allowed: currentStepIdx < stepsCount,
        dispatch: this.handleNextStep,
      },
      cancelForm: {
        allowed: true,
        dispatch: this.handleCancelForm,
      },
      submitForm: {
        allowed: currentStepIdx === stepsCount,
        dispatch: this.handleSubmitForm,
      },
    }
  }

  getStepInfo = (): Navigation => this.state.navigation

  isSubmitting = () => this.props.requestsFlags.isCreatingUser

  render() {
    return this.props.children({
      actions: this.getActionsProps(),
      hasUnsavedChanges: this.hasUnsavedChanges(),
      generalInfo: this.getGeneralInfoProps(),
      programsSettingsInfo: this.getProgramSettings(),
      imageEditorInfo: this.getImageEditorInfoProps(),
      isSubmitting: this.isSubmitting(),
      rolesInfo: this.getRolesInfoProps(),
      stepInfo: this.getStepInfo(),
    })
  }
}
