// @flow

import React from 'react'

import { LocationsGridController } from './LocationsGridController'
import { LocationsGridView } from './LocationsGridView'

export const LocationsGridScreen = () => {
  return (
    <LocationsGridController>
      {propsFromController => <LocationsGridView {...propsFromController} />}
    </LocationsGridController>
  )
}
