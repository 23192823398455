// @flow

import * as React from 'react'
import { useMemo } from 'react'
import { GridGenerator, setQuery, parseQuery } from '@r1/grid-generator'
import { ContentHeader, Main, Content } from '@r1/wireframe-primary'
import { httpClient } from '../../../httpClient'
import { errorHandler } from '../../../utils/errorHandler'

const FinancialEventsGrid = () => {
  const initialStateQuery = useMemo(parseQuery, [])
  return (
    <Main fullScreen>
      <ContentHeader>
        <ContentHeader.Title>Financial Events</ContentHeader.Title>
      </ContentHeader>
      <Content>
        <GridGenerator
          exportable
          gridId="FinancialEvents"
          gridEngineBaseUrl="/uisvc/legacy-grids"
          initialStateQuery={initialStateQuery}
          aggregationCount={{ title: 'Found Financial Events' }}
          httpClient={httpClient}
          onError={errorHandler}
          onChangeState={setQuery}
        />
      </Content>
    </Main>
  )
}

export { FinancialEventsGrid }
