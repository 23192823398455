import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Icon, Tooltip } from '@r1/ui-kit'
import styled from '@emotion/styled'

type Props = {
  value: string
  tip?: string
  hidden?: boolean
}

const IconStyled = styled(Icon)`
  :hover {
    color: ${props => props.theme.palette.grey[800]};
  }
`

export const CopyToClipboard: React.FC<Props> = ({ value, hidden = false, tip }) => {
  const [isCopied, setIsCopied] = useState(false)

  const tooltipText = useMemo(() => {
    if (isCopied) return 'Copied'
    return tip || 'Copy to clipboard'
  }, [isCopied, tip])

  const copy = useCallback(async () => {
    await navigator.clipboard.writeText(value)
    setIsCopied(true)
  }, [value])

  const resetIsCopiedFlag = useCallback(() => {
    setIsCopied(false)
  }, [])

  useEffect(() => {
    resetIsCopiedFlag()
  }, [resetIsCopiedFlag, value])

  useEffect(() => {
    document.addEventListener('copy', resetIsCopiedFlag)
    return () => {
      document.removeEventListener('copy', resetIsCopiedFlag)
    }
  }, [resetIsCopiedFlag])

  return hidden ? null : (
    <Tooltip text={tooltipText}>
      <IconStyled type="copy" cursor="pointer" onClick={copy} />
    </Tooltip>
  )
}
