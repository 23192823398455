import React from 'react'
import { Offer, OfferFilter } from '@r1-webui/gowholesale-offermanagement-v1'
import { Customer } from '@r1-webui/customerprovider-v1'
import { NotificationSystem } from '@r1/ui-kit'
import { handleServerError, PageAccessControl, PERMISSIONS, useUserSettings } from '@r1/core-blocks'
import { Content, ContentHeader, Main } from '@r1/wireframe-primary'
import { OffersGrid } from '../components/OffersGrid/OffersGrid'
import { customerProviderApi, fetchOffers, offerManagementApi } from '../api'
import { DEFAULT_FILTER, DEFAULT_PAGINATION } from '../components/OffersGrid/const'
import { queryParametersUtils } from '../../../utils/queryParametersUtils'
import { OFFERS_FILTER_OFFER_ID_QUERY_KEY } from '../const'
import { CounterOfferData } from '../components/OffersGrid/types'
import { GridPagination } from '../types'
import { showNotification } from '../../../utils/notifications/showNotification'

export const OfferManagementOffersGridScreen = () => {
  const [data, setData] = React.useState<Offer[]>([])
  const [total, setTotal] = React.useState<number>(0)
  const [filter, setFilter] = React.useState<OfferFilter>(DEFAULT_FILTER)
  const [pagination, setPagination] = React.useState<GridPagination>(DEFAULT_PAGINATION)
  const [isLoading, setIsLoading] = React.useState<boolean>(false)
  const [isExportInProgress, setIsExportInProgress] = React.useState<boolean>(false)
  const currentUserFullName = useUserSettings().currentUser.userInfo?.fullName ?? 'Unknown'

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchData = async (payload: { filter: OfferFilter; pagination: GridPagination }) => {
    setIsLoading(true)
    const response = await fetchOffers(payload)
    if (response.status === 200) {
      setData(response.body.offers)
      setTotal(response.body.totalCount)
    } else {
      handleServerError(response)
      setData([])
      setTotal(0)
    }
    setIsLoading(false)
  }

  React.useEffect(() => {
    const { getValueByKey } = queryParametersUtils
    const offerId = getValueByKey(OFFERS_FILTER_OFFER_ID_QUERY_KEY)
    if (offerId) {
      const newFilter: OfferFilter = { offerIds: [offerId] }
      setFilter(newFilter)
      fetchData({ pagination, filter: newFilter })
    } else {
      fetchData({ pagination, filter })
    }
  }, [])

  const handleOffersConflict = ({
    wrongVersionOffers,
    notEnoughQuantityOffers,
  }: {
    wrongVersionOffers: string[]
    notEnoughQuantityOffers: string[]
  }) => {
    if (wrongVersionOffers.length) {
      NotificationSystem.addNotification({
        level: 'error',
        title: 'Error',
        message: `Unfortunately, the approval of the offers failed: ${wrongVersionOffers.join(
          ', ',
        )}. Please refresh the page and try again.`,
      })
    }

    if (notEnoughQuantityOffers.length) {
      NotificationSystem.addNotification({
        level: 'error',
        title: 'Error',
        message: `Not enough products to approve all these offers: ${notEnoughQuantityOffers.join(
          ', ',
        )}. Only some of them can be approved.`,
      })
    }
  }

  const onExportOffers = React.useCallback(async () => {
    try {
      setIsExportInProgress(true)

      await offerManagementApi.exportAndDownloadOffers({ filter })
    } catch {
      showNotification({
        level: 'error',
        message: 'Export failed',
      })
    } finally {
      setIsExportInProgress(false)
    }
  }, [filter])

  const onApproveOffers = React.useCallback(
    async (offers: Offer[]) => {
      setIsLoading(true)
      try {
        const response = await offerManagementApi.approveOffer({
          approvedBy: currentUserFullName,
          offers: offers.map(offer => ({ id: offer.id, version: offer.version })),
        })

        if (response.status === 200) {
          const { wrongVersionOffers, notEnoughQuantityOffers } = response.body

          if (wrongVersionOffers.length || notEnoughQuantityOffers.length) {
            NotificationSystem.addNotification({
              level: 'success',
              title: 'Success',
              message: 'Part of the offers have successfully approved',
            })
          } else {
            NotificationSystem.addNotification({
              level: 'success',
              title: 'Success',
              message: 'Offers have successfully approved',
            })
          }

          handleOffersConflict({ wrongVersionOffers, notEnoughQuantityOffers })
        } else if (response.status === 409) {
          const { wrongVersionOffers, notEnoughQuantityOffers } = response.body
          handleOffersConflict({ wrongVersionOffers, notEnoughQuantityOffers })
        } else {
          handleServerError(response)
        }
      } finally {
        setIsLoading(false)
        await fetchData({ pagination, filter })
      }
    },
    [currentUserFullName, pagination, filter],
  )

  const onRejectOffers = React.useCallback(
    async (offers: Offer[]) => {
      setIsLoading(true)
      try {
        const response = await offerManagementApi.rejectOffer({
          rejectedBy: currentUserFullName,
          offers: offers.map(offer => ({ id: offer.id, version: offer.version })),
        })
        if (response.status !== 200) {
          handleServerError(response)
        }

        await fetchData({ pagination, filter })

        if (response.status === 200) {
          NotificationSystem.addNotification({
            level: 'success',
            title: 'Success',
            message: offers.length === 1 ? 'Offer rejected' : 'Offers rejected',
          })
        }
      } finally {
        setIsLoading(false)
      }
    },
    [currentUserFullName, filter, pagination],
  )

  const onCounterOffer = React.useCallback(
    async ({ offer, counterPriceAmount, counterQty }: CounterOfferData) => {
      setIsLoading(true)
      try {
        const response = await offerManagementApi.counterOffer(
          { offerId: offer.id },
          {
            version: offer.version,
            askPrice: { amount: `${counterPriceAmount}`, currency: 'USD' },
            askQty: counterQty,
            askedBy: currentUserFullName,
          },
        )
        if (response.status !== 200) {
          handleServerError(response)
        }

        await fetchData({ pagination, filter })

        if (response.status === 200) {
          NotificationSystem.addNotification({
            level: 'success',
            title: 'Success',
            message: 'Counter Offer has successfully registered',
          })
        }
      } finally {
        setIsLoading(false)
      }
    },
    [currentUserFullName, filter, pagination],
  )

  const onRequestOfferHistory = React.useCallback(async (offer: Offer) => {
    const response = await offerManagementApi.getHistory({ offerId: offer.id })

    if (response.status !== 200) {
      handleServerError(response)
      return []
    }

    return response.body
  }, [])

  const onRequestCustomer = React.useCallback(async (offer: Offer): Promise<Customer | null> => {
    const { email } = offer.customer

    if (!email) {
      NotificationSystem.addNotification({
        level: 'error',
        title: 'Error',
        message: `Can't find customer e-mail`,
      })

      return null
    }

    const response = await customerProviderApi.searchCustomer({
      email,
      venueProfileType: 'GoWholeSale',
      customerType: 'B2B',
    })

    if (response.status !== 200) {
      handleServerError(response)
      return null
    }

    return response.body
  }, [])

  const onChangeFilter = (payload: Partial<OfferFilter>) => {
    const { deleteValueByKey } = queryParametersUtils
    deleteValueByKey(OFFERS_FILTER_OFFER_ID_QUERY_KEY)
    const newPagination = { ...pagination, page: DEFAULT_PAGINATION.page }
    const newFilter = { ...filter, ...payload }
    setPagination(newPagination)
    setFilter(newFilter)
    fetchData({ pagination: newPagination, filter: newFilter })
  }

  const onChangePagination = (payload: GridPagination) => {
    setPagination(payload)
    fetchData({ filter, pagination: payload })
  }

  return (
    <PageAccessControl permissions={[PERMISSIONS.allowGwsOfferManagementView]}>
      <Main fullScreen>
        <ContentHeader>
          <ContentHeader.Title>goWholesale Offer Management</ContentHeader.Title>
        </ContentHeader>

        <Content>
          <OffersGrid
            loading={isLoading}
            filter={filter}
            isExportInProgress={isExportInProgress}
            pagination={pagination}
            data={data}
            total={total}
            onChangeFilter={onChangeFilter}
            onApproveOffers={onApproveOffers}
            onExportOffers={onExportOffers}
            onRejectOffers={onRejectOffers}
            onCounterOffer={onCounterOffer}
            onRequestCustomer={onRequestCustomer}
            onRequestOfferHistory={onRequestOfferHistory}
            onChangePagination={onChangePagination}
          />
        </Content>
      </Main>
    </PageAccessControl>
  )
}
