// @flow

import React from 'react'
import { Flex } from '@r1/ui-kit'
import { QuickFilter } from '@r1/grid'
import type { QuickFilterOption } from '@r1/grid/build/types/QuickFilter'
import { getLocations } from '../../services/lightningPickConveyorManagement'
import type { ControllerStateReducers, FilterState, Program } from './types'
import { saveFilterStateToQueryParameters } from './utils'

type Props = {|
  state: FilterState,
  reducers: ControllerStateReducers,
|}

export const Filter = ({ state, reducers }: Props) => {
  const { program, divert, locationType, programs, locationTypes } = state
  const {
    setFilterDivert,
    setFilterProgram,
    setFilterLocationType,
    setGridFetching,
    setGridLocations,
    setGridPage,
  } = reducers

  const onProgramSelect = async (selectedProgram: QuickFilterOption) => {
    setFilterProgram((selectedProgram: Program))
    saveFilterStateToQueryParameters({ program: selectedProgram, divert, locationType })

    setGridFetching(true)

    const locations = await getLocations({
      program: selectedProgram,
      divert,
      locationType,
    })
    setGridLocations(locations)
    setGridPage(1)

    setGridFetching(false)
  }

  const onDivertApply = async (divertInput: string | null) => {
    setFilterDivert(divertInput)
    saveFilterStateToQueryParameters({ program, divert: divertInput, locationType })

    setGridFetching(true)

    const locations = await getLocations({
      program,
      divert: divertInput,
      locationType,
    })
    setGridLocations(locations)
    setGridPage(1)

    setGridFetching(false)
  }

  const onDivertCancel = async () => {
    setFilterDivert(null)
    saveFilterStateToQueryParameters({ program, divert: null, locationType })

    setGridFetching(true)

    const locations = await getLocations({
      program,
      divert: null,
      locationType,
    })
    setGridLocations(locations)
    setGridPage(1)

    setGridFetching(false)
  }

  const onLocationTypeSelect = async (selectedLocationType: QuickFilterOption) => {
    setFilterLocationType(selectedLocationType.name)
    saveFilterStateToQueryParameters({ program, divert, locationType: selectedLocationType.name })

    setGridFetching(true)

    const locations = await getLocations({
      program,
      divert,
      locationType: selectedLocationType.name,
    })
    setGridLocations(locations)
    setGridPage(1)

    setGridFetching(false)
  }

  const onLocationTypeCancel = async () => {
    setFilterLocationType(null)
    saveFilterStateToQueryParameters({ program, divert, locationType: null })

    setGridFetching(true)

    const locations = await getLocations({
      program,
      divert,
      locationType: null,
    })
    setGridLocations(locations)
    setGridPage(1)

    setGridFetching(false)
  }

  const locationTypeValue = locationType
    ? { id: String(locationType), name: String(locationType) }
    : null

  return (
    <Flex spaceBetween="XS">
      <QuickFilter
        type="list"
        bodyMinWidth={240}
        bodyHeight={140}
        entityName="Program"
        value={program}
        options={programs}
        onChange={onProgramSelect}
      />

      <QuickFilter
        type="list"
        bodyMinWidth={140}
        entityName="Slot type"
        value={locationTypeValue}
        options={locationTypes}
        onChange={onLocationTypeSelect}
        onCancel={onLocationTypeCancel}
      />

      <QuickFilter
        type="number"
        bodyMinWidth={160}
        entityName="Divert"
        value={divert}
        min={1}
        max={99}
        onChange={onDivertApply}
        onCancel={onDivertCancel}
      />
    </Flex>
  )
}
