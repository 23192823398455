// @flow

import { types, type ControllerState, type ControllerReducerAction } from './types'

export const reducer = (state: ControllerState, action: ControllerReducerAction) => {
  switch (action.type) {
    case types.GRID_SET_PAGE:
      return { ...state, gridState: { ...state.gridState, page: action.payload } }
    case types.GRID_SET_PAGE_SIZE:
      return { ...state, gridState: { ...state.gridState, pageSize: action.payload } }
    case types.GRID_SET_FETCHING:
      return { ...state, gridState: { ...state.gridState, isFetching: action.payload } }
    case types.GRID_SET_LOCATIONS:
      return { ...state, gridState: { ...state.gridState, locations: action.payload } }
    case types.GRID_SET_ALL_SELECTED:
      return { ...state, gridState: { ...state.gridState, isAllSelected: action.payload } }
    case types.GRID_SET_SELECTED_LOCATION_IDS:
      return { ...state, gridState: { ...state.gridState, selectedLocationIds: action.payload } }
    case types.FILTER_SET_PROGRAMS:
      return { ...state, filterState: { ...state.filterState, programs: action.payload } }
    case types.FILTER_SET_LOCATION_TYPES:
      return { ...state, filterState: { ...state.filterState, locationTypes: action.payload } }
    case types.FILTER_SET_FETCHING:
      return { ...state, filterState: { ...state.filterState, isFetching: action.payload } }
    case types.FILTER_SET_DIVERT:
      return { ...state, filterState: { ...state.filterState, divert: action.payload } }
    case types.FILTER_SET_LOCATION_TYPE:
      return { ...state, filterState: { ...state.filterState, locationType: action.payload } }
    case types.FILTER_SET_PROGRAM:
      return { ...state, filterState: { ...state.filterState, program: action.payload } }
    case types.LOCATION_MNGMNT_SET_OPEN:
      return {
        ...state,
        locationManagementState: {
          ...state.locationManagementState,
          isOpen: action.payload,
        },
      }
    case types.LOCATION_MNGMNT_SET_LOADING:
      return {
        ...state,
        locationManagementState: {
          ...state.locationManagementState,
          isLoading: action.payload,
        },
      }
    case types.LOCATION_MNGMNT_SET_MODAL_TYPE:
      return {
        ...state,
        locationManagementState: {
          ...state.locationManagementState,
          type: action.payload,
        },
      }
    case types.LOCATION_MNGMNT_SET_DIVERT:
      return {
        ...state,
        locationManagementState: {
          ...state.locationManagementState,
          divert: action.payload,
        },
      }
    case types.LOCATION_MNGMNT_SET_SORT:
      return {
        ...state,
        locationManagementState: {
          ...state.locationManagementState,
          sort: action.payload,
        },
      }
    case types.LOCATION_MNGMNT_SET_LOCATION_ID:
      return {
        ...state,
        locationManagementState: {
          ...state.locationManagementState,
          locationId: action.payload,
        },
      }
    case types.LOCATION_MNGMNT_SET_LOCATION_NAME:
      return {
        ...state,
        locationManagementState: {
          ...state.locationManagementState,
          locationName: action.payload,
        },
      }
    case types.LOCATION_MNGMNT_SET_LOCATION_TYPE:
      return {
        ...state,
        locationManagementState: {
          ...state.locationManagementState,
          locationType: action.payload,
        },
      }
    case types.LOCATION_MNGMNT_SET_SORTING_INDEX_ID:
      return {
        ...state,
        locationManagementState: {
          ...state.locationManagementState,
          sortingIndexId: action.payload,
        },
      }
    case types.LOCATION_MNGMNT_SET_SORTING_INDEXES:
      return {
        ...state,
        locationManagementState: {
          ...state.locationManagementState,
          sortingIndexes: action.payload,
        },
      }
    case types.DELETE_CONFIRMATION_SET_OPEN:
      return {
        ...state,
        deleteConfirmationState: {
          ...state.deleteConfirmationState,
          isOpen: action.payload,
        },
      }
    case types.DELETE_CONFIRMATION_SET_LOADING:
      return {
        ...state,
        deleteConfirmationState: {
          ...state.deleteConfirmationState,
          isLoading: action.payload,
        },
      }
    case types.DELETE_CONFIRMATION_SET_LOCATION:
      return {
        ...state,
        deleteConfirmationState: {
          ...state.deleteConfirmationState,
          location: action.payload,
        },
      }
    case types.DELETE_CONFIRMATION_SET_LOCATION_IDS:
      return {
        ...state,
        deleteConfirmationState: {
          ...state.deleteConfirmationState,
          locationIds: action.payload,
        },
      }
    default:
      return state
  }
}
