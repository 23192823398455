import React, { useMemo } from 'react'

import { PivotTable } from '../../../../../components/PivotTable/PivotTable'
import { InfoBlockWrapper } from '../../../../../components/InfoBlockWrapper'
import { FormLoadingPlaceholder } from '../../../../../../../components/FormLoadingPlaceholder'
import { AppearanceMode } from '../../../../../constants/AppearanceMode'

type Props = {
  mode: AppearanceMode
  vendorName?: string
  vendorNumber?: number
  remitVBU?: number
}

export const VendorInformationSection: React.FC<Props> = ({
  mode,
  vendorName,
  vendorNumber,
  remitVBU,
}) => {
  const tableItems = useMemo(() => {
    return [
      {
        key: 'Vendor Name',
        value: vendorName,
      },
      {
        key: 'Vendor Number',
        value: vendorNumber,
      },
      {
        key: 'Remit VBU',
        value: remitVBU,
      },
    ]
  }, [remitVBU, vendorName, vendorNumber])

  if (mode === AppearanceMode.Loading) {
    return (
      <InfoBlockWrapper>
        <FormLoadingPlaceholder numberOfFields={3} />
      </InfoBlockWrapper>
    )
  }

  if (mode === AppearanceMode.View) {
    return (
      <InfoBlockWrapper>
        <PivotTable items={tableItems} />
      </InfoBlockWrapper>
    )
  }

  return null
}
