import React, { useState, useCallback } from 'react'
import { Modal, Flex, FormField, DatePicker, Button, Icon, Input, Select } from '@r1/ui-kit'

import { useAccessControl } from '@r1/core-blocks'

import { PickupDescription } from '@r1-webui/facilitypickupscheduler-v1'
import { add, format, getDay, isSameDay, sub } from 'date-fns'
import { ControlledActionButtons } from '@r1/ui-kit/contracts/ts/Modal'
import { createPickup } from '../../../../services/schedulePickupService'
import { Period } from '../../types'
import { useExceptionDates } from '../../hooks/useExceptionDates'
import { utcToLocal } from '../../../../../../utils/dates/utcToLocal'
import { mergeDateWithTime } from '../../../../../../utils/dates/mergeDateWithTime'
import { ISOFormat } from '../../../../../../utils/dates/formats'

export type CreatePickupEvent = {
  pickupDescription: PickupDescription
  start: Date | string
  end: Date | string
}

export type CreatePickupModalProps = {
  warehouseLocationId: string | undefined
  warehouseWorkingHours: Record<number, Period>
  warehouseExceptionDates: string[]
  onAdd: (createPickupEvent: CreatePickupEvent) => void
}

export const generateTimeIntervals = (warehouseWorkingHours: Period): string[] => {
  const result = []

  const endDate = sub(utcToLocal(warehouseWorkingHours.end), { hours: 1 })
  let startDate = utcToLocal(warehouseWorkingHours.start)

  while (startDate <= endDate) {
    result.push(format(startDate, 'h:mm a'))
    startDate = add(startDate, { hours: 1 })
  }

  return result
}

export const CreatePickupModal = (props: CreatePickupModalProps) => {
  const [orderId, setOrderId] = useState<string | null>(null)
  const [pickupDate, setPickupDate] = useState<string | null>(null)
  const [pickupTime, setPickupTime] = useState<string | null>(null)
  const [show, setShow] = useState<boolean>(false)

  const [{ allowPickupScheduleEdit }] = useAccessControl()

  const exceptionDates = useExceptionDates(props.warehouseExceptionDates)

  const onModalClose = useCallback(() => {
    setOrderId(null)
    setPickupDate(null)
    setPickupTime(null)
    setShow(false)
  }, [])

  const onSave = async () => {
    if (orderId && pickupDate && props.warehouseLocationId) {
      const start = mergeDateWithTime(pickupDate, pickupTime)
      const end = add(mergeDateWithTime(pickupDate, pickupTime), { hours: 1 })
      const timeStart = format(start, ISOFormat)
      const timeEnd = format(end, ISOFormat)

      const createPickupParams = {
        warehouseLocationId: props.warehouseLocationId,
        orderId,
        pickupDate,
        timeStart,
        timeEnd,
      }

      const pickup = await createPickup(createPickupParams)

      if (pickup) {
        const event = { pickupDescription: pickup, start: timeStart, end: timeEnd }
        props.onAdd(event)
      }
    }
    onModalClose()
  }

  const isFormFilled = orderId && pickupDate && pickupTime

  const modalActionButtons: ControlledActionButtons = [
    {
      title: 'Close',
      onClick: onModalClose,
      align: 'left',
      transparent: true,
    },
    {
      title: 'Save',
      onClick: onSave,
      align: 'right',
      disabled: !isFormFilled,
    },
  ]

  const intervalOptions = React.useMemo(() => {
    if (pickupDate) {
      const date = new Date(pickupDate)

      if (exceptionDates.some(exceptionDate => isSameDay(exceptionDate, date))) return []

      const day = getDay(date)
      const period = props.warehouseWorkingHours[day]
      const intervalTimes = (period && generateTimeIntervals(period)) || []

      return intervalTimes.map(interval => ({ id: interval, name: interval }))
    }
    return []
  }, [pickupDate, exceptionDates, props.warehouseWorkingHours])

  return (
    <React.Fragment>
      <Button
        disabled={!allowPickupScheduleEdit || !props.warehouseLocationId}
        data-test-id="shipping__schedule-pickup__create-pickup-button"
        onClick={() => setShow(true)}
      >
        <Icon type="plus" />
        Pickup
      </Button>
      <Modal
        isControlled
        show={show}
        size="M"
        title="Create piсkup"
        actionButtons={modalActionButtons}
        onEscapeKeyDown={onModalClose}
      >
        <Flex column>
          <FormField>
            <FormField.Label>Order ID</FormField.Label>
            <Input
              value={orderId || ''}
              data-test-id="shipping__create-pickup-modal__order-id-input"
              onChange={setOrderId}
            />
          </FormField>
          <FormField>
            <FormField.Label>Pickup Date</FormField.Label>
            <DatePicker
              value={pickupDate}
              data-test-id="shipping__create-pickup-modal__pickup-date-date-picker"
              onChange={setPickupDate}
            />
          </FormField>
          <FormField>
            <FormField.Label>Pickup Period</FormField.Label>
            <Select
              value={pickupTime}
              options={intervalOptions}
              data-test-id="shipping__create-pickup-modal__pickup-period-select"
              onChange={setPickupTime}
            />
          </FormField>
        </Flex>
      </Modal>
    </React.Fragment>
  )
}
