// @flow

import React, { useCallback, useMemo } from 'react'
import { Modal, Text } from '@r1/ui-kit'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'
import { deleteLocations, getLocations } from '../../../services'
import type { DeleteConfirmationState, ControllerStateReducers, FilterState } from '../types'

type Props = {|
  state: DeleteConfirmationState,
  filterState: FilterState,
  reducers: ControllerStateReducers,
|}

export const DeleteConfirmationModal = ({
  state: { isOpen, location, isLoading, locationIds },
  filterState: { program, divert, locationType },
  reducers: {
    setGridLocations,
    setGridFetching,
    setDeleteConfirmationOpen,
    setDeleteConfirmationLoading,
  },
}: Props) => {
  const deleteLocationsByIds = useCallback(
    async (ids: string[]) => {
      setDeleteConfirmationLoading(true)

      if (await deleteLocations(ids)) {
        setDeleteConfirmationLoading(false)
        setDeleteConfirmationOpen(false)

        setGridFetching(true)
        const locations = await getLocations({ program, locationType, divert })
        setGridLocations(locations)
        setGridFetching(false)
      }

      setDeleteConfirmationLoading(false)
    },
    [
      divert,
      locationType,
      program,
      setDeleteConfirmationLoading,
      setDeleteConfirmationOpen,
      setGridFetching,
      setGridLocations,
    ],
  )

  const onDeleteLocationClick = useCallback(() => {
    let locationIdsToDelete: string[] | null = null

    if (locationIds && locationIds.length) {
      locationIdsToDelete = locationIds
      deleteLocationsByIds(locationIdsToDelete)
      return
    }

    if (location) {
      locationIdsToDelete = [location.id]
      deleteLocationsByIds(locationIdsToDelete)
    }
  }, [location, locationIds, deleteLocationsByIds])

  const getTitleForMultipleLocations = useCallback((ids: string[]) => {
    return ids.length > 1
      ? `Delete ${ids.length} conveyor locations?`
      : 'Delete 1 conveyor location?'
  }, [])

  const modalActionButtons: BottomActionButton[] = useMemo(
    () => [
      {
        title: 'Cancel',
        color: 'secondary',
        onClick: () => setDeleteConfirmationOpen(false),
        align: 'right',
      },
      {
        title: 'Delete',
        transparent: false,
        color: 'danger',
        loading: isLoading,
        onClick: onDeleteLocationClick,
        align: 'right',
      },
    ],
    [setDeleteConfirmationOpen, onDeleteLocationClick, isLoading],
  )

  return (
    <Modal
      isControlled
      show={isOpen}
      title={
        locationIds && locationIds.length
          ? getTitleForMultipleLocations(locationIds)
          : `Delete conveyor location ${location ? `"${location.name}"?` : '?'}`
      }
      actionButtons={modalActionButtons}
      onEscapeKeyDown={() => setDeleteConfirmationOpen(false)}
    >
      <Text>You can&apos;t undo this action</Text>
    </Modal>
  )
}
