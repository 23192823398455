// @flow

import { useMemo, useCallback, useState } from 'react'
import { NotificationSystem } from '@r1/ui-kit'
import type {
  BulkActionError,
  OnConfirmBulkAction,
  BulkActionSuccess,
} from '@r1/grid-generator/build/types/bulkActions'
import {
  getTitle,
  getMessage,
  getNotificationTitle,
  getNotificationLevel,
  isMultipleVendorError,
} from './helpers'

const CREATE_REQUEST_META = {
  $type: 'CustomBulkAction',
  bulkActionKey: 'createRequest',
  title: 'Create RA request',
}

export const useBulkActions = () => {
  const [isMultipleRequestsModalShown, showMultipleRequestsModal] = useState<boolean>(false)
  const [createMultipleRequestsModalTitle, setCreateMultipleRequestsModalTitle] = useState('')
  const [createMultipleRequestsModalMessage, setCreateMultipleRequestsModalMessage] = useState('')
  const [onConfirmCreateMultipleRequests, setOnConfirmCreateMultipleRequests] = useState<
    null | (() => void),
  >(null)

  const onDone = useCallback((req: BulkActionSuccess | BulkActionError) => {
    if (req.$type !== 'Success') {
      return
    }

    let { details } = req

    try {
      if (typeof req.details === 'string') {
        details = JSON.parse(req.details)
      }
    } catch (e) {
      console.error(e)
    }

    if (req.code === 'Warning' && isMultipleVendorError(details)) {
      setCreateMultipleRequestsModalTitle(getTitle(details))
      setCreateMultipleRequestsModalMessage(getMessage(details))
      showMultipleRequestsModal(true)

      return
    }

    NotificationSystem.addNotification({
      level: getNotificationLevel(req.code),
      title: getNotificationTitle(req),
      message: details,
      autoDismiss: false,
    })
  }, [])

  const onClick = useCallback((onConfirm: OnConfirmBulkAction) => {
    /** for the initial bulk action request we send allowCreateMultipleRequests: false.
     * if backend replies with error ('request will be created for multiple vendors')
     * we show confirmation modal. If only user accepts that multiple requests will be created for each vendor
     * then we do the same api call with allowCreateMultipleRequests: true
     */
    onConfirm({ allowCreateMultipleRequests: false })

    /** save current onConfirm to state in order to use after confirmation with allowCreateMultipleRequests: true */
    const confirmCreateMultipleRequests = () => onConfirm({ allowCreateMultipleRequests: true })
    setOnConfirmCreateMultipleRequests((): (() => void) => confirmCreateMultipleRequests)
  }, [])

  const bulkActions = useMemo(() => {
    const createRequestAction = { ...CREATE_REQUEST_META, onDone, onClick }
    return { actions: [createRequestAction] }
  }, [onClick, onDone])

  const hideMultipleRequestsModal = useCallback(() => showMultipleRequestsModal(false), [])

  return {
    createMultipleRequestsModalTitle,
    createMultipleRequestsModalMessage,
    bulkActions,
    isMultipleRequestsModalShown,
    hideMultipleRequestsModal,
    onConfirmCreateMultipleRequests,
  }
}
