// @flow

import { useReducer } from 'react'
import type { Program } from '../types'
import { defaultState } from './defaultState'
import { reducer } from './reducer'
import {
  types,
  type ControllerReducerAction,
  type ControllerState,
  type Picker,
  type ControllerStateReducers,
} from './types'

export const useControllerStateReducer = (): {|
  state: ControllerState,
  reducers: ControllerStateReducers,
|} => {
  const [state, dispatch] = useReducer<ControllerState, ControllerReducerAction>(
    reducer,
    defaultState,
  )

  const setGridFetching = (isFetching: boolean) =>
    dispatch({ type: types.GRID_SET_FETCHING, payload: isFetching })

  const setGridPickers = (pickers: Picker[]) =>
    dispatch({ type: types.GRID_SET_PICKERS, payload: pickers })

  const setGridTotalCount = (totalCount: number) =>
    dispatch({ type: types.GRID_SET_TOTAL_COUNT, payload: totalCount })

  const setGridAllSelected = (isAllSelected: boolean) =>
    dispatch({ type: types.GRID_SET_ALL_SELECTED, payload: isAllSelected })

  const setGridSelectedPickerIds = (selectedPickerIds: string[]) =>
    dispatch({ type: types.GRID_SET_SELECTED_PICKER_IDS, payload: selectedPickerIds })

  const setGridPage = (page: number) => dispatch({ type: types.GRID_SET_PAGE, payload: page })

  const setGridPageSize = (pageSize: number) =>
    dispatch({ type: types.GRID_SET_PAGE_SIZE, payload: pageSize })

  const setFilterPrograms = (programs: Program[]) =>
    dispatch({ type: types.FILTER_SET_PROGRAMS, payload: programs })

  const setFilterProgramId = (programId: string | null) =>
    dispatch({ type: types.FILTER_SET_PROGRAM_ID, payload: programId })

  const setFilterFetching = (isFetching: boolean) =>
    dispatch({ type: types.FILTER_SET_FETCHING, payload: isFetching })

  const setFilterLogins = (logins: string[]) =>
    dispatch({ type: types.FILTER_SET_LOGINS, payload: logins })

  const setFilterNames = (names: string[]) =>
    dispatch({ type: types.FILTER_SET_NAMES, payload: names })

  const setFilterBarCodes = (barCodes: string[]) =>
    dispatch({ type: types.FILTER_SET_BARCODES, payload: barCodes })

  return {
    state,
    reducers: {
      setGridFetching,
      setGridPickers,
      setGridTotalCount,
      setGridAllSelected,
      setGridSelectedPickerIds,
      setGridPage,
      setGridPageSize,
      setFilterPrograms,
      setFilterProgramId,
      setFilterFetching,
      setFilterLogins,
      setFilterNames,
      setFilterBarCodes,
    },
  }
}
