// @flow

import React, { useState } from 'react'
import { useAccountInfo } from '@r1/wireframe-primary'
import { Flex, Checkbox, Text, Button, NotificationSystem } from '@r1/ui-kit'
import { Table, Pagination } from '@r1/grid'
import { useAccessControl, cookie } from '@r1/core-blocks'
import { printJobIds } from '../../../../utils'
import { searchPickers, exportAllPickersGetUrl, printPickersGetDocumentId } from '../../services'
import { CounterSection } from '../../../../components/GridComponents'
import type { ControllerStateReducers, GridState, FilterState, Picker } from './types'
import { MIN_PAGE_SIZE } from './PickersGridController'

type Props = {|
  state: GridState,
  filterState: FilterState,
  reducers: ControllerStateReducers,
|}

const PRINT_ACTION_ID = 'PRINT'

export const Grid = ({
  state: { selectedPickerIds, isAllSelected, pickers, totalCount, isFetching, pageSize, page },
  filterState,
  reducers: {
    setGridSelectedPickerIds,
    setGridAllSelected,
    setGridPage,
    setGridPageSize,
    setGridFetching,
    setGridPickers,
    setGridTotalCount,
  },
}: Props) => {
  const account = useAccountInfo()
  const [isExporting, setExporting] = useState(false)
  const [{ allowConveyorPickersExport, allowConveyorPickersPrintLoginLabels }] = useAccessControl()

  const onPaginationChange = async newPagination => {
    setGridFetching(true)

    setGridPage(newPagination.page)
    setGridPageSize(newPagination.pageSize)
    const newPickersResponse = await searchPickers({
      page: newPagination.page,
      pageSize: newPagination.pageSize,
      filterState,
    })
    setGridPickers(newPickersResponse.pickers)
    setGridTotalCount(newPickersResponse.totalCount)

    setGridSelectedPickerIds([])
    setGridAllSelected(false)

    setGridFetching(false)
  }

  const onGridItemSelect = (id: string, wasChecked: boolean) => () => {
    if (wasChecked) {
      setGridAllSelected(false)
      const newPickerIds = [...selectedPickerIds]
      newPickerIds.splice(newPickerIds.indexOf(id), 1)
      setGridSelectedPickerIds(newPickerIds)
    } else {
      const newPickerIds = [...selectedPickerIds, id]
      if (newPickerIds.length === pickers.length) {
        setGridAllSelected(true)
        setGridSelectedPickerIds(newPickerIds)
      } else {
        setGridSelectedPickerIds(newPickerIds)
      }
    }
  }

  const onSelectAll = () => {
    if (isAllSelected) setGridSelectedPickerIds([])
    else setGridSelectedPickerIds(pickers.map(p => p.userPrincipalId))
    setGridAllSelected(!isAllSelected)
  }

  const onExportAll = async () => {
    setExporting(true)

    const url = await exportAllPickersGetUrl()

    setExporting(false)

    if (url) {
      window.open(url, '_blank')
    }
  }

  const onActionItemSelect = async (id: string) => {
    switch (id) {
      case PRINT_ACTION_ID: {
        const accountId = account.id

        let machineId

        machineId = cookie.getItem(`UserPrinterSettings${accountId}`)

        if (!machineId) machineId = localStorage.getItem(`ReconMain: MACHINE_ID_${accountId}`)

        if (machineId) {
          const documentId = await printPickersGetDocumentId({
            userPrincipalIds: selectedPickerIds,
            machineId,
          })

          if (documentId) printJobIds({ jobIds: [documentId] })
        } else {
          NotificationSystem.addNotification({
            level: 'error',
            title: 'Please set up printer settings',
          })
        }

        break
      }
      default:
    }
  }

  const actionItems = [
    {
      id: PRINT_ACTION_ID,
      title: <Text>Print login labels</Text>,
      disabled: !allowConveyorPickersPrintLoginLabels,
    },
  ]

  const displaySettings = {
    columnsSettings: [
      {
        width: 50,
        header: {
          content: (
            <Checkbox
              indeterminate={!isAllSelected && !!selectedPickerIds.length}
              checked={isAllSelected}
              onChange={onSelectAll}
            />
          ),
        },
        cell: {
          $type: 'custom',
          renderer: (data: Picker) => {
            const checked = isAllSelected || selectedPickerIds.includes(data.userPrincipalId)
            return (
              <Checkbox
                checked={checked}
                onChange={onGridItemSelect(data.userPrincipalId, checked)}
              />
            )
          },
        },
      },
      {
        width: 250,
        header: {
          content: 'Login',
        },
        cell: {
          $type: 'text',
          field: 'login',
        },
      },
      {
        header: {
          content: 'Name',
        },
        cell: {
          $type: 'text',
          field: 'name',
        },
      },
    ],
  }

  const renderTable = () => (
    <Table loading={isFetching} data={pickers} displaySettings={displaySettings} />
  )

  return (
    <Flex column spaceBetween="M">
      <Flex justify="space-between">
        <CounterSection
          isSelected={!!selectedPickerIds.length}
          count={selectedPickerIds.length || totalCount}
          actionItems={actionItems}
          onSelectItem={onActionItemSelect}
        />

        <Button
          loading={isFetching || isExporting}
          disabled={!totalCount || !allowConveyorPickersExport}
          onClick={onExportAll}
        >
          Export All
        </Button>
      </Flex>

      {totalCount < MIN_PAGE_SIZE ? (
        renderTable()
      ) : (
        <Pagination
          rowCount={totalCount}
          page={page}
          pageSize={pageSize}
          availablePageSizes={[50, 100, 500]}
          // $FlowFixMe[incompatible-type] promise in callback
          onChange={onPaginationChange}
        >
          {renderTable()}
        </Pagination>
      )}
    </Flex>
  )
}
