// @flow

import { useReducer } from 'react'
import { queryParametersUtils } from '../../../../../utils'
import { defaultState } from './defaultState'
import { reducer } from './reducer'
import {
  types,
  type ControllerReducerAction,
  type ControllerState,
  type Location,
  type Program,
  type MappedLocationType,
  type SortingIndex,
  type ControllerStateReducers,
} from './types'

export const useControllerStateReducer = (): {|
  state: ControllerState,
  reducers: ControllerStateReducers,
|} => {
  const [state, dispatch] = useReducer<ControllerState, ControllerReducerAction>(
    reducer,
    defaultState,
  )
  const { setQueries } = queryParametersUtils

  const setGridFetching = (isFetching: boolean) =>
    dispatch({ type: types.GRID_SET_FETCHING, payload: isFetching })

  const setGridLocations = (locations: Location[]) =>
    dispatch({ type: types.GRID_SET_LOCATIONS, payload: locations })

  const setGridPage = (page: number) => {
    dispatch({ type: types.GRID_SET_PAGE, payload: page })
    setQueries([{ key: 'page', value: String(page) }])
  }

  const setGridPageSize = (pageSize: number) => {
    dispatch({ type: types.GRID_SET_PAGE_SIZE, payload: pageSize })
    setQueries([{ key: 'pageSize', value: String(pageSize) }])
  }

  const setGridAllSelected = (isAllSelected: boolean) =>
    dispatch({ type: types.GRID_SET_ALL_SELECTED, payload: isAllSelected })

  const setGridSelectedLocationIds = (selectedLocationIds: string[]) =>
    dispatch({ type: types.GRID_SET_SELECTED_LOCATION_IDS, payload: selectedLocationIds })

  const setFilterPrograms = (programs: Program[]) =>
    dispatch({ type: types.FILTER_SET_PROGRAMS, payload: programs })

  const setFilterLocationTypes = (locationTypes: MappedLocationType[]) =>
    dispatch({ type: types.FILTER_SET_LOCATION_TYPES, payload: locationTypes })

  const setFilterFetching = (isFetching: boolean) =>
    dispatch({ type: types.FILTER_SET_FETCHING, payload: isFetching })

  const setFilterProgram = (program: Program | null) =>
    dispatch({ type: types.FILTER_SET_PROGRAM, payload: program })

  const setFilterLocationType = (locationType: string | null) =>
    dispatch({ type: types.FILTER_SET_LOCATION_TYPE, payload: locationType })

  const setFilterDivert = (divert: string | null) =>
    dispatch({ type: types.FILTER_SET_DIVERT, payload: divert })

  const setLocationManagementOpen = (isOpen: boolean) =>
    dispatch({ type: types.LOCATION_MNGMNT_SET_OPEN, payload: isOpen })

  const setLocationManagementLoading = (isLoading: boolean) =>
    dispatch({ type: types.LOCATION_MNGMNT_SET_LOADING, payload: isLoading })

  const setLocationManagementLocationId = (locationId: string | null) =>
    dispatch({ type: types.LOCATION_MNGMNT_SET_LOCATION_ID, payload: locationId })

  const setLocationManagementDivert = (divert: string | null) =>
    dispatch({ type: types.LOCATION_MNGMNT_SET_DIVERT, payload: divert })

  const setLocationManagementSort = (sort: string | null) =>
    dispatch({ type: types.LOCATION_MNGMNT_SET_SORT, payload: sort })

  const setLocationManagementModalType = (type: 'create' | 'edit') =>
    dispatch({ type: types.LOCATION_MNGMNT_SET_MODAL_TYPE, payload: type })

  const setLocationManagementLocationType = (locationType: string | null) =>
    dispatch({ type: types.LOCATION_MNGMNT_SET_LOCATION_TYPE, payload: locationType })

  const setLocationManagementLocationName = (locationName: string | null) =>
    dispatch({ type: types.LOCATION_MNGMNT_SET_LOCATION_NAME, payload: locationName })

  const setLocationManagementSortingIndexId = (sortingIndexId: string | null) =>
    dispatch({ type: types.LOCATION_MNGMNT_SET_SORTING_INDEX_ID, payload: sortingIndexId })

  const setLocationManagementSortingIndexes = (sortingIndexes: SortingIndex[]) =>
    dispatch({ type: types.LOCATION_MNGMNT_SET_SORTING_INDEXES, payload: sortingIndexes })

  const setDeleteConfirmationOpen = (isOpen: boolean) =>
    dispatch({ type: types.DELETE_CONFIRMATION_SET_OPEN, payload: isOpen })

  const setDeleteConfirmationLoading = (isLoading: boolean) =>
    dispatch({ type: types.DELETE_CONFIRMATION_SET_LOADING, payload: isLoading })

  const setDeleteConfirmationLocation = (location: Location | null) =>
    dispatch({ type: types.DELETE_CONFIRMATION_SET_LOCATION, payload: location })

  const setDeleteConfirmationLocationIds = (locationIds: string[] | null) =>
    dispatch({ type: types.DELETE_CONFIRMATION_SET_LOCATION_IDS, payload: locationIds })

  return {
    state,
    reducers: {
      setGridFetching,
      setGridLocations,
      setGridPage,
      setGridPageSize,
      setGridAllSelected,
      setGridSelectedLocationIds,
      setFilterPrograms,
      setFilterLocationTypes,
      setFilterFetching,
      setFilterProgram,
      setFilterLocationType,
      setFilterDivert,
      setLocationManagementOpen,
      setLocationManagementLoading,
      setLocationManagementLocationId,
      setLocationManagementLocationName,
      setLocationManagementDivert,
      setLocationManagementSort,
      setLocationManagementModalType,
      setLocationManagementLocationType,
      setLocationManagementSortingIndexId,
      setLocationManagementSortingIndexes,
      setDeleteConfirmationOpen,
      setDeleteConfirmationLoading,
      setDeleteConfirmationLocation,
      setDeleteConfirmationLocationIds,
    },
  }
}
