// @flow

import * as React from 'react'
import { GridGenerator } from '@r1/grid-generator'
import { Flex, Modal, Text, withTheme, Link } from '@r1/ui-kit'
import type { BottomActionButton } from '@r1/ui-kit/build/types/common'
import { Content, Main, ContentHeader } from '@r1/wireframe-primary'
import type { ContextRouter } from 'react-router-dom'

import { errorHandler } from '../../../../utils/errorHandler'
import { httpClient } from '../../../../httpClient'

import { useStateQuery } from '../hooks/useStateQuery'
import { useBreadcrumbs } from '../hooks/useBreadcrumbs'
import { useBulkActions } from './hooks/useBulkActions'

const GRID_ID = 'ReturnsAuthorizationManagement'
const GRID_BASE_URL = '/uisvc/legacy-grids'
const AGGREGATION_COUNT = { title: 'All Records' }

const Grid = ({ history, theme }) => {
  const { isExpanded, lastGrouping, initialStateQuery, onChangeState } = useStateQuery()
  const { breadcrumbs, parentLink } = useBreadcrumbs(history, isExpanded, lastGrouping)
  const {
    createMultipleRequestsModalTitle,
    createMultipleRequestsModalMessage,
    bulkActions,
    isMultipleRequestsModalShown,
    hideMultipleRequestsModal,
    onConfirmCreateMultipleRequests,
  } = useBulkActions()

  const modalActionButtons: BottomActionButton[] = React.useMemo(
    () => [
      {
        title: 'Cancel',
        color: 'secondary',
        onClick: hideMultipleRequestsModal,
        align: 'right',
      },
      {
        title: 'Create request',
        transparent: false,
        onClick: () => {
          if (onConfirmCreateMultipleRequests) onConfirmCreateMultipleRequests()
          hideMultipleRequestsModal()
        },
        align: 'right',
      },
    ],
    [hideMultipleRequestsModal, onConfirmCreateMultipleRequests],
  )

  return (
    <Main fullScreen>
      <ContentHeader />
      <ContentHeader.Breadcrumbs>
        <Link to={parentLink}>{breadcrumbs}</Link>
      </ContentHeader.Breadcrumbs>
      <ContentHeader.Title>Return Authorization Management: Inventory</ContentHeader.Title>

      <Content>
        <Flex column>
          <GridGenerator
            exportable
            gridId={GRID_ID}
            gridEngineBaseUrl={GRID_BASE_URL}
            initialStateQuery={initialStateQuery}
            aggregationCount={AGGREGATION_COUNT}
            httpClient={httpClient}
            bulkActions={bulkActions}
            onError={errorHandler}
            onChangeState={onChangeState}
          />
        </Flex>
      </Content>

      <Modal
        isControlled
        show={isMultipleRequestsModalShown}
        title={createMultipleRequestsModalTitle}
        actionButtons={modalActionButtons}
        onEscapeKeyDown={hideMultipleRequestsModal}
      >
        <Flex column minWidth={416} maxWidth={416}>
          <Text color={theme.palette.grey[800]}>{createMultipleRequestsModalMessage}</Text>
        </Flex>
      </Modal>
    </Main>
  )
}

export const ReturnsAuthorizationManagementGrid = withTheme<
  {|
    history: $ElementType<ContextRouter, 'history'>,
  |},
  mixed,
>(Grid)
