// @flow

import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import {
  Icon,
  Flex,
  Button,
  Text,
  Dropdown,
  useConfigurationProvider,
  Box,
  Checkbox,
} from '@r1/ui-kit'
import type { EnhancedBoxProps as BoxProps } from '@r1/ui-kit/build/types/Box'
import { Table, Pagination } from '@r1/grid'
import { useAccessControl } from '@r1/core-blocks'
import { getLocations } from '../../services/lightningPickConveyorManagement'
import { CounterSection } from '../../../../components/GridComponents'
import { defaultState, MIN_PAGE_SIZE } from './LocationsGridController'
import type { ControllerStateReducers, Location, GridState, FilterState } from './types'
import { saveFilterStateToQueryParameters } from './utils'

type Props = {|
  state: GridState,
  filterState: FilterState,
  reducers: ControllerStateReducers,
|}

const TextWrapperBox: React$ComponentType<BoxProps> = styled(Box)`
  cursor: pointer;
  color: ${({ theme }) => theme.palette.blue[500]};
`

const EDIT_ACTION_ID = 'EDIT_ACTION'
const DELETE_ACTION_ID = 'DELETE_ACTION'

export const Grid = ({
  state: { locations, page, pageSize, isFetching, isAllSelected, selectedLocationIds },
  filterState,
  reducers: {
    setGridPage,
    setGridPageSize,
    setGridFetching,
    setGridLocations,
    setGridAllSelected,
    setGridSelectedLocationIds,
    setFilterDivert,
    setFilterLocationType,
    setLocationManagementLocationId,
    setLocationManagementModalType,
    setLocationManagementOpen,
    setLocationManagementDivert,
    setLocationManagementSort,
    setLocationManagementLocationType,
    setLocationManagementSortingIndexId,
    setLocationManagementSortingIndexes,
    setLocationManagementLocationName,
    setDeleteConfirmationLocation,
    setDeleteConfirmationLocationIds,
    setDeleteConfirmationOpen,
  },
}: Props) => {
  const [paginationLocations, setPaginationLocations] = useState<Location[]>([])
  const [{ allowDivertLocationsEdit }] = useAccessControl()
  const { palette } = useConfigurationProvider()

  const onCreateLocationClick = () => {
    setLocationManagementModalType('create')
    setLocationManagementOpen(true)
  }

  const onPaginationChange = newPagination => {
    setGridPage(newPagination.page)
    setGridPageSize(newPagination.pageSize)
  }

  const actionItems = [
    {
      id: EDIT_ACTION_ID,
      title: <Text>Edit</Text>,
      disabled: !allowDivertLocationsEdit,
    },
    {
      id: DELETE_ACTION_ID,
      title: <Text>Delete</Text>,
      disabled: !allowDivertLocationsEdit,
    },
  ]

  const bulkActionItems = [
    {
      id: DELETE_ACTION_ID,
      title: <Text>Delete</Text>,
      disabled: !allowDivertLocationsEdit,
    },
  ]

  const onEditAction = ({ id, divert, sort, locationType, sortingIndex, name }: Location) => {
    setLocationManagementLocationId(id)
    setLocationManagementModalType('edit')
    setLocationManagementDivert(String(divert))
    setLocationManagementSort(String(sort))
    setLocationManagementLocationType(locationType)
    setLocationManagementLocationName(name)
    if (sortingIndex) {
      setLocationManagementSortingIndexes([sortingIndex])
      setLocationManagementSortingIndexId(sortingIndex.id)
    } else {
      const { locationManagementState } = defaultState
      setLocationManagementSortingIndexes(locationManagementState.sortingIndexes)
      setLocationManagementSortingIndexId(locationManagementState.sortingIndexId)
    }

    setLocationManagementOpen(true)
  }

  const onActionSelect = (location: Location) => (actionId: string) => {
    switch (actionId) {
      case EDIT_ACTION_ID: {
        onEditAction(location)

        break
      }
      case DELETE_ACTION_ID: {
        setDeleteConfirmationLocationIds(null)
        setDeleteConfirmationLocation(location)
        setDeleteConfirmationOpen(true)

        break
      }
      default:
    }
  }

  const onBulkActionSelect = async (id: string) => {
    switch (id) {
      case DELETE_ACTION_ID: {
        setDeleteConfirmationLocationIds(selectedLocationIds)
        setDeleteConfirmationOpen(true)

        break
      }
      default:
    }
  }

  const onIDCLick = (params: Location) => () => {
    onEditAction(params)
  }

  const onCancelFilter = async () => {
    setFilterDivert(null)
    setFilterLocationType(null)

    saveFilterStateToQueryParameters({
      program: filterState.program,
      divert: null,
      locationType: null,
    })

    setGridSelectedLocationIds([])
    setGridAllSelected(false)

    setGridFetching(true)

    const newLocations = await getLocations({
      program: filterState.program,
      divert: null,
      locationType: null,
    })
    setGridLocations(newLocations)

    setGridFetching(false)
  }

  const onGridItemSelect = (id: string, wasChecked: boolean) => () => {
    if (wasChecked) {
      setGridAllSelected(false)
      const newPickerIds = [...selectedLocationIds]
      newPickerIds.splice(newPickerIds.indexOf(id), 1)
      setGridSelectedLocationIds(newPickerIds)
    } else {
      const newPickerIds = [...selectedLocationIds, id]
      if (newPickerIds.length === paginationLocations.length) {
        setGridAllSelected(true)
        setGridSelectedLocationIds(newPickerIds)
      } else {
        setGridSelectedLocationIds(newPickerIds)
      }
    }
  }

  const onSelectAll = () => {
    if (isAllSelected) setGridSelectedLocationIds([])
    else setGridSelectedLocationIds(paginationLocations.map(l => l.id))
    setGridAllSelected(!isAllSelected)
  }

  const displaySettings = {
    columnsSettings: [
      {
        width: 50,
        header: {
          content: (
            <Checkbox
              indeterminate={!isAllSelected && !!selectedLocationIds.length}
              checked={isAllSelected}
              onChange={onSelectAll}
            />
          ),
        },
        cell: {
          $type: 'custom',
          renderer: (location: Location) => {
            const checked = isAllSelected || selectedLocationIds.includes(location.id)
            return <Checkbox checked={checked} onChange={onGridItemSelect(location.id, checked)} />
          },
        },
      },
      {
        width: 100,
        header: {
          content: 'ID',
        },
        cell: {
          $type: 'custom',
          renderer: location => {
            return (
              <TextWrapperBox onClick={onIDCLick(location)}>
                <Text color={palette.blue[500]}>{location.name}</Text>
              </TextWrapperBox>
            )
          },
        },
      },
      {
        header: {
          content: 'Sorting index',
        },
        cell: {
          $type: 'custom',
          renderer: ({ sortingIndex }) => (sortingIndex ? sortingIndex.name : ''),
        },
      },
      {
        width: 100,
        header: {
          content: 'Divert',
        },
        cell: {
          $type: 'text',
          field: 'divert',
        },
      },
      {
        width: 100,
        header: {
          content: 'Slot',
        },
        cell: {
          $type: 'text',
          field: 'sort',
        },
      },
      {
        width: 200,
        horizontalAlign: 'right',
        header: {
          content: 'Slot type',
        },
        cell: {
          $type: 'custom',
          renderer: location => (
            <Flex minWidth={168} justify="space-between">
              <Text>{location.locationType}</Text>
              <Dropdown.Menu
                mode="hover"
                placement="bottom-end"
                size="S"
                zIndex={41}
                width={160}
                items={actionItems}
                data-test-id="test-id"
                onSelectItem={onActionSelect(location)}
              >
                <Icon type="dots" />
              </Dropdown.Menu>
            </Flex>
          ),
        },
      },
    ],
  }

  useEffect(() => {
    // TODO move this logic to server when data becomes larger
    const start = (page - 1) * pageSize
    const end = start + pageSize
    setPaginationLocations(locations.slice(start, end))

    setGridSelectedLocationIds([])
    setGridAllSelected(false)
  }, [locations, page, pageSize])

  const rowCount = locations.length

  const renderTable = () => (
    <Table
      loading={isFetching || filterState.isFetching}
      data={paginationLocations}
      displaySettings={displaySettings}
    />
  )

  return (
    <Flex column spaceBetween="M">
      <Flex align="center" justify="space-between">
        <CounterSection
          count={selectedLocationIds.length || rowCount}
          isSelected={!!selectedLocationIds.length}
          isFiltered={!!filterState.locationType || !!filterState.divert}
          actionItems={bulkActionItems}
          onSelectItem={onBulkActionSelect}
          onFilterFlush={onCancelFilter}
        />

        {allowDivertLocationsEdit && (
          <Button disabled={isFetching} onClick={onCreateLocationClick}>
            Create
          </Button>
        )}
      </Flex>

      {rowCount < MIN_PAGE_SIZE ? (
        renderTable()
      ) : (
        <Pagination
          rowCount={rowCount}
          page={page}
          pageSize={pageSize}
          availablePageSizes={[10, 100, 500]}
          onChange={onPaginationChange}
        >
          {renderTable()}
        </Pagination>
      )}
    </Flex>
  )
}
