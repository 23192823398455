// @flow

import React, { useState } from 'react'
import { Select } from '@r1/ui-kit'
import { useDebounce } from '../../../../../utils'
import { searchSortingIndexes } from '../../../services'
import type { SortingIndex, ControllerStateReducers, FilterStateProgram } from '../types'

type Props = {|
  width: number,
  program: FilterStateProgram,
  disabled: boolean,
  sortingIndexId: string | null,
  sortingIndexes: SortingIndex[],
  reducers: ControllerStateReducers,
|}

export const SortingIndexesSelect = ({
  width,
  program,
  disabled,
  sortingIndexId,
  sortingIndexes,
  reducers: { setLocationManagementSortingIndexId, setLocationManagementSortingIndexes },
}: Props) => {
  const limit = 10
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const debounce = useDebounce(300)

  const search = async (searchTerm: string) => {
    setIsLoading(true)
    // eslint-disable-next-line no-shadow
    const sortingIndexes = await searchSortingIndexes({ program, searchTerm, limit })
    setIsLoading(false)
    setLocationManagementSortingIndexes(sortingIndexes)
  }

  return (
    <Select
      disabled={disabled}
      loading={isLoading}
      placeholder="Type to search sorting indexes"
      noResultsText="Type to search"
      width={width}
      options={sortingIndexes}
      value={sortingIndexId}
      onChange={setLocationManagementSortingIndexId}
      onInputChange={term => debounce(() => term && search(term))}
    />
  )
}
