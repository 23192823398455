import React, { useState } from 'react'
import { OfferFilter } from '@r1-webui/gowholesale-offermanagement-v1'
import { Flex, FormField, TagInput, Select, DatePicker, Button, Input } from '@r1/ui-kit'
import { OffersGridFilterProps } from '../types'
import { OFFER_STATUS_OPTIONS } from '../const'

export const OffersGridFilter = ({ filter: initialFilter, onChange }: OffersGridFilterProps) => {
  const [filter, setCurrentFilter] = useState<OfferFilter>(initialFilter)

  const onListFilterChange = (type: keyof OfferFilter) => (value: string[]) => {
    setCurrentFilter(prevFilter => ({ ...prevFilter, [type]: value }))
  }

  const onCustomerNameChange = (value: string) => {
    setCurrentFilter(prevFilter => ({ ...prevFilter, customerCompany: value }))
  }

  const onExpirationDateChange = (dateType: 'from' | 'to') => (value: string | null) => {
    setCurrentFilter(prevFilter => ({
      ...prevFilter,
      expirationDate: { ...prevFilter.expirationDate, [dateType]: value },
    }))
  }

  const onExpirationDateRemove = (dateType: 'from' | 'to') => () => {
    setCurrentFilter(prevFilter => ({
      ...prevFilter,
      expirationDate: { ...prevFilter.expirationDate, [dateType]: null },
    }))
  }

  const onApproveDateChange = (dateType: 'from' | 'to') => (value: string | null) => {
    setCurrentFilter(prevFilter => ({
      ...prevFilter,
      approveDate: { ...prevFilter.approveDate, [dateType]: value },
    }))
  }

  const onApproveDateRemove = (dateType: 'from' | 'to') => () => {
    setCurrentFilter(prevFilter => ({
      ...prevFilter,
      approveDate: { ...prevFilter.approveDate, [dateType]: null },
    }))
  }

  const formatFiltersBeforeSubmit = (): OfferFilter => {
    return {
      ...filter,
      customerCompany: filter.customerCompany?.trim() || undefined,
    }
  }

  const onSearchButtonClick = () => {
    const formattedFilters = formatFiltersBeforeSubmit()
    setCurrentFilter(formattedFilters)
    onChange(formattedFilters)
  }

  return (
    <Flex column spaceBetween="XS">
      <Flex spaceBetween="XL">
        <Flex column basis={0.4} spaceBetween="S">
          <FormField>
            <FormField.Label>Offer IDs</FormField.Label>
            <TagInput
              $type="simpleTagValue"
              zIndex={2}
              tagLimit={5}
              placeholder="Enter values"
              value={(filter.offerIds ?? []).map(String)}
              accept={/^\d+$/}
              delimiter={['\n', ',']}
              onChange={onListFilterChange('offerIds')}
            />
          </FormField>

          <FormField>
            <FormField.Label>SKUs</FormField.Label>
            <TagInput
              $type="simpleTagValue"
              zIndex={1}
              tagLimit={5}
              placeholder="Enter values"
              value={(filter.skus ?? []).map(String)}
              delimiter={['\n', ',']}
              onChange={onListFilterChange('skus')}
            />
          </FormField>

          <FormField>
            <FormField.Label>Statuses</FormField.Label>
            <Select
              multiple
              placeholder="Choose Statuses"
              options={OFFER_STATUS_OPTIONS}
              value={filter.statuses ?? []}
              onChange={onListFilterChange('statuses')}
            />
          </FormField>
        </Flex>

        <Flex column spaceBetween="S">
          <FormField>
            <FormField.Label>Expiration Date</FormField.Label>
            <Flex spaceBetween="XL">
              <Flex align="center" spaceBetween="M">
                <span>From:</span>
                <DatePicker
                  value={filter.expirationDate?.from}
                  onChange={onExpirationDateChange('from')}
                  onRemove={onExpirationDateRemove('from')}
                />
              </Flex>
              <Flex align="center" spaceBetween="M">
                <span>To:</span>
                <DatePicker
                  value={filter.expirationDate?.to}
                  onChange={onExpirationDateChange('to')}
                  onRemove={onExpirationDateRemove('to')}
                />
              </Flex>
            </Flex>
          </FormField>

          <FormField>
            <FormField.Label>Approval Date</FormField.Label>
            <Flex spaceBetween="XL">
              <Flex align="center" spaceBetween="M">
                <span>From:</span>
                <DatePicker
                  value={filter.approveDate?.from}
                  onChange={onApproveDateChange('from')}
                  onRemove={onApproveDateRemove('from')}
                />
              </Flex>
              <Flex align="center" spaceBetween="M">
                <span>To:</span>
                <DatePicker
                  value={filter.approveDate?.to}
                  onChange={onApproveDateChange('to')}
                  onRemove={onApproveDateRemove('to')}
                />
              </Flex>
            </Flex>
          </FormField>

          <FormField>
            <FormField.Label>Company Name</FormField.Label>
            <Input
              placeholder="Enter value"
              value={filter.customerCompany || ''}
              onChange={onCustomerNameChange}
            />
          </FormField>
        </Flex>
      </Flex>
      <Flex justify="flex-start">
        <Button onClick={onSearchButtonClick}>Search</Button>
      </Flex>
    </Flex>
  )
}
